import React from 'react';
import { Box } from '@shared/ui/box';
import useBreakpoint from '@/lib/hooks/useBreakpoint';
import { selectThemeDrawerIsOpen, useSelector } from '@shared/redux';

export const ThemeDrawerMask: React.FC<{ onClose: () => void }> = ({
  onClose,
}) => {
  const matches = useBreakpoint(991);
  const drawerIsOpen = useSelector(selectThemeDrawerIsOpen);

  return matches && drawerIsOpen ? (
    <Box
      onClick={onClose}
      boxStyles={{
        top: 0,
        left: 0,
        zIndex: 999,
        width: '100%',
        height: '100%',
        position: 'fixed',
      }}
    />
  ) : null;
};
