import React from 'react';
import { Upload } from 'antd';
import { breakpoints, prefixCls } from '@shared/theme';
import styled, { CSSObject } from '@emotion/styled';

export const wrapperStyles: CSSObject = {
  height: '100%',
  overflow: 'hidden',
  flexDirection: 'column',
};

export const virtualListWrapperStyles: CSSObject = {
  width: '100%',
  height: '100%',
  display: 'block',
  maxHeight: '100%',
  overflow: 'hidden',
  marginInline: 'auto',
  position: 'relative',
  flexDirection: 'column',
};

export const imageStyles: React.CSSProperties = {
  objectFit: 'cover',
  backgroundColor: '#fff',
  objectPosition: 'center',
};

export const buttonStyles: React.CSSProperties = {
  border: 0,
  background: 'none',
};

export const searchWrapperStyles: CSSObject = {
  marginBottom: '2.4rem',
};

export const Card = styled('div', {
  shouldForwardProp: (prop) =>
    !['mb', 'border', 'backgroundColor'].includes(prop),
})(
  ({
    mb,
    border,
    backgroundColor,
  }: {
    border: CSSObject['border'];
    mb?: CSSObject['marginBottom'];
    backgroundColor: CSSObject['backgroundColor'];
  }) => ({
    border,
    display: 'flex',
    backgroundColor,
    marginBottom: mb,
    borderRadius: 10,
    cursor: 'pointer',
    overflow: 'hidden',
    position: 'relative',
    alignItems: 'center',
    justifyContent: 'center',
  }),
);

export const FontCard = styled('div', {
  shouldForwardProp: (prop) => !['border', 'drawerTheme'].includes(prop),
})(
  ({
    border,
    drawerTheme,
  }: {
    border?: CSSObject['border'];
    drawerTheme?: 'dark' | 'light';
  }) => ({
    width: 170,
    height: 170,
    minWidth: 170,
    display: 'flex',
    borderRadius: 10,
    cursor: 'pointer',
    overflow: 'hidden',
    position: 'relative',
    alignItems: 'center',
    justifyContent: 'center',
    border: border ? border : '2px solid rgba(0, 0, 0, 0.15)',
    backgroundColor: drawerTheme === 'dark' ? ' #F5F5F5' : void 0,
    '&:not(.selected-palette)': {
      backgroundColor: '#001529',
    },
  }),
);

export const ColorCard = styled('div', {
  shouldForwardProp: (prop) => !['border'].includes(prop),
})(({ border }: { border?: CSSObject['border'] }) => ({
  border,
  width: 132,
  padding: 8,
  height: 132,
  minWidth: 132,
  display: 'flex',
  borderRadius: 10,
  cursor: 'pointer',
  position: 'relative',
  background: '#F5F5F5',
  '&:hover > .selected-palette-shuffle-icon': {
    opacity: 1,
  },
  '&.selected-palette': {
    cursor: 'initial',
  },
}));

export const ImageWrapper = styled('div')(() => ({
  borderRadius: 5,
  overflow: 'hidden',
  position: 'relative',
  width: 'calc(100% - 12px)',
  height: 'calc(100% - 12px)',
}));

const selector = `&.${prefixCls}-upload-wrapper > .${prefixCls}-upload.${prefixCls}-upload-select`;

export const StyledUpload = styled(Upload, {
  shouldForwardProp: (prop) => prop !== 'drawerTheme',
})(({ drawerTheme }: { drawerTheme?: 'light' | 'dark' }) => {
  let border;
  let background;

  if (drawerTheme === 'dark') {
    background = 'rgba(255, 255, 255, 0.04)';
    border = '1px dashed rgba(255, 255, 255, 0.15)';
  }

  return {
    [selector]: {
      border,
      background,
      width: 170,
      height: 170,
      marginBottom: 0,
      borderRadius: 10,
      marginInlineEnd: 0,
    },
  };
});

export const UploaderBody = styled('div')(() => ({
  display: 'flex',
  borderRadius: 5,
  overflow: 'hidden',
  alignItems: 'center',
  position: 'relative',
  justifyContent: 'center',
  width: 'calc(100% - 12px)',
  height: 'calc(100% - 12px)',
  [`&:hover .${prefixCls}-replace-icon`]: {
    opacity: 1,
    backgroundColor: 'rgba(0,0,0,.65)',
  },
}));

export const ReplaceIconWrapper = styled('div')(() => ({
  zIndex: 1,
  width: 34,
  opacity: 0,
  height: 34,
  fontSize: 16,
  display: 'flex',
  borderRadius: '50%',
  position: 'relative',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: 'rgba(0,0,0,.45)',
  willChange: 'opacity, background-color',
  transition: 'opacity .4s ease,background-color .4s ease',
}));

export const StyledUploadWrapper = styled('div')(() => ({
  gap: 15,
  width: '100%',
  display: 'flex',
  borderRadius: 5,
  flexWrap: 'wrap',
  paddingRight: '2.5rem',
  overflow: 'hidden',
  [`& .${prefixCls}-upload-wrapper`]: {
    width: '100%',
  },
}));
