import styled from '@emotion/styled';

const gTC = `minmax(calc(${100 / 3}% - 16px), 300px)`;

export const TemplatesListContainer = styled('div')(() => ({
  width: '100%',
  display: 'grid',
  gap: '2.4rem 1.6rem',
  paddingBlock: '1.6rem',
  justifyContent: 'center',
  gridTemplateColumns: `${gTC} ${gTC} ${gTC}`,
  '& > div': {
    cursor: 'pointer',
    transition: 'all 0.2s ease',
  },
  '@media (max-width: 991px)': {
    gridTemplateColumns: `${gTC} ${gTC}`,
  },
  '@media (max-width: 768px)': {
    gridTemplateColumns: `${gTC} ${gTC}`,
  },
}));
