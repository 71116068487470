import { CSSObject } from '@emotion/styled';

const background =
  'linear-gradient(90deg, rgba(245, 245, 245, 0.00) 0%, #F5F5F5 70.31%, #F5F5F5 100%)';

const darkBackground =
  'linear-gradient(90deg, rgba(0, 21, 41, 0.00) 0%, #001529 68.23%, #001529 100%)';

export const sampleNextArrowStyles = (
  className: string,
  drawerTheme: any,
): CSSObject => ({
  right: 0,
  width: 70,
  top: '50%',
  height: '100%',
  position: 'absolute',
  alignItems: 'center',
  justifyContent: 'center',
  transform: 'translate(0, -50%)',
  display: className.includes('slick-disabled') ? 'none' : 'flex',
  background: drawerTheme === 'dark' ? darkBackground : background,
});

export const samplePrevArrowStyles = (
  className: string,
  drawerTheme: any,
): CSSObject => ({
  left: 0,
  zIndex: 1,
  width: 70,
  top: '50%',
  height: '100%',
  position: 'absolute',
  alignItems: 'center',
  justifyContent: 'center',
  transform: 'translate(-2.4rem, -50%) rotate(180deg)',
  display: className.includes('slick-disabled') ? 'none' : 'flex',
  background: drawerTheme === 'dark' ? darkBackground : background,
});

export const carouselStyles: CSSObject = {
  [`&.slick-slider .slick-list .slick-track .slick-slide`]: {
    pointerEvents: 'auto',
  },
};
