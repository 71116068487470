import { CSSObject } from '@emotion/styled';

export const textStyles: CSSObject = {
  fontWeight: 600,
  color: '#5956E9',
  cursor: 'pointer',
  fontSize: '1.4rem',
  lineHeight: '2.2rem',
  marginRight: '1.6rem',
  '&:hover': { opacity: 0.8 },
};
