import React from 'react';
import { Box } from '@shared/ui/box';
import useBreakpoint from '@/lib/hooks/useBreakpoint';

export const HeightToggler: React.FC<{ onClick: () => void }> = ({
  onClick,
}) => {
  const matches = useBreakpoint(991);

  let styles: React.CSSProperties = { right: 0 };

  if (matches) {
    styles = { left: 0 };
  }

  return (
    <Box
      onClick={onClick}
      boxStyles={{
        top: 0,
        height: 57,
        position: 'absolute',
        width: 'calc(100% - 70px)',
        ...styles,
      }}
    />
  );
};
