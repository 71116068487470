import React from 'react';
import { textStyles } from './styles';
import { Text } from '@shared/ui/typography';
import { selectDrawerTheme, useSelector } from '@shared/redux';

export const SeeAll: React.FC<{
  count: number;
  onClick?: () => void;
}> = ({ count, onClick }) => {
  const drawerTheme = useSelector(selectDrawerTheme);
  const color = drawerTheme === 'dark' ? '#fff' : void 0;

  return (
    <Text onClick={onClick} textProps={{ ...textStyles, color }}>
      See all ({count > 99 ? '99+' : count})
    </Text>
  );
};
