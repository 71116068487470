import React from 'react';
import { message, Upload } from 'antd';
import { RcFile } from 'antd/es/upload';
import { Builder } from '@/lib/types/builder';
import { allowedImages } from '@shared/constants';
import { selectCounter, useSelector } from '@shared/redux';
import { BasicDemoCard } from '@shared/ui/basic-demo-card';
import { largeSize } from '@shared/constants/builderCardSizes';
import { useLocalPostHog } from '@shared/hooks/useLocalPostHog';
import { PosthogEvents } from '@shared/constants/posthog-events';
import { useUploadImageMutation } from '@/lib/services/mediakit';
import { getBasicComponentsDetails } from '@widgets/mediakit/helpers';

export function ImageDemo({
  slug,
  data,
  size,
  onAddItem,
}: Builder.DemoComponentProps) {
  const posthog = useLocalPostHog();
  const [upload] = useUploadImageMutation();
  const counter = useSelector(selectCounter);
  const detailsFunc = getBasicComponentsDetails()[data.name];

  const { title, icon } = detailsFunc();

  const beforeUpload = (file: RcFile) => {
    const isAllowedFormat = allowedImages.includes(file.type);

    if (!isAllowedFormat) {
      message.error('You can only upload jpg/jpeg/png/webp file!').then();

      return false;
    }

    const isAllowedSize = file.size / 1024 / 1024 < 20;

    if (!isAllowedSize) {
      message.error('Image must smaller than 20MB!').then();

      return false;
    }

    return true;
  };

  const onClick = () => {
    try {
      posthog?.capture(PosthogEvents.ImageComponent);
    } catch (e) {
      console.error('Posthog error:', e);
    }
  };

  return (
    <Upload
      name="image"
      method="POST"
      showUploadList={false}
      beforeUpload={beforeUpload}
      accept={allowedImages.join(',')}
      customRequest={async ({ file }) => {
        const formData = new FormData();

        formData.append('image', file);
        formData.append('uid', `${data.name}_+_${counter}`);

        if (typeof onAddItem === 'function') {
          onAddItem({
            currentTarget: {
              dataset: {
                w: largeSize.w,
                h: largeSize.h,
                type: data.name,
                platform: `${data.platform}`,
              },
            },
          } as any);
        }

        if (slug) {
          upload({ slug, data: formData });
        }
      }}
    >
      <BasicDemoCard
        h={2}
        w={2}
        icon={icon}
        size={size}
        title={title}
        type={data.name}
        onClick={onClick}
        platform={data.platform}
      />
    </Upload>
  );
}
