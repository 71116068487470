import React from 'react';
import { Button, Flex } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { onToggleOnboardingModal, useDispatch } from '@shared/redux';

export const CardTitle: React.FC = () => {
  const dispatch = useDispatch();

  const onClose = () => {
    dispatch(onToggleOnboardingModal(false));
  };

  return (
    <Flex align="center" justify="space-between">
      <span>Setup Your Media Kit</span>
      <Button type="text" shape="circle" onClick={onClose}>
        <CloseOutlined />
      </Button>
    </Flex>
  );
};
