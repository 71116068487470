import React from 'react';
import { AddComponentTarget } from '@widgets/mkit-tour/ui/add-component-target';

export const FloatButtonIcon: React.FC = () => {
  return (
    <>
      <svg
        width="20"
        height="20"
        fill="none"
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_7577_18911)">
          <path
            fill="black"
            d="M17 9H11V3C11 2.73478 10.8946 2.48043 10.7071 2.29289C10.5196 2.10536 10.2652 2 10 2C9.73478 2 9.48043 2.10536 9.29289 2.29289C9.10536 2.48043 9 2.73478 9 3V9H3C2.73478 9 2.48043 9.10536 2.29289 9.29289C2.10536 9.48043 2 9.73478 2 10C2 10.2652 2.10536 10.5196 2.29289 10.7071C2.48043 10.8946 2.73478 11 3 11H9V17C9 17.2652 9.10536 17.5196 9.29289 17.7071C9.48043 17.8946 9.73478 18 10 18C10.2652 18 10.5196 17.8946 10.7071 17.7071C10.8946 17.5196 11 17.2652 11 17V11H17C17.2652 11 17.5196 10.8946 17.7071 10.7071C17.8946 10.5196 18 10.2652 18 10C18 9.73478 17.8946 9.48043 17.7071 9.29289C17.5196 9.10536 17.2652 9 17 9Z"
          />
        </g>
        <defs>
          <clipPath id="clip0_7577_18911">
            <rect width="20" height="20" fill="white" />
          </clipPath>
        </defs>
      </svg>

      <AddComponentTarget />
    </>
  );
};
