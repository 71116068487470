import React from 'react';
import { Tour } from 'antd';
import { useMkitTour } from './hooks/use-mkit-tour';
import { selectFirstTour, useSelector } from '@shared/redux';
import './styles.css';

export const MkitTour = () => {
  const { open, steps, current } = useMkitTour();
  const firstTour = useSelector(selectFirstTour);

  if (firstTour === undefined) {
    return null;
  }

  return (
    <Tour
      open={open}
      mask={false}
      steps={steps}
      current={current}
      rootClassName="tourRootClassName"
    />
  );
};
