import React from 'react';
import { spaces } from '@shared/theme';
import { Flex, Typography } from 'antd';
import { avatarSizeMD } from './constants';
import { CharsContainer } from './chars-container';
import { HandleContainer } from './handle-container';
import { ProfileMenuAvatar } from './profile-menu-avatar';
import { selectAuthUser, useSelector } from '@shared/redux';

const { Text } = Typography;

export const AvatarLabel = () => {
  const authUser = useSelector(selectAuthUser);
  const chars = authUser?.email?.slice(0, 2)?.toUpperCase();

  return (
    <Flex gap={spaces.space8} align="center">
      <ProfileMenuAvatar
        shape="circle"
        size={avatarSizeMD}
        src={authUser?.profile_photo_path}
      >
        <CharsContainer>
          <Text color="primary">{chars}</Text>
        </CharsContainer>
      </ProfileMenuAvatar>
      <HandleContainer>
        {!!authUser?.handle ? <Text ellipsis>{authUser?.handle}</Text> : null}
        <Text ellipsis type="secondary">
          {authUser?.email}
        </Text>
      </HandleContainer>
    </Flex>
  );
};
