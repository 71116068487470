import React from 'react';
import { Builder } from '@/lib/types/builder';
import { BasicDemoCard } from '@shared/ui/basic-demo-card';
import { useLocalPostHog } from '@shared/hooks/useLocalPostHog';
import { PosthogEvents } from '@shared/constants/posthog-events';
import { ComponentNames } from '@shared/constants/componentNames';
import { getBasicComponentsDetails } from '@widgets/mediakit/helpers';
import {
  commonSizes,
  titleComponentSize,
} from '@shared/constants/builderCardSizes';

const names: any = {
  [ComponentNames.basics_info_card]: PosthogEvents.InfoCard,
  [ComponentNames.basics_link]: PosthogEvents.LinkComponent,
  [ComponentNames.basics_text]: PosthogEvents.TextComponent,
  [ComponentNames.basics_video]: PosthogEvents.VideoComponent,
  [ComponentNames.basics_title]: PosthogEvents.TitleComponent,
  [ComponentNames.basics_gallery]: PosthogEvents.GalleryComponent,
};

export function CommonDemo({
  data,
  size,
  seeAll,
  onAddItem,
}: Builder.DemoComponentProps) {
  const posthog = useLocalPostHog();
  const detailsFunc = getBasicComponentsDetails()[data.name];

  const { title, icon } = detailsFunc();

  let w: number = commonSizes.w;
  let h: number = commonSizes.h;

  if (data.name === ComponentNames.basics_title) {
    w = titleComponentSize.w;
    h = titleComponentSize.h;
  }

  const onClick = (e: any) => {
    if (typeof onAddItem === 'function') {
      onAddItem(e);
    }

    const posthogEvent = names[data.name];

    if (posthogEvent) {
      try {
        let properties;
        if (seeAll) {
          properties = {
            seeAll,
          };
        }

        posthog?.capture(posthogEvent, properties);
      } catch (e) {
        console.error('Posthog error:', e);
      }
    }
  };

  return (
    <BasicDemoCard
      h={h}
      w={w}
      size={size}
      icon={icon}
      title={title}
      type={data.name}
      onClick={onClick}
      platform={data.platform}
    />
  );
}
