import React from 'react';
import { Flex } from 'antd';
import Image from 'next/image';
import styled from '@emotion/styled';
import { ThemeFilter } from '@shared/constants/themeFilter';
import fonts from '@widgets/mediakit/ThemeDrawer/icons/fonts.svg';
import color from '@widgets/mediakit/ThemeDrawer/icons/color.svg';
import background from '@widgets/mediakit/ThemeDrawer/icons/background.svg';
import fontsActive from '@widgets/mediakit/ThemeDrawer/icons/fontsActive.svg';
import colorActive from '@widgets/mediakit/ThemeDrawer/icons/colorActive.svg';
import cardsStyles from '@widgets/mediakit/ThemeDrawer/icons/cardsStyles.svg';
import backgroundActive from '@widgets/mediakit/ThemeDrawer/icons/backgroundActive.svg';
import cardsStylesActive from '@widgets/mediakit/ThemeDrawer/icons/cardsStylesActive.svg';
import {
  useSelector,
  useDispatch,
  selectThemeFilter,
  onChangeThemeFilter,
} from '@shared/redux';
import { useLocalPostHog } from '@shared/hooks/useLocalPostHog';
import { useUpgradeModal } from '@shared/hooks/useUpgradeModal';
import { PosthogEvents } from '@shared/constants/posthog-events';
import useBreakpoint from '@/lib/hooks/useBreakpoint';
import { breakpoints } from '@shared/theme';

const wrapperStyle: React.CSSProperties = {
  width: '100%',
  alignItems: 'center',
  backgroundColor: '#001529',
};

const flexStyles: React.CSSProperties = {
  width: '100%',
  paddingBlock: '2rem',
};

const FontsBtn = () => {
  const dispatch = useDispatch();
  const posthog = useLocalPostHog();
  const themeFilter = useSelector(selectThemeFilter);

  const isActive = ThemeFilter.allFonts === themeFilter;

  const handleChangeFilter = () => {
    if (isActive) return;

    dispatch(onChangeThemeFilter(ThemeFilter.allFonts));

    try {
      posthog?.capture(PosthogEvents.ThemingFonts);
    } catch (e) {
      console.error('Posthog error:', e);
    }
  };

  return (
    <Card onClick={handleChangeFilter}>
      <Div>
        <Image alt="basic" src={isActive ? fontsActive : fonts} />
      </Div>
      <Helper active={isActive}>Fonts</Helper>
    </Card>
  );
};

const ColorBtn = () => {
  const dispatch = useDispatch();
  const posthog = useLocalPostHog();
  const themeFilter = useSelector(selectThemeFilter);

  const isActive =
    ThemeFilter.colors === themeFilter ||
    ThemeFilter.allAccentColor === themeFilter ||
    typeof themeFilter === 'number';

  const handleChangeFilter = () => {
    if (isActive) return;

    dispatch(onChangeThemeFilter(ThemeFilter.colors));

    try {
      posthog?.capture(PosthogEvents.ThemingColor);
    } catch (e) {
      console.error('Posthog error:', e);
    }
  };

  return (
    <Card onClick={handleChangeFilter}>
      <Div>
        <Image alt="basic" src={isActive ? colorActive : color} />
      </Div>
      <Helper active={isActive}>Color</Helper>
    </Card>
  );
};

const BackgroundBtn = () => {
  const dispatch = useDispatch();
  const posthog = useLocalPostHog();
  const themeFilter = useSelector(selectThemeFilter);

  const isActive = ThemeFilter.allBackgrounds === themeFilter;

  const handleChangeFilter = () => {
    if (isActive) return;

    dispatch(onChangeThemeFilter(ThemeFilter.allBackgrounds));

    try {
      posthog?.capture(PosthogEvents.ThemingBackground);
    } catch (e) {
      console.error('Posthog error:', e);
    }
  };

  return (
    <Card onClick={handleChangeFilter}>
      <Div>
        <Image alt="basic" src={isActive ? backgroundActive : background} />
      </Div>
      <Helper active={isActive}>Background</Helper>
    </Card>
  );
};

const CardsStylesBtn = () => {
  const isActive = false;

  const posthog = useLocalPostHog();
  const { handleOpen } = useUpgradeModal();

  const onClick = () => {
    handleOpen('upgrade', {
      from: 'theme_drawer_cards_styles',
    });

    try {
      posthog?.capture(PosthogEvents.UpgradeClick);
    } catch (e) {
      console.error('Posthog error:', e);
    }
  };

  return (
    <Card onClick={onClick}>
      <Div>
        <Image alt="basic" src={isActive ? cardsStylesActive : cardsStyles} />
      </Div>
      <Helper active={isActive}>Cards Style</Helper>
    </Card>
  );
};

const Div = styled('div')(() => ({
  width: 64,
  height: 64,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const Card = styled('div')(() => ({
  gap: 5,
  display: 'flex',
  cursor: 'pointer',
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'center',
}));

const Divider = styled('div')(() => ({
  height: 1,
  width: '100%',
  backgroundColor: 'rgba(255, 255, 255, 0.20)',
}));

const Helper = styled('span', {
  shouldForwardProp: (prop) => prop !== 'active',
})(({ active }: { active?: boolean }) => ({
  fontSize: 14,
  lineHeight: '22px',
  fontWeight: active ? 700 : 500,
  color: 'rgba(255, 255, 255, 0.88)',
}));

export const Head: React.FC = () => {
  const isXS = useBreakpoint(479);

  return (
    <Flex
      vertical
      style={{ ...wrapperStyle, paddingInline: isXS ? '1rem' : '2.4rem' }}
    >
      <Flex gap={20} justify="flex-start" style={flexStyles}>
        <BackgroundBtn />
        <ColorBtn />
        <FontsBtn />
        <CardsStylesBtn />
      </Flex>
      <Divider />
    </Flex>
  );
};
