import useSWR from 'swr';
import axios from 'axios';
import { InternetConnectionStatus } from '@shared/constants/internetConnectionStatus';
import {
  useDispatch,
  useSelector,
  onChangeInternetConnection,
  selectInternetConnectionStatus,
} from '@shared/redux';

const fetcher = (url: string) => axios.get(url, { timeout: 5000 });

export const useCheckInternetConnection = () => {
  const dispatch = useDispatch();
  const status = useSelector(selectInternetConnectionStatus);

  const isPaused = () => process.env.NODE_ENV === 'development';

  const onError = () => {
    if (status === InternetConnectionStatus.online) {
      dispatch(onChangeInternetConnection(InternetConnectionStatus.offline));
    }
  };

  const onSuccess = () => {
    if (status === InternetConnectionStatus.offline) {
      dispatch(onChangeInternetConnection(InternetConnectionStatus.online));
    }
  };

  useSWR('/api/health-check', fetcher, {
    onError,
    isPaused,
    onSuccess,
    refreshInterval: 3000,
    keepPreviousData: false,
    refreshWhenHidden: false,
    shouldRetryOnError: true,
    refreshWhenOffline: true,
    errorRetryInterval: 3000,
    revalidateOnReconnect: true,
  });

  return {};
};
