'use client';

import React from 'react';
import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';
import {
  onShowDrawer,
  selectAllLayout,
  useDispatch,
  useSelector,
} from '@shared/redux';

const shape1 = keyframes`
    0% {
        width: 364px;
        height: 364px;
    }
    33.3% {
        width: 170px;
        height: 364px;
    }
    66.6% {
        width: 170px;
        height: 364px;
    }
    100% {
        width: 364px;
        height: 364px;
    }
`;

const shape2 = keyframes`
    0% {
        width: 170px;
        height: 364px;
    }
    33.3% {
        width: 364px;
        height: 364px;
    }
    66.6% {
        width: 170px;
        height: 364px;
    }
    100% {
        width: 170px;
        height: 364px;
    }
`;

const shape3 = keyframes`
    0% {
        width: 170px;
        height: 364px;
    }
    33.3% {
        width: 170px;
        height: 364px;
    }
    66.6% {
        width: 364px;
        height: 364px;
    }
    100% {
        width: 170px;
        height: 364px;
    }
`;

const shape4 = keyframes`
    0% {
        width: 170px;
        height: 170px;
    }
    33.3% {
        width: 170px;
        height: 170px;
    }
    66.6% {
        width: 364px;
        height: 170px;
    }
    100% {
        width: 170px;
        height: 170px;
    }
`;

const shape5 = keyframes`
    0% {
        width: 364px;
        height: 170px;
    }
    33.3% {
        width: 170px;
        height: 170px;
    }
    66.6% {
        width: 170px;
        height: 170px;
    }
    100% {
        width: 364px;
        height: 170px;
    }
`;

const shape6 = keyframes`
    0% {
        width: 170px;
        height: 170px;
    }
    33.3% {
        width: 364px;
        height: 170px;
    }
    66.6% {
        width: 170px;
        height: 170px;
    }
    100% {
        width: 170px;
        height: 170px;
    }
`;

const mShape1 = keyframes`
    0% {
        width: 364px;
    }
    25% {
        width: 170px;
    }
    50% {
        width: 170px;
    }
    75% {
        width: 364px;
    }
    100% {
        width: 364px;
    }
`;

const mShape2 = keyframes`
    0% {
        width: 170px;
        margin-top: 206px;
        margin-left: -376px;
    }
    25% {
        width: 170px;
        margin-top: 12px;
        margin-left: 12px;
    }
    50% {
        width: 170px;
        margin-top: 12px;
        margin-left: 12px;
    }
    75% {
        width: 170px;
        margin-top: 206px;
        margin-left: -376px;
    }
    100% {
        width: 170px;
        margin-top: 206px;
        margin-left: -376px;
    }
`;

const mShape4 = keyframes`
    0% {
        width: 170px;
        margin-top: -182px;
        margin-left: 206px;
    }
    25% {
        width: 364px;
        margin-top: 12px;
        margin-left: 12px;
    }
    50% {
        width: 364px;
        margin-top: 12px;
        margin-left: 12px;
    }
    75% {
        width: 170px;
        margin-top: -182px;
        margin-left: 206px;
    }
    100% {
        width: 170px;
        margin-top: -182px;
        margin-left: 206px;
    }
`;

const shapeShadow =
  'rgba(0, 0, 0, 0.02) 0 2px 4px 0, rgba(0, 0, 0, 0.02) 0 1px 6px -1px, rgba(0, 0, 0, 0.03) 0 1px 2px 0';

const EmptyStateContainer = styled('div')(() => ({
  '&.container': {
    width: '100%',
    display: 'flex',
    marginTop: '-1rem',
    borderRadius: '2.4rem',
    flexDirection: 'column',
    '@media (max-width: 991px)': {
      display: 'flex',
      width: 'initial',
      margin: '-1rem',
      flexDirection: 'column',
    },
    '& > .row': {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      '@media (max-width: 991px)': {
        width: 388,
        display: 'flex',
        flexDirection: 'row',
        marginLeft: 'calc(50% - 194px)',
      },
      '& > .shape': {
        margin: '1rem',
        cursor: 'pointer',
        position: 'relative',
        borderRadius: '2.4rem',
        boxShadow: shapeShadow,
        transition: 'background-color 0.4s ease',
        backgroundColor: 'hsla(0, 0%, 100%, 0.4)',
        backdropFilter: 'saturate(120%) blur(5px)',
        '&::after': {
          top: '50%',
          opacity: 0,
          left: '50%',
          content: "' '",
          width: '2.4rem',
          height: '2.4rem',
          position: 'absolute',
          margin: '-1rem 0 0 -1rem',
          transition: 'opacity 0.4s ease',
          backgroundImage: "url('/plusGradient.svg')",
        },
        '&:hover': {
          backgroundColor: 'hsla(0, 0%, 100%, 0.8)',
          '&::after': {
            opacity: 1,
          },
        },

        '@media (max-width: 991px)': {
          opacity: 0.4,
          margin: '1rem',
          boxShadow: 'none',
          position: 'static',
          backdropFilter: 'none',
          borderRadius: '2.4rem',
          backgroundColor: '#fff',
          '&::after': {
            backgroundImage: 'none',
          },
        },
      },
      '& > .shape.shape-1': {
        width: 364,
        height: 364,
        animation: `4s infinite ${shape1} ease`,

        '@media (max-width: 991px)': {
          width: 170,
          height: 170,
          animation: `6s infinite ${mShape1} ease`,
        },
      },
      '& > .shape.shape-2': {
        width: 170,
        height: 364,
        animation: `4s infinite ${shape2} ease`,

        '@media (max-width: 991px)': {
          width: 170,
          height: 170,
          animation: `6s infinite ${mShape2} ease`,
        },
      },
      '& > .shape.shape-3': {
        width: 170,
        height: 364,
        animation: `4s infinite ${shape3} ease`,
        '@media (max-width: 991px)': {
          display: 'none',
        },
      },
      '& > .shape.shape-4': {
        width: 170,
        height: 170,
        animation: `4s infinite ${shape4} ease`,
        '@media (max-width: 991px)': {
          width: 170,
          height: 170,
          animation: `6s infinite ${mShape4} ease`,
        },
      },
      '& > .shape.shape-5': {
        width: 364,
        height: 170,
        animation: `4s infinite ${shape5} ease`,
        '@media (max-width: 991px)': {
          display: 'none',
        },
      },
      '& > .shape.shape-6': {
        width: 170,
        height: 170,
        animation: `4s infinite ${shape6} ease`,
        '@media (max-width: 991px)': {
          display: 'none',
        },
      },
    },
    '&:hover  > .row > .shape': {
      animationPlayState: 'paused',
      '@media (max-width: 991px)': {
        animationPlayState: 'running',
      },
    },
  },
}));

const w = 378;
const w2 = 170;

const MobileEmptyStateContainer = styled('div')(() => ({
  '&.container': {
    display: 'flex',
    margin: '-1rem',
    width: 'initial',
    marginTop: '-1rem',
    borderRadius: '2.4rem',
    flexDirection: 'column',
    '& > .row': {
      width: w,
      display: 'flex',
      flexDirection: 'row',
      marginLeft: `calc(50% - ${w / 2}px)`,
      '& > .shape': {
        cursor: 'pointer',
        opacity: 0.4,
        margin: '1rem',
        boxShadow: 'none',
        position: 'static',
        backdropFilter: 'none',
        borderRadius: '2.4rem',
        backgroundColor: '#fff',
        transition: 'background-color 0.4s ease',
        '&::after': {
          backgroundImage: 'none',
        },
        '&:hover': {
          backgroundColor: 'hsla(0, 0%, 100%, 0.8)',
          '&::after': {
            opacity: 1,
          },
        },
      },
      '& > .shape.shape-1': {
        width: w2,
        height: w2,
        animation: `6s infinite ${mShape1} ease`,
      },
      '& > .shape.shape-2': {
        width: w2,
        height: w2,
        animation: `6s infinite ${mShape2} ease`,
      },
      '& > .shape.shape-3': {
        display: 'none',
      },
      '& > .shape.shape-4': {
        width: w2,
        height: w2,
        animation: `6s infinite ${mShape4} ease`,
      },
      '& > .shape.shape-5': {
        display: 'none',
      },
      '& > .shape.shape-6': {
        display: 'none',
      },
    },
    '&:hover  > .row > .shape': {
      animationPlayState: 'running',
    },
  },
}));

export const EmptyStateProvider: React.FC<
  React.PropsWithChildren<{
    shouldFetch?: boolean;
    previewMode: 'mobile' | 'desktop';
  }>
> = ({ children, previewMode, shouldFetch }) => {
  const dispatch = useDispatch();
  const allLayouts = useSelector(selectAllLayout);
  const isEmpty = !allLayouts.sm.length && !allLayouts.xxs.length;

  const openDrawer = () => {
    dispatch(onShowDrawer());
  };

  const isMobile = previewMode === 'mobile';

  if (shouldFetch) {
    return children;
  }

  return isEmpty ? (
    isMobile ? (
      <MobileEmptyStateContainer className="container">
        <div className="row">
          <div onClick={openDrawer} className="shape shape-1" />
          <div onClick={openDrawer} className="shape shape-2" />
          <div onClick={openDrawer} className="shape shape-3" />
        </div>
        <div className="row">
          <div onClick={openDrawer} className="shape shape-4" />
          <div onClick={openDrawer} className="shape shape-5" />
          <div onClick={openDrawer} className="shape shape-6" />
        </div>
      </MobileEmptyStateContainer>
    ) : (
      <EmptyStateContainer className="container">
        <div className="row">
          <div onClick={openDrawer} className="shape shape-1" />
          <div onClick={openDrawer} className="shape shape-2" />
          <div onClick={openDrawer} className="shape shape-3" />
        </div>
        <div className="row">
          <div onClick={openDrawer} className="shape shape-4" />
          <div onClick={openDrawer} className="shape shape-5" />
          <div onClick={openDrawer} className="shape shape-6" />
        </div>
      </EmptyStateContainer>
    )
  ) : (
    children
  );
};
