import React from 'react';
import { Typography } from 'antd';
import { Box } from '@shared/ui/box';
import styled from '@emotion/styled';
import { selectDrawerTheme, useSelector } from '@shared/redux';
import { useLocalPostHog } from '@shared/hooks/useLocalPostHog';
import { BackgroundTypes } from '@shared/constants/background-types';
import { UploadIcon } from '@widgets/mediakit/ThemeDrawer/icons/upload';
import { Unsplash } from '@widgets/mediakit/ThemeDrawer/icons/unsplash';
import { Branded } from '@widgets/mediakit/ThemeDrawer/icons/branded';
import { breakpoints } from '@shared/theme';

const shadow =
  '0px 1px 2px 0px rgba(0, 0, 0, 0.03), 0px 1px 6px -1px rgba(0, 0, 0, 0.02), 0px 2px 4px 0px rgba(0, 0, 0, 0.02)';

const Card = styled('div', {
  shouldForwardProp: (prop) => !['drawerTheme', 'isSelected'].includes(prop),
})(({
  isSelected,
  drawerTheme,
}: {
  isSelected?: boolean;
  drawerTheme?: 'light' | 'dark';
}) => {
  let background;
  let border = '2px solid rgba(0, 0, 0, 0.06)';

  if (drawerTheme === 'dark') {
    border = '2px solid rgba(0, 0, 0, 0.06)';
    background = 'rgba(255, 255, 255, 0.20)';
  }

  if (isSelected) {
    border = '2px solid #5956E9';

    if (drawerTheme === 'dark') {
      background = '#fff';
    }
  }

  return {
    gap: 8,
    border,
    height: 38,
    background,
    display: 'flex',
    borderRadius: 8,
    cursor: 'pointer',
    boxShadow: shadow,
    padding: '8px 10px',
    alignItems: 'center',
  };
});

const CardBody = styled('div')(() => {
  return {
    display: 'flex',
    borderRadius: 8,
    alignItems: 'center',
    justifyContent: 'center',
  };
});

const HelpText = styled(Typography.Text, {
  shouldForwardProp: (prop) => !['drawerTheme', 'isSelected'].includes(prop),
})(({
  isSelected,
  drawerTheme,
}: {
  isSelected?: boolean;
  drawerTheme?: 'dark' | 'light';
}) => {
  let fontWeight = 400;
  let color = drawerTheme === 'dark' ? 'rgba(255, 255, 255, 0.88)' : void 0;

  if (isSelected) {
    fontWeight = 600;
    color = 'rgba(0, 0, 0, 0.88)';
  }

  return {
    color,
    fontWeight,
  };
});

const UploadButton: React.FC<{
  bgImageType: any;
  setBgImageType: any;
}> = ({ bgImageType, setBgImageType }) => {
  const posthog = useLocalPostHog();
  const drawerTheme = useSelector(selectDrawerTheme);

  const isSelected = bgImageType === BackgroundTypes.Static;

  const onClick = () => {
    setBgImageType(BackgroundTypes.Static);

    try {
      posthog?.capture(BackgroundTypes.Static);
    } catch (e) {
      console.error('Posthog error:', e);
    }
  };

  let fill = '#fff';
  let fillOpacity = 0.45;

  if (isSelected || drawerTheme === 'dark') {
    fillOpacity = 1;
  }

  if (drawerTheme === 'dark') {
    fill = '#fff';
  }

  if (isSelected) {
    fill = '#5956E9';
  }

  return (
    <Card isSelected={isSelected} drawerTheme={drawerTheme} onClick={onClick}>
      <CardBody>
        <UploadIcon fill={fill} fillOpacity={fillOpacity} />
      </CardBody>
      <HelpText isSelected={isSelected} drawerTheme={drawerTheme}>
        Upload
      </HelpText>
    </Card>
  );
};

const UnsplashButton: React.FC<{
  bgImageType: any;
  setBgImageType: any;
}> = ({ bgImageType, setBgImageType }) => {
  const posthog = useLocalPostHog();
  const drawerTheme = useSelector(selectDrawerTheme);

  const isSelected = bgImageType === BackgroundTypes.Unsplash;

  const onClick = () => {
    setBgImageType(BackgroundTypes.Unsplash);

    try {
      posthog?.capture(BackgroundTypes.Unsplash);
    } catch (e) {
      console.error('Posthog error:', e);
    }
  };

  let fill = '#fff';
  let fillOpacity = 0.45;

  if (isSelected || drawerTheme === 'dark') {
    fillOpacity = 1;
  }

  if (drawerTheme === 'dark') {
    fill = '#fff';
  }

  if (isSelected) {
    fill = '#5956E9';
  }

  return (
    <Card isSelected={isSelected} drawerTheme={drawerTheme} onClick={onClick}>
      <CardBody>
        <Unsplash fill={fill} fillOpacity={fillOpacity} />
      </CardBody>
      <HelpText isSelected={isSelected} drawerTheme={drawerTheme}>
        Unsplash
      </HelpText>
    </Card>
  );
};

const ViralMangoButton: React.FC<{
  bgImageType: any;
  setBgImageType: any;
}> = ({ bgImageType, setBgImageType }) => {
  const posthog = useLocalPostHog();
  const drawerTheme = useSelector(selectDrawerTheme);

  const isSelected = bgImageType === BackgroundTypes.ViralMango;

  const onClick = () => {
    setBgImageType(BackgroundTypes.ViralMango);

    try {
      posthog?.capture(BackgroundTypes.ViralMango);
    } catch (e) {
      console.error('Posthog error:', e);
    }
  };

  let fill = '#fff';
  let fillOpacity = 0.45;

  if (isSelected || drawerTheme === 'dark') {
    fillOpacity = 1;
  }

  if (drawerTheme === 'dark') {
    fill = '#fff';
  }

  if (isSelected) {
    fill = '#5956E9';
  }

  return (
    <Card isSelected={isSelected} drawerTheme={drawerTheme} onClick={onClick}>
      <CardBody>
        <Branded fill={fill} fillOpacity={fillOpacity} />
      </CardBody>
      <HelpText isSelected={isSelected} drawerTheme={drawerTheme}>
        Branded
      </HelpText>
    </Card>
  );
};

export const BgTypes: React.FC<{
  bgImageType: any;
  setBgImageType: any;
}> = ({ bgImageType, setBgImageType }) => {
  return (
    <Box
      boxStyles={{
        gap: 16,
        maxWidth: 390,
        justifyContent: 'space-between',
        [`@media (max-width: ${breakpoints.xs})`]: {
          gap: 12,
          '& > div': {
            paddingInline: 8,
          },
        },
      }}
    >
      <ViralMangoButton
        bgImageType={bgImageType}
        setBgImageType={setBgImageType}
      />
      <UnsplashButton
        bgImageType={bgImageType}
        setBgImageType={setBgImageType}
      />
      <UploadButton bgImageType={bgImageType} setBgImageType={setBgImageType} />
    </Box>
  );
};
