import React from 'react';
import { Badge, Skeleton, Tag, Tooltip } from 'antd';
import styled, { CSSObject } from '@emotion/styled';
import { useRouter, useSearchParams } from 'next/navigation';
import { UnverifiedModal } from '@widgets/verification/ui/unverified-modal';
import { VerificationStatuses } from '@shared/constants/verificationStatuses';
import {
  useDispatch,
  useSelector,
  selectAuthUserHandle,
  onToggleVerificationModal,
  selectVerificationStatus,
} from '@shared/redux';
import { prefixCls } from '@shared/theme';

const unVerifiedIcon = (
  <svg
    width="15"
    height="14"
    fill="none"
    viewBox="0 0 15 14"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_7377_62092)">
      <path
        stroke="#5956E9"
        d="M10.6216 12.0304L10.7186 12.5209L10.6216 12.0304C10.3369 12.0867 10.0747 12.2243 9.86664 12.4266L9.24647 13.0298C9.11682 13.1559 8.92917 13.2022 8.75576 13.1507L7.92632 12.9049L7.78421 13.3842L7.92632 12.9049C7.64809 12.8224 7.35191 12.8224 7.07368 12.9049L7.21579 13.3842L7.07368 12.9049L6.24424 13.1507C6.07083 13.2022 5.88318 13.1559 5.75353 13.0298L5.13336 12.4266C4.92533 12.2243 4.66307 12.0867 4.37839 12.0304L4.28143 12.5209L4.37838 12.0304L3.52968 11.8626C3.35225 11.8276 3.20759 11.6994 3.15139 11.5275L2.88256 10.7052C2.79239 10.4294 2.62414 10.1856 2.39821 10.0035L1.72468 9.46055C1.58387 9.34704 1.51534 9.16633 1.54546 8.988L1.68957 8.13496C1.73791 7.84882 1.70221 7.5548 1.5868 7.28854L1.24273 6.49479C1.1708 6.32884 1.1941 6.13698 1.30365 5.99307L1.82768 5.30472C2.00345 5.07382 2.10848 4.79689 2.13003 4.50749L2.19425 3.64476C2.20768 3.46439 2.31747 3.30534 2.48135 3.22882L3.26524 2.86284C3.52819 2.74008 3.74989 2.54367 3.90345 2.29744L4.36125 1.56338C4.45696 1.40991 4.62809 1.32009 4.80876 1.32851L5.67294 1.36874C5.96282 1.38224 6.2504 1.31136 6.5008 1.16469L7.2473 0.72746C7.40337 0.63605 7.59663 0.63605 7.7527 0.72746L8.4992 1.16469C8.7496 1.31136 9.03718 1.38224 9.32706 1.36874L10.1912 1.32851C10.3719 1.32009 10.543 1.40991 10.6388 1.56338L11.0965 2.29744C11.2501 2.54368 11.4718 2.74008 11.7348 2.86284L12.5187 3.22882C12.6825 3.30534 12.7923 3.46439 12.8057 3.64476L12.87 4.50749C12.8915 4.79689 12.9965 5.07382 13.1723 5.30472L13.6963 5.99307C13.8059 6.13698 13.8292 6.32884 13.7573 6.49479L13.4132 7.28854C13.2978 7.5548 13.2621 7.84882 13.3104 8.13496L13.4545 8.988C13.4847 9.16633 13.4161 9.34704 13.2753 9.46055L12.6018 10.0035C12.3759 10.1856 12.2076 10.4294 12.1174 10.7052L11.8486 11.5275C11.7924 11.6994 11.6477 11.8276 11.4703 11.8626L10.6216 12.0304Z"
      />
      <path
        fill="#5956E9"
        d="M10.3141 5.78042C10.5301 5.57913 10.537 5.23916 10.3294 5.02926C10.1303 4.82801 9.80732 4.82144 9.60024 5.01444L6.51548 7.88935L5.40923 6.81543C5.20594 6.61808 4.88259 6.61808 4.6793 6.81543C4.46728 7.02124 4.4673 7.36157 4.67933 7.56737L5.81775 8.67234C6.20008 9.04344 6.80623 9.04959 7.19602 8.68633L10.3141 5.78042Z"
      />
      <path
        fill="#5956E9"
        d="M6.9946 0.296017C7.30673 0.113197 7.69327 0.113198 8.0054 0.296017L8.7519 0.733248C8.91883 0.831024 9.11055 0.878278 9.3038 0.869281L10.168 0.829047C10.5293 0.812224 10.8716 0.991854 11.063 1.29879L11.5208 2.03285C11.6232 2.19701 11.771 2.32794 11.9463 2.40979L12.7302 2.77577C13.0579 2.9288 13.2775 3.24691 13.3044 3.60764L13.3686 4.47037C13.383 4.6633 13.453 4.84792 13.5702 5.00186L14.0942 5.69021C14.3133 5.97803 14.3599 6.36174 14.216 6.69364L13.872 7.4874C13.795 7.6649 13.7712 7.86092 13.8034 8.05168L13.9475 8.90471C14.0078 9.26139 13.8707 9.6228 13.5891 9.84982L12.9156 10.3928C12.765 10.5142 12.6528 10.6767 12.5927 10.8606L12.3239 11.6829C12.2115 12.0267 11.9221 12.283 11.5673 12.3531L10.7186 12.5209C10.5288 12.5584 10.3539 12.6502 10.2153 12.7851L9.59508 13.3882C9.33577 13.6404 8.96047 13.7329 8.61365 13.6301L7.78421 13.3842C7.59873 13.3293 7.40127 13.3293 7.21579 13.3842L6.38635 13.6301C6.03953 13.7329 5.66423 13.6404 5.40492 13.3882L4.78475 12.7851C4.64606 12.6502 4.47123 12.5584 4.28143 12.5209L3.43273 12.3531C3.07787 12.283 2.78854 12.0267 2.67614 11.6829L2.40732 10.8606C2.3472 10.6767 2.23503 10.5142 2.08441 10.3928L1.41088 9.84982C1.12926 9.6228 0.992195 9.26139 1.05245 8.90471L1.19656 8.05168C1.22878 7.86092 1.20498 7.6649 1.12804 7.4874L0.783977 6.69364C0.640114 6.36174 0.686706 5.97803 0.905816 5.69021L1.42984 5.00186C1.54702 4.84792 1.61704 4.6633 1.6314 4.47037L1.69563 3.60764C1.72249 3.2469 1.94206 2.9288 2.26983 2.77577L3.05372 2.40979C3.22902 2.32794 3.37682 2.19701 3.47919 2.03285L3.93699 1.29879C4.12841 0.991854 4.47067 0.812224 4.83201 0.829047L5.6962 0.869281C5.88945 0.878278 6.08117 0.831024 6.2481 0.733248L6.9946 0.296017Z"
      />
      <path
        fill="white"
        d="M10.3141 5.78042C10.5301 5.57913 10.537 5.23916 10.3294 5.02926C10.1303 4.82801 9.80732 4.82144 9.60024 5.01444L6.51548 7.88935L5.40923 6.81543C5.20594 6.61808 4.88259 6.61808 4.6793 6.81543C4.46728 7.02125 4.4673 7.36157 4.67933 7.56737L5.81775 8.67234C6.20008 9.04344 6.80623 9.04959 7.19602 8.68633L10.3141 5.78042Z"
      />
    </g>
    <defs>
      <clipPath id="clip0_7377_62092">
        <rect x="0.5" width="14" height="14" rx="4" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const verifiedIcon = (
  <svg
    width="15"
    height="14"
    fill="none"
    viewBox="0 0 15 14"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_7377_62092)">
      <path
        stroke="#5956E9"
        d="M10.6216 12.0304L10.7186 12.5209L10.6216 12.0304C10.3369 12.0867 10.0747 12.2243 9.86664 12.4266L9.24647 13.0298C9.11682 13.1559 8.92917 13.2022 8.75576 13.1507L7.92632 12.9049L7.78421 13.3842L7.92632 12.9049C7.64809 12.8224 7.35191 12.8224 7.07368 12.9049L7.21579 13.3842L7.07368 12.9049L6.24424 13.1507C6.07083 13.2022 5.88318 13.1559 5.75353 13.0298L5.13336 12.4266C4.92533 12.2243 4.66307 12.0867 4.37839 12.0304L4.28143 12.5209L4.37838 12.0304L3.52968 11.8626C3.35225 11.8276 3.20759 11.6994 3.15139 11.5275L2.88256 10.7052C2.79239 10.4294 2.62414 10.1856 2.39821 10.0035L1.72468 9.46055C1.58387 9.34704 1.51534 9.16633 1.54546 8.988L1.68957 8.13496C1.73791 7.84882 1.70221 7.5548 1.5868 7.28854L1.24273 6.49479C1.1708 6.32884 1.1941 6.13698 1.30365 5.99307L1.82768 5.30472C2.00345 5.07382 2.10848 4.79689 2.13003 4.50749L2.19425 3.64476C2.20768 3.46439 2.31747 3.30534 2.48135 3.22882L3.26524 2.86284C3.52819 2.74008 3.74989 2.54367 3.90345 2.29744L4.36125 1.56338C4.45696 1.40991 4.62809 1.32009 4.80876 1.32851L5.67294 1.36874C5.96282 1.38224 6.2504 1.31136 6.5008 1.16469L7.2473 0.72746C7.40337 0.63605 7.59663 0.63605 7.7527 0.72746L8.4992 1.16469C8.7496 1.31136 9.03718 1.38224 9.32706 1.36874L10.1912 1.32851C10.3719 1.32009 10.543 1.40991 10.6388 1.56338L11.0965 2.29744C11.2501 2.54368 11.4718 2.74008 11.7348 2.86284L12.5187 3.22882C12.6825 3.30534 12.7923 3.46439 12.8057 3.64476L12.87 4.50749C12.8915 4.79689 12.9965 5.07382 13.1723 5.30472L13.6963 5.99307C13.8059 6.13698 13.8292 6.32884 13.7573 6.49479L13.4132 7.28854C13.2978 7.5548 13.2621 7.84882 13.3104 8.13496L13.4545 8.988C13.4847 9.16633 13.4161 9.34704 13.2753 9.46055L12.6018 10.0035C12.3759 10.1856 12.2076 10.4294 12.1174 10.7052L11.8486 11.5275C11.7924 11.6994 11.6477 11.8276 11.4703 11.8626L10.6216 12.0304Z"
      />
      <path
        fill="#5956E9"
        d="M10.3141 5.78042C10.5301 5.57913 10.537 5.23916 10.3294 5.02926C10.1303 4.82801 9.80732 4.82144 9.60024 5.01444L6.51548 7.88935L5.40923 6.81543C5.20594 6.61808 4.88259 6.61808 4.6793 6.81543C4.46728 7.02124 4.4673 7.36157 4.67933 7.56737L5.81775 8.67234C6.20008 9.04344 6.80623 9.04959 7.19602 8.68633L10.3141 5.78042Z"
      />
      <path
        fill="#5956E9"
        d="M6.9946 0.296017C7.30673 0.113197 7.69327 0.113198 8.0054 0.296017L8.7519 0.733248C8.91883 0.831024 9.11055 0.878278 9.3038 0.869281L10.168 0.829047C10.5293 0.812224 10.8716 0.991854 11.063 1.29879L11.5208 2.03285C11.6232 2.19701 11.771 2.32794 11.9463 2.40979L12.7302 2.77577C13.0579 2.9288 13.2775 3.24691 13.3044 3.60764L13.3686 4.47037C13.383 4.6633 13.453 4.84792 13.5702 5.00186L14.0942 5.69021C14.3133 5.97803 14.3599 6.36174 14.216 6.69364L13.872 7.4874C13.795 7.6649 13.7712 7.86092 13.8034 8.05168L13.9475 8.90471C14.0078 9.26139 13.8707 9.6228 13.5891 9.84982L12.9156 10.3928C12.765 10.5142 12.6528 10.6767 12.5927 10.8606L12.3239 11.6829C12.2115 12.0267 11.9221 12.283 11.5673 12.3531L10.7186 12.5209C10.5288 12.5584 10.3539 12.6502 10.2153 12.7851L9.59508 13.3882C9.33577 13.6404 8.96047 13.7329 8.61365 13.6301L7.78421 13.3842C7.59873 13.3293 7.40127 13.3293 7.21579 13.3842L6.38635 13.6301C6.03953 13.7329 5.66423 13.6404 5.40492 13.3882L4.78475 12.7851C4.64606 12.6502 4.47123 12.5584 4.28143 12.5209L3.43273 12.3531C3.07787 12.283 2.78854 12.0267 2.67614 11.6829L2.40732 10.8606C2.3472 10.6767 2.23503 10.5142 2.08441 10.3928L1.41088 9.84982C1.12926 9.6228 0.992195 9.26139 1.05245 8.90471L1.19656 8.05168C1.22878 7.86092 1.20498 7.6649 1.12804 7.4874L0.783977 6.69364C0.640114 6.36174 0.686706 5.97803 0.905816 5.69021L1.42984 5.00186C1.54702 4.84792 1.61704 4.6633 1.6314 4.47037L1.69563 3.60764C1.72249 3.2469 1.94206 2.9288 2.26983 2.77577L3.05372 2.40979C3.22902 2.32794 3.37682 2.19701 3.47919 2.03285L3.93699 1.29879C4.12841 0.991854 4.47067 0.812224 4.83201 0.829047L5.6962 0.869281C5.88945 0.878278 6.08117 0.831024 6.2481 0.733248L6.9946 0.296017Z"
      />
      <path
        fill="white"
        d="M10.3141 5.78042C10.5301 5.57913 10.537 5.23916 10.3294 5.02926C10.1303 4.82801 9.80732 4.82144 9.60024 5.01444L6.51548 7.88935L5.40923 6.81543C5.20594 6.61808 4.88259 6.61808 4.6793 6.81543C4.46728 7.02125 4.4673 7.36157 4.67933 7.56737L5.81775 8.67234C6.20008 9.04344 6.80623 9.04959 7.19602 8.68633L10.3141 5.78042Z"
      />
    </g>
    <defs>
      <clipPath id="clip0_7377_62092">
        <rect x="0.5" width="14" height="14" rx="4" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const UnVerifiedTag = styled(Tag, {
  shouldForwardProp: (prop) => !['opacity', 'danger'].includes(prop),
})(({ opacity }: { danger?: boolean; opacity?: CSSObject['opacity'] }) => ({
  gap: 4,
  opacity,
  height: 32,
  fontSize: 14,
  marginRight: 0,
  display: 'flex',
  color: '#5956E9',
  fontWeight: 600,
  borderRadius: 99,
  lineHeight: '20px',
  cursor: 'pointer',
  alignItems: 'center',
  borderStyle: 'solid',
  borderColor: '#5956E9',
  justifyContent: 'center',
  backgroundColor: 'transparent',
}));

const VerifiedSkeleton = styled(Skeleton)(() => ({
  [`&.${prefixCls}-skeleton`]: {
    width: 68,
    [`& > .${prefixCls}-skeleton-content`]: {
      height: 32,
      [`& > .${prefixCls}-skeleton-title`]: {
        height: 32,
        marginBottom: 0,
        borderRadius: 99,
      },
    },
  },
}));

export const Unverified: React.FC<{ showSkeleton?: boolean }> = ({
  showSkeleton,
}) => {
  const router = useRouter();
  const dispatch = useDispatch();
  const params = useSearchParams();
  const handle = useSelector(selectAuthUserHandle);
  const verificationStatus = useSelector(selectVerificationStatus);

  React.useEffect(() => {
    if (
      handle &&
      params.get('rejected') === '1' &&
      verificationStatus === VerificationStatuses.VERIFY_LINK_REMOVED
    ) {
      dispatch(onToggleVerificationModal());

      router.replace(`/${handle}`);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params, dispatch, handle, verificationStatus]);

  if (showSkeleton) {
    return (
      <VerifiedSkeleton
        paragraph={false}
        active={showSkeleton}
        loading={showSkeleton}
      />
    );
  }

  switch (verificationStatus) {
    case VerificationStatuses.VERIFY_REQUEST_SENT: {
      return (
        <UnVerifiedTag opacity={0.4}>
          {unVerifiedIcon} <span>Pending</span>
        </UnVerifiedTag>
      );
    }
    case VerificationStatuses.VERIFY_LINK_REMOVED: {
      return (
        <>
          <Badge color="red" offset={[-5, 2]} status="processing" dot>
            <UnVerifiedTag
              onClick={() => dispatch(onToggleVerificationModal())}
            >
              {unVerifiedIcon} <span>Verify</span>
            </UnVerifiedTag>
          </Badge>
          <UnverifiedModal />
        </>
      );
    }
    case VerificationStatuses.VERIFY_SUCCESS: {
      return <Tooltip title="Verified Profile">{verifiedIcon}</Tooltip>;
    }
    default: {
      return (
        <>
          <Badge color="red" offset={[-5, 2]} status="processing" dot>
            <UnVerifiedTag
              onClick={() => dispatch(onToggleVerificationModal())}
            >
              {unVerifiedIcon}
              <span>Verify</span>
            </UnVerifiedTag>
          </Badge>
          <UnverifiedModal />
        </>
      );
    }
  }
};
