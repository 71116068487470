import styled from '@emotion/styled';

export const Mask = styled('div')(() => ({
  top: 0,
  left: 0,
  zIndex: 155,
  width: '100%',
  height: '100%',
  position: 'fixed',
  minWidth: '100vw',
  minHeight: '100vh',
  backgroundColor: 'transparent',
}));
