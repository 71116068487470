import styled from '@emotion/styled';
import { breakpoints } from '@shared/theme';

export const ActionsContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'hasActive',
})(({ hasActive }: { hasActive?: boolean }) => ({
  zIndex: 152,
  gap: '2.4rem',
  display: 'flex',
  position: 'fixed',
  alignItems: 'flex-end',
  insetBlockEnd: '4.8rem',
  insetInlineEnd: '2.4rem',
  justifyContent: 'center',
  [`@media (max-width: ${breakpoints.sm})`]: {
    insetInlineEnd: '1.6rem',
    justifyContent: 'space-between',
    insetBlockEnd: hasActive ? '8rem' : '1.6rem',
    insetInlineStart: hasActive ? '1.6rem' : 'unset',
    width: hasActive ? 'fit-content' : 'calc(100% - 3.2rem)',
  },
}));
