import React from 'react';
import { Box } from '@shared/ui/box';
import { CSSObject } from '@emotion/styled';
import { AccentColor } from '@widgets/mediakit/ThemeDrawer/ui/accent-color';
import { ColorPalette } from '@widgets/mediakit/ThemeDrawer/ui/color-palette';

const boxStyles: CSSObject = {
  gap: '3.2rem',
  display: 'flex',
  flexDirection: 'column',
};

export const Colors: React.FC = () => {
  return (
    <Box boxStyles={boxStyles}>
      <AccentColor />
      <ColorPalette />
    </Box>
  );
};
