'use client';

import React from 'react';
import { v4 as uuidV4 } from 'uuid';
import styled from '@emotion/styled';
import { prefixCls } from '@shared/theme';
import { Builder } from '@/lib/types/builder';
import useBreakpoint from '@/lib/hooks/useBreakpoint';
import { ModalProps } from 'antd/lib/modal/interface';
import { MoreOutlined, PlusOutlined } from '@ant-design/icons';
import { useLocalPostHog } from '@shared/hooks/useLocalPostHog';
import { PosthogEvents } from '@shared/constants/posthog-events';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { socialPlatforms, socialPlatformsIcons } from '@shared/ui/social-icons';
import {
  Form,
  Input,
  Modal,
  Button,
  Skeleton,
  Dropdown,
  Typography,
} from 'antd';
import {
  useDispatch,
  useSelector,
  onEditSocial,
  onChangeSocial,
  onRemoveSocial,
  selectSocialLinks,
} from '@shared/redux';

interface IFormValues {
  social_links: { platform: number; value: string }[];
}

const keys = [uuidV4(), uuidV4(), uuidV4()];

const modalStyles: ModalProps['styles'] = {
  body: { padding: 0 },
  content: { padding: 0 },
  header: { padding: '16px 24px' },
};

const modalStyle: ModalProps['style'] = {
  top: 20,
};

const Body = styled('div', {
  shouldForwardProp: (propName) => propName !== 'showMore',
})(({ showMore }: { showMore?: boolean }) => ({
  gap: '1.6rem',
  display: 'flex',
  padding: '1.6rem',
  overflowY: 'auto',
  flexDirection: 'column',
  maxHeight: 'calc(100vh - 280px)',
  ...(!showMore
    ? {
        height: 180,
        overflowY: 'hidden',
      }
    : {}),
}));

const Footer = styled('div')(() => ({
  display: 'flex',
  padding: '1.6rem',
  alignItems: 'center',
  justifyContent: 'center',
}));

const Container = styled('div')(() => ({
  gap: '1.3rem',
  width: '100%',
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'center',
}));

const InputGroup = styled('div')(() => ({
  gap: '.8rem',
  display: 'flex',
  alignItems: 'center',
}));

const IconText = styled(Typography.Text)(() => ({
  width: '2.4rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const ModalContent: React.FC<{
  defaultValues?: IFormValues['social_links'];
  toggleModal: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ toggleModal, defaultValues }) => {
  const dispatch = useDispatch();
  const posthog = useLocalPostHog();
  const [showMore, setShowMore] = React.useState<boolean>(false);

  const onlyValues = defaultValues?.map(({ platform }) => {
    return platform;
  });

  const { control, register, handleSubmit } = useForm<IFormValues>({
    defaultValues: {
      social_links: socialPlatforms
        .filter((sp) => !onlyValues?.includes(sp.platform))
        .map(({ platform }) => ({
          platform,
          value: '',
        })),
    },
  });

  const { fields } = useFieldArray({
    control,
    name: 'social_links',
  });

  const onSubmit = (data: IFormValues) => {
    const links = data.social_links.filter((link) => link.value);

    let types = [];

    if (defaultValues) {
      const newValues = defaultValues.concat(links);

      types = newValues.map((v) => {
        const platform = socialPlatforms.find(
          (sp) => sp.platform === v.platform,
        );
        return platform?.type;
      });

      dispatch(onChangeSocial(newValues));
    } else {
      dispatch(onChangeSocial(links));

      types = links.map((v) => {
        const platform = socialPlatforms.find(
          (sp) => sp.platform === v.platform,
        );
        return platform?.type;
      });
    }

    try {
      posthog?.capture(PosthogEvents.AddedSocials, {
        socials: types,
      });
    } catch (e) {
      console.error('Posthog error:', e);
    }

    toggleModal(false);
  };

  return (
    <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
      <Body showMore={showMore || fields.length <= 4}>
        {fields.map((field, index) => {
          const { dark, placeholder } = socialPlatformsIcons[field.platform];

          return (
            <InputGroup key={field.id}>
              <IconText>
                {dark({
                  style: { width: 24, height: 24 },
                })}
              </IconText>
              <Controller
                control={control}
                name={`social_links.${index}.value`}
                render={({ field }) => (
                  <Input
                    {...field}
                    size="large"
                    autoComplete="off"
                    placeholder={placeholder}
                  />
                )}
              />
              <input
                type="hidden"
                {...register(`social_links.${index}.platform`)}
              />
            </InputGroup>
          );
        })}
      </Body>

      {!showMore && fields.length > 4 && (
        <div
          style={{
            display: 'flex',
            marginTop: '1rem',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Button
            type="text"
            icon={<PlusOutlined />}
            style={{ color: '#5956E9' }}
            onClick={() => setShowMore(true)}
          >
            Show more
          </Button>
        </div>
      )}

      <Footer>
        <Button block size="large" type="primary" htmlType="submit">
          Add Social
        </Button>
      </Footer>
    </form>
  );
};

const EditModalContent: React.FC<{
  editableLink: Builder.CurrentMediakit.SocialLink & { index: number };
  setEditableLink: React.Dispatch<
    React.SetStateAction<
      (Builder.CurrentMediakit.SocialLink & { index: number }) | undefined
    >
  >;
}> = ({ editableLink, setEditableLink }) => {
  const dispatch = useDispatch();

  const { control, register, handleSubmit } = useForm<{
    index: number;
    value: string;
    platform: number;
  }>({
    defaultValues: {
      index: editableLink.index,
      value: editableLink.value,
      platform: editableLink.platform,
    },
  });

  const onSubmit = (data: {
    index: number;
    value: string;
    platform: number;
  }) => {
    dispatch(onEditSocial(data));

    setEditableLink(undefined);
  };

  const { dark, placeholder } = socialPlatformsIcons[editableLink.platform];

  return (
    <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
      <Body showMore style={{ paddingBottom: 0 }}>
        <Controller
          name="value"
          control={control}
          rules={{ required: 'This field is required.' }}
          render={({ field, fieldState }) => (
            <Form.Item
              style={{ width: '100%', marginBottom: 0 }}
              validateStatus={!!fieldState.error ? 'error' : undefined}
              help={
                <span style={{ paddingLeft: '3.5rem' }}>
                  {fieldState.error?.message}
                </span>
              }
            >
              <InputGroup>
                <IconText>
                  {dark({ style: { width: 24, height: 24 } })}
                </IconText>
                <Input
                  size="large"
                  autoComplete="off"
                  placeholder={placeholder}
                  {...field}
                />
              </InputGroup>
            </Form.Item>
          )}
        />
        <input type="hidden" {...register('index')} />
        <input type="hidden" {...register('platform')} />
      </Body>
      <Footer>
        <Button block size="large" type="primary" htmlType="submit">
          Save
        </Button>
      </Footer>
    </form>
  );
};

const CustomTag = styled('span')(() => ({
  width: 44,
  height: 32,
  display: 'flex',
  borderRadius: 8,
  position: 'relative',
  alignItems: 'center',
  justifyContent: 'center',
  border: '1px solid rgba(0, 0, 0, 0.15)',
}));

const TagText = styled(Typography.Text)(() => ({
  [`&.${prefixCls}-typography`]: {
    width: 18,
    height: 18,
    display: 'flex',
    alignItems: 'center',
  },
}));

const MoreOutlinedIcon = styled(MoreOutlined)(() => ({
  [`&.anticon.anticon-more`]: {
    top: 1,
    right: -1,
    fontSize: 16,
    position: 'absolute',
    color: 'rgba(0, 0, 0, 0.45)',
  },
}));

const SocialSkeleton = styled(Skeleton)(() => ({
  [`&.${prefixCls}-skeleton`]: {
    width: 44,
    height: 32,
    borderRadius: 8,
    [`& > .${prefixCls}-skeleton-content`]: {
      [`& > .${prefixCls}-skeleton-title`]: {
        width: 44,
        height: 32,
        marginBottom: 0,
      },
    },
  },
}));

const TagSkeleton: React.FC = () => {
  return keys.map((uuid) => (
    <SocialSkeleton active loading key={uuid} paragraph={false}>
      <CustomTag />
    </SocialSkeleton>
  ));
};

export const AddSocials: React.FC<{ showSkeleton?: boolean }> = ({
  showSkeleton,
}) => {
  const dispatch = useDispatch();
  const b425 = useBreakpoint(425);
  const socialLinks = useSelector(selectSocialLinks);
  const [modalIsOpen, toggleModal] = React.useState<boolean>(false);
  const [editableLink, setEditableLink] = React.useState<
    (Builder.CurrentMediakit.SocialLink & { index: number }) | undefined
  >(undefined);

  const handleModal = () => {
    toggleModal((prevState) => !prevState);
  };

  return (
    <Container>
      {showSkeleton ? (
        <TagSkeleton />
      ) : (
        socialLinks?.map((link, index) => (
          <CustomTag key={link.platform}>
            <TagText>
              {socialPlatformsIcons[link.platform].dark({
                style: { width: 18, height: 18 },
              })}
            </TagText>
            <Dropdown
              trigger={['click']}
              menu={{
                items: [
                  {
                    key: 'edit',
                    label: 'Edit',
                    onClick: () => setEditableLink({ ...link, index }),
                  },
                  {
                    danger: true,
                    key: 'delete',
                    label: 'Delete',
                    onClick: () => dispatch(onRemoveSocial(index)),
                  },
                ],
              }}
            >
              <MoreOutlinedIcon />
            </Dropdown>
          </CustomTag>
        ))
      )}

      {(socialLinks || [])?.length < 22 &&
        (showSkeleton ? (
          <Skeleton.Button style={{ width: 120 }} active={showSkeleton} />
        ) : (
          <Button
            type="dashed"
            id="addSocialsTarget"
            onClick={handleModal}
            icon={<PlusOutlined />}
            disabled={showSkeleton}
          >
            Add Social
          </Button>
        ))}
      <Modal
        closable
        footer={null}
        destroyOnClose
        open={modalIsOpen}
        style={modalStyle}
        title="Add Socials"
        okText="Add Social"
        styles={modalStyles}
        onCancel={handleModal}
        width={!b425 ? 430 : undefined}
      >
        <ModalContent defaultValues={socialLinks} toggleModal={toggleModal} />
      </Modal>
      <Modal
        closable
        footer={null}
        destroyOnClose
        style={modalStyle}
        okText="Add Social"
        title="Edit Socials"
        styles={modalStyles}
        open={!!editableLink}
        width={!b425 ? 430 : undefined}
        onCancel={() => setEditableLink(undefined)}
      >
        {!!editableLink && (
          <EditModalContent
            editableLink={editableLink}
            setEditableLink={setEditableLink}
          />
        )}
      </Modal>
    </Container>
  );
};
