import styled from '@emotion/styled';
import { profileSizes } from '@shared/constants/profileSizes';

export const ProfileCardBody = styled('div', {
  shouldForwardProp: (prop) => prop !== 'activeSize',
})(({ activeSize }: { activeSize: profileSizes }) => ({
  gap: '2.4rem',
  width: '100%',
  display: 'flex',
  padding: '2.4rem',
  background: '#fff',
  position: 'relative',
  willChange: 'padding',
  borderRadius: '2.4rem',
  flexDirection: 'column',
  transition: 'padding .2s ease',
  paddingTop: activeSize !== profileSizes.s160220 ? 96 : 156,
}));
