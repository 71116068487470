import React from 'react';
import { Box } from '@shared/ui/box';
import styled from '@emotion/styled';
import { prefixCls } from '@shared/theme';
import { Text } from '@shared/ui/typography';
import useBreakpoint from '@/lib/hooks/useBreakpoint';
import { useEnableMutation } from '@/lib/services/mediakit';
import { useLocalPostHog } from '@shared/hooks/useLocalPostHog';
import { PosthogEvents } from '@shared/constants/posthog-events';
import { CopyOutlined, ShareAltOutlined } from '@ant-design/icons';
import { Button, Form, Input, Modal, Skeleton, Typography } from 'antd';
import {
  useDispatch,
  useSelector,
  onOpenShareModal,
  onCloseShareModal,
  selectCurrentMediakit,
  selectShareModalIsOpen,
} from '@shared/redux';

const StyledText = styled(Typography.Text)(() => ({
  [`&.${prefixCls}-typography`]: {
    position: 'relative',
    [`& > .${prefixCls}-typography-copy`]: {
      top: 0,
      left: 0,
      bottom: 0,
      margin: 0,
      width: '100%',
      alignItems: 'center',
      position: 'absolute',
      display: 'flex !important',
      '& > span': {
        left: 16,
        position: 'relative',
      },
    },
  },
  '& .anticon': {
    transition: 'all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1)',
  },
  '& .anticon.anticon-copy': {
    color: '#00000073',
  },
  '& .anticon.anticon-check': {
    color: '#3e40c2',
  },
}));

const ButtonSkeleton = () => {
  const is768 = useBreakpoint(768);
  const style = {
    padding: 0,
    display: 'flex',
    alignItems: 'center',
    width: is768 ? 40 : 98,
    minWidth: is768 ? 'unset' : void 0,
  };

  return <Skeleton.Button active size="large" style={style} />;
};

export const ShareButton: React.FC<{
  slug: string;
  username: string;
  scrolled?: boolean;
  showSkeleton?: boolean;
}> = ({ slug, scrolled, showSkeleton }) => {
  const dispatch = useDispatch();
  const posthog = useLocalPostHog();
  const matches = useBreakpoint(768);
  const [enable, res] = useEnableMutation();
  const [value, setValue] = React.useState('');
  const mediakit = useSelector(selectCurrentMediakit);
  const modalIsOpen = useSelector(selectShareModalIsOpen);

  React.useEffect(() => {
    if (res.isSuccess) {
      dispatch(onOpenShareModal());
    }
  }, [res, dispatch]);

  React.useEffect(() => {
    if (typeof window === 'object' && mediakit?.profile?.handle) {
      setValue(`${window.location.origin}/${mediakit?.profile?.handle}`);
    }
  }, [mediakit?.profile?.handle]);

  const [copied, setCopied] = React.useState(false);
  const copyIdRef = React.useRef<any>(null);

  const onCopyClick = () => {
    setCopied(true);
    cleanCopyId();

    copyIdRef.current = setTimeout(() => {
      setCopied(false);
    }, 3000);
  };

  React.useEffect(() => cleanCopyId, []);

  const cleanCopyId = () => {
    if (copyIdRef.current) {
      clearTimeout(copyIdRef.current);
    }
  };

  const afterOpenChange = (open: boolean) => {
    if (open) {
      try {
        posthog?.capture(PosthogEvents.ShareClicked);
      } catch (e) {
        console.error('Posthog error:', e);
      }
    }
  };

  return (
    <>
      {showSkeleton ? (
        <ButtonSkeleton />
      ) : (
        <Button
          size="large"
          id="share-button"
          loading={res.isLoading}
          onClick={() => enable(slug)}
          type={scrolled ? 'primary' : void 0}
          style={{ borderRadius: scrolled ? 99 : void 0 }}
          icon={
            scrolled ? null : (
              <ShareAltOutlined style={{ verticalAlign: 'middle' }} />
            )
          }
        >
          {scrolled ? 'Share' : !matches && 'Share'}
        </Button>
      )}
      <Modal
        title="Share"
        open={modalIsOpen}
        afterOpenChange={afterOpenChange}
        width={matches ? undefined : 420}
        onCancel={() => dispatch(onCloseShareModal())}
        footer={
          <Box boxStyles={{ justifyContent: 'space-between' }}>
            <StyledText
              style={{ color: '#3e40c2' }}
              copyable={{
                text: value,
                onCopy: () => onCopyClick(),
                icon: <CopyOutlined style={{ color: '#000000E0' }} />,
              }}
            >
              <Button
                size="large"
                ghost={copied}
                type={!copied ? undefined : 'primary'}
                icon={<CopyOutlined style={{ color: 'white', opacity: 0 }} />}
              >
                {copied ? 'Link Copied!' : 'Copy Link'}
              </Button>
            </StyledText>

            <Button
              size="large"
              type="primary"
              onClick={() => dispatch(onCloseShareModal())}
            >
              Done
            </Button>
          </Box>
        }
      >
        <Form component="div" layout="vertical">
          <Form.Item
            label="MediaKit URL"
            extra="Anyone with the link can view."
            style={{ marginBottom: 40, marginTop: 24 }}
          >
            <Input
              readOnly
              size="large"
              value={value}
              suffix={
                <Button style={{ padding: 0 }} size="small" type="link">
                  <Text
                    copyable={{ text: value }}
                    textProps={{
                      '& .anticon.anticon-copy': {
                        color: '#00000073',
                      },
                      '& .anticon.anticon-check': {
                        color: '#3e40c2',
                      },
                    }}
                  />
                </Button>
              }
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
