import React from 'react';
import { Skeleton } from 'antd';
import styled from '@emotion/styled';
import { prefixCls } from '@shared/theme';
import { CSSObject } from '@emotion/styled';
import { Builder } from '@/lib/types/builder';
import { LocationIcon } from '@shared/ui/Icons';
import { hexToRgb } from '@/lib/utils/hexToRgb';
import { defaultEndColor } from '@shared/constants';
import { fontVariants } from '@shared/constants/fontVariants';
import { useLocalPostHog } from '@shared/hooks/useLocalPostHog';
import { PosthogEvents } from '@shared/constants/posthog-events';
import { textContrastChecker } from '@/lib/utils/textContrastChecker';
import {
  useSelector,
  useDispatch,
  selectLocation,
  selectCountries,
  onSelectLocation,
  selectMediakitTheme,
} from '@shared/redux';
import {
  LogoContainer,
  BorderedSelect,
  CountrySelectContainer,
} from './styled';

const dropdownStyle: React.CSSProperties = {
  width: 300,
};

const Div = styled('div', {
  shouldForwardProp: (propName) => propName !== 'styles',
})<{ styles?: CSSObject }>(({ styles }) => ({
  textAlign: 'left',
  [`& > .${prefixCls}-select-dropdown .${prefixCls}-select-item-option-selected:not(.${prefixCls}-select-item-option-disabled)`]:
    {
      ...styles,
    },
}));

const CountrySkeleton = styled(Skeleton)({
  [`&.${prefixCls}-skeleton`]: {
    width: 100,
    [`& > .${prefixCls}-skeleton-content`]: {
      height: 18,
      [`& > .${prefixCls}-skeleton-title`]: {
        height: 18,
        marginBottom: 0,
      },
    },
  },
});

export const CountrySelect: React.FC<{ showSkeleton?: boolean }> = ({
  showSkeleton,
}) => {
  const dispatch = useDispatch();
  const ref = React.useRef<any>();
  const posthog = useLocalPostHog();
  const selectRef = React.useRef<any>();
  const location = useSelector(selectLocation);
  const countries = useSelector(selectCountries);

  const [bordered, setBordered] = React.useState<boolean>(false);

  const theme = useSelector(selectMediakitTheme);

  const { r, g, b } = hexToRgb(theme?.primary_color || defaultEndColor);
  const buttonTextColor = textContrastChecker(r, g, b);

  let fill: string;
  if (buttonTextColor === '#ffffff') {
    fill = theme?.primary_color as string;
  } else {
    fill = '#000000';
  }

  const filterOption = (
    input: string,
    option?: { label: string; value: string },
  ) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  const onSelect = (
    _value: string,
    options: Builder.CurrentMediakit.Location,
  ) => {
    dispatch(onSelectLocation(options));

    setTimeout(() => selectRef?.current?.blur(), 300);

    if (options.label) {
      try {
        posthog?.capture(PosthogEvents.AddedCountry, {
          country: options.label,
        });
      } catch (e) {
        console.error('Posthog error:', e);
      }
    }
  };

  return (
    <>
      <CountrySelectContainer>
        <LogoContainer>
          <LocationIcon fill={fill} />
        </LogoContainer>
        <CountrySkeleton
          paragraph={false}
          active={showSkeleton}
          loading={showSkeleton}
        >
          <BorderedSelect
            showSearch
            ref={selectRef}
            autoFocus={false}
            options={countries}
            placeholder="Country"
            onSelect={onSelect as any}
            className={fontVariants.opt3}
            dropdownStyle={dropdownStyle}
            onBlur={() => setBordered(false)}
            onFocus={() => setBordered(true)}
            filterOption={filterOption as any}
            getPopupContainer={() => ref.current}
            value={location?.value ? location : null}
            variant={!bordered ? 'borderless' : undefined}
            selectSelector={{
              borderColor: `${theme?.primary_color} !important` || void 0,
            }}
          />
        </CountrySkeleton>
      </CountrySelectContainer>
      <Div
        ref={ref}
        styles={{
          color: buttonTextColor,
          backgroundColor: `${theme?.primary_color}` || void 0,
        }}
      />
    </>
  );
};
