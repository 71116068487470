import { Avatar } from 'antd';
import styled from '@emotion/styled';
import { Neutral } from '@shared/theme';

export const DropAvatar = styled(Avatar, {
  shouldForwardProp: (prop) => prop !== 'bordered',
})(({ bordered }: { bordered?: boolean }) => ({
  minWidth: '4rem',
  minHeight: '4rem',
  cursor: 'pointer',
  verticalAlign: 'middle',
  backgroundColor: Neutral.gray4,
  border: bordered ? '1px solid #fff' : void 0,
}));
