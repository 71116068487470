import React from 'react';
import { Spin } from 'antd';
import Image from 'next/image';
import { AuthorName } from './author-name';
import { useRouter } from 'next/navigation';
import { blurDataURL } from '@shared/constants';
import fallback from '@shared/icons/fallback.png';
import { Templates } from '@/lib/types/templates';
import { useFetchTemplatesMutation } from '../api';
import { PreviewModal } from '../ui/preview-modal';
import badgeIcon from '@shared/icons/pro-badge.svg';
import { Text, Title } from '@shared/ui/typography';
import useBreakpoint from '@/lib/hooks/useBreakpoint';
import { CreateFormScratch } from './create-form-scratch';
import { mkitDefaults } from '@shared/constants/mkit-defaults';
import ImageWithFallback from '@shared/ui/image-with-fallback';
import { useLocalPostHog } from '@shared/hooks/useLocalPostHog';
import { useUpgradeModal } from '@shared/hooks/useUpgradeModal';
import { PosthogEvents } from '@shared/constants/posthog-events';
import { TemplateTypes } from '@shared/constants/template-types';
import { ProBadge } from '@widgets/templates-modal/lib/pro-badge';
import viralMangoAvatar from '@shared/icons/viralMangoAvatar.svg';
import { TemplatesListContainer } from './templates-list-container';
import { useFetchCategoriesMutation } from '@/lib/services/categories';
import { CreateFormScratchButton } from '@widgets/templates-modal/lib/create-form-scratch-button';
import {
  useCreateMediakitMutation,
  useFetchMediakitsMutation,
} from '@/lib/services/mediakit';
import {
  TemplateCard,
  TemplateCardExtra,
  TemplateCardFooter,
} from '@widgets/templates-modal/lib/template-card';
import { TemplateName } from '@widgets/templates-modal/lib/template-name';

export const TemplatesList: React.FC<{
  scratchAsButton?: boolean;
  onUpdate?: (template?: Templates.Template) => Promise<void>;
}> = ({ onUpdate, scratchAsButton }) => {
  const { push } = useRouter();
  const is768 = useBreakpoint(768);
  const posthog = useLocalPostHog();

  const [loading, setLoading] = React.useState(true);

  const [selectedTemplate, setTemplate] = React.useState(0);
  const [previewModal, setPreviewModal] = React.useState(false);

  const [localTemplates, setLocalTemplates] = React.useState<
    Templates.Template[]
  >([]);

  const { handleOpen } = useUpgradeModal();
  const [create] = useCreateMediakitMutation();
  const [getList] = useFetchMediakitsMutation();
  const [fetchTemplates] = useFetchTemplatesMutation();
  const [fetchCategories] = useFetchCategoriesMutation();

  React.useEffect(() => {
    fetchCategories();
  }, [fetchCategories]);

  React.useEffect(() => {
    async function start() {
      const { data } = await fetchTemplates().unwrap();

      setLocalTemplates(data.templates);

      setLoading(false);
    }

    start().then().catch(console.error);
  }, [fetchTemplates]);

  const selectTemplate = (id?: number) => async () => {
    setLoading(true);

    if (typeof onUpdate === 'function') {
      await onUpdate();
    } else {
      const list = await getList().unwrap();

      if (!list.data?.mediakits.length) {
        let body: any = mkitDefaults;

        if (id) {
          body = { template_id: id };
        } else {
          try {
            posthog?.capture(PosthogEvents.SelectedScratch);
          } catch (e) {
            console.error('Posthog error:', e);
          }
        }

        const res = await create(body).unwrap();

        const mediakit = res.data?.mediakit;

        if (mediakit) {
          let url = `${mediakit.handle}`;

          if (!res.data.mediakit.default) {
            url = `${url}/${mediakit.slug}`;
          }

          push(url);
        }
      } else {
        handleOpen('template', {
          from: 'create_from_scratch',
        });
      }
    }

    setLoading(false);
  };

  const openPreview = (id: number) => async () => {
    setTemplate(id);

    setPreviewModal(true);
  };

  return (
    <Spin spinning={loading}>
      {scratchAsButton && (
        <CreateFormScratchButton onClick={selectTemplate(void 0)} />
      )}
      <TemplatesListContainer>
        {!scratchAsButton && (
          <CreateFormScratch onClick={selectTemplate(void 0)} />
        )}
        {localTemplates.map((template) => {
          const src = is768
            ? template.thumb_mobile?.trim()
            : template.thumb?.trim();

          return (
            <TemplateCard key={template.id} onClick={openPreview(template.id)}>
              <TemplateCardExtra>
                <ImageWithFallback
                  fill
                  quality={80}
                  sizes="100%"
                  placeholder="blur"
                  alt={template.name}
                  src={src || fallback}
                  fallbackSrc={fallback}
                  blurDataURL={blurDataURL}
                />
              </TemplateCardExtra>
              <TemplateCardFooter>
                <TemplateName level={5} ellipsis={{ tooltip: true }}>
                  {template.name}
                </TemplateName>
                <AuthorName type="secondary">
                  <Image
                    src={viralMangoAvatar}
                    width={is768 ? 12 : 14}
                    alt="Viral Mango Avatar"
                    height={is768 ? 12 : 14}
                    style={{ position: 'relative', bottom: 1 }}
                  />
                  Created by{' '}
                  <span style={{ color: 'rgba(0, 0, 0, 0.84)' }}>
                    ViralMango Studio
                  </span>
                </AuthorName>
              </TemplateCardFooter>
              {template.type === TemplateTypes.PRO && (
                <ProBadge>
                  <Image src={badgeIcon} alt="pro" />
                </ProBadge>
              )}
            </TemplateCard>
          );
        })}
      </TemplatesListContainer>
      <PreviewModal
        onUpdate={onUpdate}
        setTemplate={setTemplate}
        previewModal={previewModal}
        setPreviewModal={setPreviewModal}
        selectedTemplate={selectedTemplate}
      />
    </Spin>
  );
};
