'use client';

import React from 'react';
import Script from 'next/script';
import { useOnboardingMutation } from '@/lib/services/auth';
import { useLocalPostHog } from '@shared/hooks/useLocalPostHog';
import { PosthogEvents } from '@shared/constants/posthog-events';
import {
  useSelector,
  selectAuthUser,
  selectCurrentMediakit,
} from '@shared/redux';

const formId = 'w41BG5';

export const Tally = () => {
  const posthog = useLocalPostHog();
  const user = useSelector(selectAuthUser);
  const [onboarding] = useOnboardingMutation();
  const mkit = useSelector(selectCurrentMediakit);
  const [loaded, setLoaded] = React.useState(false);

  const getTally = () => {
    if (typeof window === 'undefined') {
      return;
    }

    return (window as any).Tally;
  };

  const onLoad = () => {
    getTally()?.loadEmbeds();

    setLoaded(true);
  };

  React.useEffect(() => {
    const onSubmit = () => {
      onboarding({ step: 'show_survey' });

      try {
        posthog?.capture(PosthogEvents.TallyCompleted, {
          email: user?.email,
          handle: user?.handle,
        });
      } catch (e) {
        console.error('Posthog error:', e);
      }
    };

    const onClose = () => {
      onboarding({ step: 'show_survey' });

      try {
        posthog?.capture(PosthogEvents.TallyClosed, {
          email: user?.email,
          handle: user?.handle,
        });
      } catch (e) {
        console.error('Posthog error:', e);
      }
    };

    if (loaded && user?.email) {
      const handle =
        typeof window !== 'undefined' ? window.location.href : user?.handle;

      getTally()?.openPopup(formId, {
        onClose,
        onSubmit,
        showOnce: true,
        layout: 'modal',
        autoClose: 3000,
        key: user?.email,
        hiddenFields: {
          handle,
          email: user?.email,
          total_followers: mkit?.profile?.total_followers || 0,
        },
      });
    }
  }, [
    loaded,
    posthog,
    onboarding,
    user?.email,
    user?.handle,
    mkit?.profile?.total_followers,
  ]);

  return (
    <Script async onLoad={onLoad} src="https://tally.so/widgets/embed.js" />
  );
};
