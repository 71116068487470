import React from 'react';
import styled from '@emotion/styled';
import { Input, Skeleton } from 'antd';
import { prefixCls } from '@shared/theme';
import { fontVariants } from '@shared/constants/fontVariants';
import { useLocalPostHog } from '@shared/hooks/useLocalPostHog';
import { PosthogEvents } from '@shared/constants/posthog-events';
import {
  selectBio,
  useDispatch,
  useSelector,
  onChangeTextarea,
} from '@shared/redux';
import { TextAreaProps } from 'antd/es/input/TextArea';

const Container = styled('div')({
  width: '100%',
  display: 'flex',
});

const BioSkeleton = styled(Skeleton)({
  [`&.${prefixCls}-skeleton`]: {
    [`& > .${prefixCls}-skeleton-content`]: {
      [`& > .${prefixCls}-skeleton-paragraph`]: {
        marginBottom: 0,
        '& > li + li': {
          marginTop: '.8rem',
        },
      },
    },
  },
});

export const BioInput: React.FC<{ showSkeleton?: boolean }> = ({
  showSkeleton,
}) => {
  const value = useSelector(selectBio);
  const dispatch = useDispatch();
  const posthog = useLocalPostHog();
  const [variant, setVariant] =
    React.useState<TextAreaProps['variant']>('borderless');

  const onFocus = () => {
    setVariant(void 0);
  };

  const onBlur = (e: React.FocusEvent<HTMLTextAreaElement>) => {
    const value = e.currentTarget.value?.trim() || '';

    dispatch(onChangeTextarea(value?.slice(0, 1500)));

    if (value) {
      try {
        posthog?.capture(PosthogEvents.AddedBio);
      } catch (e) {
        console.error('Posthog error:', e);
      }
    }

    setVariant('borderless');
  };

  return (
    <Container>
      <BioSkeleton
        title={false}
        active={showSkeleton}
        loading={showSkeleton}
        paragraph={{ rows: 4 }}
      >
        <Input.TextArea
          size="large"
          onBlur={onBlur}
          onFocus={onFocus}
          variant={variant}
          defaultValue={value}
          id="bioTextAreaTarget"
          placeholder="Your bio..."
          style={{ resize: 'none' }}
          autoSize={{ minRows: 1, maxRows: 5 }}
          className={`${fontVariants.opt3} animate__animated`}
        />
      </BioSkeleton>
    </Container>
  );
};
