import React from 'react';
import { Box } from '@shared/ui/box';

export const IsNew = () => {
  return (
    <Box
      boxStyles={{
        top: 6,
        right: 6,
        width: 34,
        height: 2,
        position: 'absolute',
      }}
    >
      <svg
        width="38"
        height="20"
        fill="none"
        viewBox="0 0 38 20"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          rx="10"
          width="38"
          height="20"
          fill="url(#paint0_linear_7577_47675)"
        />
        <path
          fill="white"
          d="M5.64258 15V5.13574H6.83887L12.1914 12.8125H12.3008V5.13574H13.5176V15H12.3213L6.96875 7.35059H6.85938V15H5.64258ZM18.8906 15.1299C16.7852 15.1299 15.5137 13.6533 15.5137 11.3428V11.3359C15.5137 9.05957 16.8125 7.50098 18.8154 7.50098C20.8184 7.50098 22.0352 8.99121 22.0352 11.1924V11.6572H16.7305C16.7646 13.1816 17.6055 14.0703 18.918 14.0703C19.916 14.0703 20.5312 13.5986 20.7295 13.1543L20.7568 13.0928H21.9463L21.9326 13.1475C21.6797 14.1455 20.627 15.1299 18.8906 15.1299ZM18.8086 8.56055C17.7148 8.56055 16.8809 9.30566 16.751 10.707H20.8252C20.7021 9.25098 19.8955 8.56055 18.8086 8.56055ZM25.3027 15L23.2383 7.63086H24.4277L25.877 13.5234H25.9863L27.6338 7.63086H28.7617L30.4092 13.5234H30.5186L31.9678 7.63086H33.1504L31.0859 15H29.8896L28.2422 9.29883H28.1328L26.4922 15H25.3027Z"
        />
        <defs>
          <linearGradient
            x1="38"
            y1="0"
            y2="5.18309"
            x2="-1.92361"
            id="paint0_linear_7577_47675"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#6EDFFB" />
            <stop offset="0.469526" stopColor="#B97EFC" />
            <stop offset="1" stopColor="#5956E9" />
          </linearGradient>
        </defs>
      </svg>
    </Box>
  );
};
