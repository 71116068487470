import React from 'react';
import { CardTitle } from './card-title';
import { OnboardingItems } from './onboarding-items';
import { getStartedStepsCount } from '@shared/constants';
import { Card, CardProps, Flex, Progress, Typography } from 'antd';
import { useSelector, selectGetStartedCompletedCount } from '@shared/redux';

const progressStyle: React.CSSProperties = { marginBottom: '0.8rem' };

const cardStyle: React.CSSProperties = {
  width: '100%',
  position: 'relative',
};

const cardStyles: CardProps['styles'] = {
  body: { maxHeight: 'calc(100vh - 200px)', overflowY: 'auto' },
};

export const Content: React.FC = () => {
  const count = useSelector(selectGetStartedCompletedCount);

  return (
    <Card
      bordered={false}
      style={cardStyle}
      styles={cardStyles}
      title={<CardTitle />}
    >
      <Flex gap={8} vertical>
        <Flex vertical style={{ marginBottom: 8 }}>
          <Flex gap={4}>
            <Typography.Text>
              Complete all steps to get a verified
            </Typography.Text>
            <svg
              width="16"
              height="16"
              fill="none"
              viewBox="0 0 16 16"
              style={{ marginTop: 3 }}
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill="#5956E9"
                d="M7.4946 0.296017C7.80673 0.113198 8.19327 0.113198 8.5054 0.296017L9.46728 0.8594C9.63422 0.957177 9.82593 1.00443 10.0192 0.995433L11.1327 0.943591C11.4941 0.926768 11.8363 1.1064 12.0277 1.41333L12.6176 2.35919C12.72 2.52335 12.8678 2.65428 13.0431 2.73613L14.0532 3.2077C14.3809 3.36073 14.6005 3.67884 14.6274 4.03957L14.7101 5.15123C14.7245 5.34416 14.7945 5.52878 14.9117 5.68271L15.5869 6.56967C15.806 6.85749 15.8526 7.24121 15.7087 7.5731L15.2654 8.59588C15.1885 8.77338 15.1647 8.9694 15.1969 9.16016L15.3826 10.2593C15.4428 10.616 15.3058 10.9774 15.0241 11.2044L14.1563 11.904C14.0057 12.0254 13.8935 12.1879 13.8334 12.3718L13.487 13.4314C13.3746 13.7752 13.0853 14.0315 12.7304 14.1017L11.6368 14.3178C11.447 14.3553 11.2722 14.4471 11.1335 14.582L10.3344 15.3592C10.0751 15.6114 9.69979 15.7039 9.35297 15.6011L8.28421 15.2843C8.09873 15.2293 7.90127 15.2293 7.71579 15.2843L6.64703 15.6011C6.30022 15.7039 5.92491 15.6114 5.6656 15.3592L4.8665 14.582C4.72781 14.4471 4.55298 14.3553 4.36318 14.3178L3.26961 14.1017C2.91475 14.0315 2.62542 13.7752 2.51302 13.4314L2.16663 12.3718C2.10651 12.1879 1.99435 12.0254 1.84373 11.904L0.975868 11.2044C0.694245 10.9774 0.557179 10.616 0.617434 10.2593L0.803118 9.16016C0.835344 8.9694 0.811543 8.77338 0.734602 8.59588L0.291269 7.5731C0.147406 7.24121 0.193997 6.85749 0.413107 6.56967L1.08832 5.68271C1.20551 5.52878 1.27553 5.34416 1.28989 5.15123L1.37265 4.03958C1.3995 3.67884 1.61908 3.36073 1.94685 3.2077L2.95691 2.73613C3.13221 2.65428 3.28001 2.52335 3.38238 2.35919L3.97227 1.41333C4.16369 1.1064 4.50595 0.926768 4.86729 0.943591L5.98081 0.995433C6.17407 1.00443 6.36578 0.957177 6.53272 0.8594L7.4946 0.296017Z"
              />
              <path
                fill="white"
                d="M11.2159 6.60585C11.4628 6.3758 11.4707 5.98726 11.2334 5.74738V5.74738C11.0059 5.51738 10.6367 5.50987 10.4001 5.73044L6.87462 9.01605L5.61034 7.78871C5.378 7.56317 5.00846 7.56317 4.77613 7.78871V7.78871C4.53383 8.02393 4.53384 8.41287 4.77616 8.64807L6.17472 10.0055C6.55705 10.3766 7.1632 10.3828 7.55298 10.0195L11.2159 6.60585Z"
              />
            </svg>
          </Flex>
          <Typography>blue check.</Typography>
        </Flex>
        <Progress
          size={['100%', 20]}
          style={progressStyle}
          strokeColor="#52c41a"
          percent={Math.ceil((count * 100) / getStartedStepsCount)}
        />
        <OnboardingItems />
      </Flex>
    </Card>
  );
};
