import styled from '@emotion/styled';
import { previewTop } from '@shared/theme';

export const PreviewHeader = styled('div')(() => ({
  top: 0,
  zIndex: 2400,
  width: '100%',
  display: 'flex',
  background: '#fff',
  position: 'sticky',
  height: previewTop,
  alignItems: 'center',
  paddingInline: '2.4rem',
  justifyContent: 'space-between',
  borderBottom: '1px solid rgba(0, 0, 0, 0.06)',
}));
