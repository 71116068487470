import styled, { CSSObject } from '@emotion/styled';

export const BodyContainer = styled('div')(
  ({ height }: { height?: CSSObject['height'] }) => ({
    height,
    flexGrow: 1,
    gap: '3.2rem',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  }),
);
