import React from 'react';

export const UploadIcon: React.FC<{
  fill?: string;
  fillOpacity?: number;
}> = ({ fill, fillOpacity }) => {
  return (
    <svg
      width="18"
      height="18"
      fill="none"
      viewBox="0 0 18 18"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_7264_45271)">
        <path
          fill={fill}
          fillOpacity={fillOpacity}
          d="M14.2467 4.79265C13.2541 -1.22202 4.74763 -1.22312 3.75485 4.79276C-1.17455 5.91381 -0.502664 13.0785 4.60581 13.194H8.15559V8.76514L7.27092 9.64415C6.47946 10.4185 5.30756 9.25413 6.0764 8.44959L8.37536 6.15626C8.60748 5.89874 8.99533 5.79931 9.322 5.94213C9.43179 5.87216 11.7865 8.34573 11.9252 8.44958C12.6923 9.25261 11.5248 10.4196 10.7307 9.64414L9.84601 8.76513V13.194H13.3959C18.5009 13.0788 19.1794 5.91532 14.2467 4.79265Z"
        />
        <path
          fill={fill}
          fillOpacity={fillOpacity}
          d="M8.1543 16.034V16.8736C8.1543 17.3413 8.53182 17.7188 8.99949 17.7188C9.4672 17.7188 9.84472 17.3413 9.84472 16.8736V16.034V13.1941H8.1543L8.1543 16.034Z"
        />
      </g>
      <defs>
        <clipPath id="clip0_7264_45271">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
