import React from 'react';
import { Flex, Typography } from 'antd';
import { RightOutlined } from '@ant-design/icons';
import styled, { CSSObject } from '@emotion/styled';
import useBreakpoint from '@/lib/hooks/useBreakpoint';
import { checked, unchecked } from './get-started-states';
import { useOnboardingMutation } from '@/lib/services/auth';
import { useLocalPostHog } from '@shared/hooks/useLocalPostHog';
import { PosthogEvents } from '@shared/constants/posthog-events';
import {
  useDispatch,
  useSelector,
  onBulkUpdate,
  selectTikTokUsername,
  selectYouTubeUsername,
  selectInstagramUsername,
  onToggleOnboardingModal,
  onToggleVerificationModal,
  selectVerificationHasSent,
  selectComponentsIsSelected,
  selectCategoriesHasSelected,
} from '@shared/redux';

const ItemCard = styled('button', {
  shouldForwardProp: (prop) => prop !== 'cursor',
})(({ cursor }: { cursor: CSSObject['cursor'] }) => ({
  cursor,
  gap: '16px',
  width: '100%',
  display: 'flex',
  padding: '1.6rem',
  borderRadius: '.8rem',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
  background: 'rgba(245, 245, 245, 0.50)',
  border: '1px solid rgba(0, 0, 0, 0.15)',
}));

const tiktok = {
  allLayouts: {
    sm: [
      {
        w: 4,
        h: 1,
        x: 0,
        y: 5,
        i: 'title_+_5',
        moved: false,
        static: false,
      },
      {
        w: 2,
        h: 2,
        x: 0,
        y: 6,
        i: 'tk_profile_+_6',
        moved: false,
        static: false,
      },
      {
        w: 1,
        h: 2,
        x: 3,
        y: 8,
        i: 'tk_avg_views_+_7',
        moved: false,
        static: false,
      },
      {
        w: 2,
        h: 2,
        x: 2,
        y: 6,
        i: 'tk_engagement_rate_+_8',
        moved: false,
        static: false,
      },
      {
        w: 2,
        h: 2,
        x: 0,
        y: 8,
        i: 'tk_avg_likes_+_9',
        moved: false,
        static: false,
      },
      {
        w: 1,
        h: 2,
        x: 2,
        y: 8,
        i: 'tk_avg_comments_+_10',
        moved: false,
        static: false,
      },
    ],
    xxs: [
      {
        w: 2,
        h: 1,
        x: 0,
        y: 7,
        i: 'title_+_5',
        moved: false,
        static: false,
      },
      {
        w: 2,
        h: 2,
        x: 0,
        y: 8,
        i: 'tk_profile_+_6',
        moved: false,
        static: false,
      },
      {
        w: 1,
        h: 2,
        x: 1,
        y: 12,
        i: 'tk_avg_views_+_7',
        moved: false,
        static: false,
      },
      {
        w: 1,
        h: 2,
        x: 0,
        y: 10,
        i: 'tk_engagement_rate_+_8',
        moved: false,
        static: false,
      },
      {
        w: 1,
        h: 2,
        x: 1,
        y: 10,
        i: 'tk_avg_likes_+_9',
        moved: false,
        static: false,
      },
      {
        w: 1,
        h: 2,
        x: 0,
        y: 12,
        i: 'tk_avg_comments_+_10',
        moved: false,
        static: false,
      },
    ],
  },
  extraData: {
    'title_+_5': {
      value: 'TikTok',
      bg_color: '#FFFFFF',
    },
  },
};

const youtube = {
  allLayouts: {
    sm: [
      {
        w: 4,
        h: 1,
        x: 0,
        y: 10,
        i: 'title_+_11',
        moved: false,
        static: false,
      },
      {
        w: 2,
        h: 4,
        x: 0,
        y: 11,
        i: 'yt_profile_+_12',
        moved: false,
        static: false,
      },
      {
        w: 1,
        h: 2,
        x: 3,
        y: 13,
        i: 'yt_avg_views_+_13',
        moved: false,
        static: false,
      },
      {
        w: 1,
        h: 2,
        x: 2,
        y: 11,
        i: 'yt_engagement_rate_+_14',
        moved: false,
        static: false,
      },
      {
        w: 1,
        h: 2,
        x: 3,
        y: 11,
        i: 'yt_avg_likes_+_15',
        moved: false,
        static: false,
      },
      {
        w: 1,
        h: 2,
        x: 2,
        y: 13,
        i: 'yt_avg_comments_+_16',
        moved: false,
        static: false,
      },
    ],
    xxs: [
      {
        w: 2,
        h: 1,
        x: 0,
        y: 14,
        i: 'title_+_11',
        moved: false,
        static: false,
      },
      {
        w: 2,
        h: 2,
        x: 0,
        y: 15,
        i: 'yt_profile_+_12',
        moved: false,
        static: false,
      },
      {
        w: 1,
        h: 2,
        x: 1,
        y: 19,
        i: 'yt_avg_views_+_13',
        moved: false,
        static: false,
      },
      {
        w: 1,
        h: 2,
        x: 0,
        y: 17,
        i: 'yt_engagement_rate_+_14',
        moved: false,
        static: false,
      },
      {
        w: 1,
        h: 2,
        x: 1,
        y: 17,
        i: 'yt_avg_likes_+_15',
        moved: false,
        static: false,
      },
      {
        w: 1,
        h: 2,
        x: 0,
        y: 19,
        i: 'yt_avg_comments_+_16',
        moved: false,
        static: false,
      },
    ],
  },
  extraData: {
    'title_+_11': {
      value: 'YouTube',
      bg_color: '#FFFFFF',
    },
  },
};

const instagram = {
  allLayouts: {
    sm: [
      {
        w: 4,
        h: 1,
        x: 0,
        y: 0,
        i: 'title_+_0',
        moved: false,
        static: false,
      },
      {
        w: 2,
        h: 4,
        x: 0,
        y: 1,
        i: 'ig_profile_+_1',
        moved: false,
        static: false,
      },
      {
        w: 2,
        h: 2,
        x: 2,
        y: 1,
        i: 'ig_engagement_rate_+_2',
        moved: false,
        static: false,
      },
      {
        w: 1,
        h: 2,
        x: 2,
        y: 3,
        i: 'ig_avg_likes_+_3',
        moved: false,
        static: false,
      },
      {
        w: 1,
        h: 2,
        x: 3,
        y: 3,
        i: 'ig_avg_comments_+_4',
        moved: false,
        static: false,
      },
    ],
    xxs: [
      {
        w: 2,
        h: 1,
        x: 0,
        y: 0,
        i: 'title_+_0',
        moved: false,
        static: false,
      },
      {
        w: 2,
        h: 2,
        x: 0,
        y: 1,
        i: 'ig_profile_+_1',
        moved: false,
        static: false,
      },
      {
        w: 2,
        h: 2,
        x: 0,
        y: 3,
        i: 'ig_engagement_rate_+_2',
        moved: false,
        static: false,
      },
      {
        w: 1,
        h: 2,
        x: 0,
        y: 5,
        i: 'ig_avg_likes_+_3',
        moved: false,
        static: false,
      },
      {
        w: 1,
        h: 2,
        x: 1,
        y: 5,
        i: 'ig_avg_comments_+_4',
        moved: false,
        static: false,
      },
    ],
  },
  extraData: {
    'title_+_0': {
      value: 'Instagram',
      bg_color: '#FFFFFF',
    },
  },
};

export const OnboardingItems = () => {
  const dispatch = useDispatch();
  const posthog = useLocalPostHog();
  const match991 = useBreakpoint(991);
  const [onboarding] = useOnboardingMutation();
  const verificationHasSent = useSelector(selectVerificationHasSent);
  const componentsIsSelected = useSelector(selectComponentsIsSelected);
  const categoriesHasSelected = useSelector(selectCategoriesHasSelected);

  const hasInstagram = !!useSelector(selectInstagramUsername);
  const hasTikTok = !!useSelector(selectTikTokUsername);
  const hasYouTube = !!useSelector(selectYouTubeUsername);

  const addComponentsOnClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    try {
      e.currentTarget.disabled = true;

      let elems: any = instagram;

      if (hasInstagram) {
        elems = instagram;
      } else if (hasTikTok) {
        elems = tiktok;
      } else if (hasYouTube) {
        elems = youtube;
      }

      dispatch(onBulkUpdate(elems));

      onboarding({ step: 'basics.profile' });

      dispatch(onToggleOnboardingModal(false));

      e.currentTarget.disabled = false;
    } catch (error) {
      console.error(error);

      e.currentTarget.disabled = false;
    }
  };

  const verificationOnClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.currentTarget.disabled = true;

    dispatch(onToggleOnboardingModal(false));

    dispatch(onToggleVerificationModal());

    try {
      posthog?.capture(PosthogEvents.VerifyClicked);
    } catch (e) {
      console.error('Posthog error:', e);
    }

    e.currentTarget.disabled = false;
  };

  const addCategoryOnClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.currentTarget.disabled = true;

    const nameInputContainer = document.getElementById('nameInputContainer');

    let categoriesSelectTarget = document.getElementById(
      'categoriesSelectTarget',
    );

    if (categoriesSelectTarget) {
      categoriesSelectTarget.classList.remove('animate__shakeY');

      if (match991) {
        dispatch(onToggleOnboardingModal(false));
      }

      setTimeout(
        (target, nameInput) => {
          target.classList.add('animate__shakeY');

          if (match991) {
            nameInput?.scrollIntoView({ behavior: 'smooth' });
          }
        },
        200,
        categoriesSelectTarget,
        nameInputContainer,
      );
    }

    e.currentTarget.disabled = false;
  };

  const onboardingList: {
    title: string;
    completed: boolean;
    onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  }[] = [
    {
      completed: true,
      title: 'Connect social profiles',
    },
    {
      title: 'Add Category',
      onClick: addCategoryOnClick,
      completed: categoriesHasSelected,
    },
    {
      title: 'Add Components',
      onClick: addComponentsOnClick,
      completed: componentsIsSelected,
    },
    {
      title: 'Get Verified',
      onClick: verificationOnClick,
      completed: verificationHasSent,
    },
  ];

  return onboardingList.map((item, index) => {
    let cursor = 'pointer';

    if (item.completed) {
      cursor = 'default';
    }

    return (
      <ItemCard
        key={index}
        cursor={cursor}
        onClick={!item.completed ? item.onClick : void 0}
      >
        <Flex gap={4} vertical style={{ width: '100%' }}>
          <Flex gap={16} align="center">
            {item.completed ? checked : unchecked}
            <Typography.Text strong>
              {item.completed ? <del>{item.title}</del> : item.title}
            </Typography.Text>
          </Flex>
        </Flex>
        {!item.completed && (
          <Typography.Text type="secondary">
            <RightOutlined />
          </Typography.Text>
        )}
      </ItemCard>
    );
  });
};
