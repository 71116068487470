import { Select } from 'antd';
import styled from '@emotion/styled';
import { prefixCls } from '@shared/theme';
import { CSSObject } from '@emotion/styled';

export const LogoContainer = styled('div')(() => ({
  display: 'flex',
  position: 'relative',
}));

export const BorderedSelect = styled(Select, {
  shouldForwardProp: (propName) => propName !== 'selectSelector',
})<{
  selectSelector?: CSSObject;
}>(({ selectSelector }) => ({
  [`&.${prefixCls}-select`]: {
    width: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  [`&.${prefixCls}-select:not(.${prefixCls}-select-open,.${prefixCls}-select-focused)`]:
    {
      [`.${prefixCls}-select-selector`]: {
        paddingLeft: 0,
        textAlign: 'left',
      },
    },
  [`&.${prefixCls}-select.${prefixCls}-select-open.${prefixCls}-select-focused`]:
    {
      [`.${prefixCls}-select-selector`]: {
        ...selectSelector,
      },
    },
  [`&.${prefixCls}-select.${prefixCls}-select-focused .${prefixCls}-select-selector`]:
    {
      ...selectSelector,
    },
}));

export const CountrySelectContainer = styled('div')(() => ({
  gap: '.8rem',
  display: 'flex',
  alignItems: 'center',
}));
