'use client';

import React from 'react';
import { Box } from '@shared/ui/box';
import { Handle } from './components/Handle';
import { Backdrop } from './components/Backdrop';
import { BioInput } from './components/BioInput';
import { PoweredBy } from '@shared/ui/powered-by';
import { NameInput } from './components/NameInput';
import { AddSocials } from './components/AddSocials';
import { QueryStatus } from '@reduxjs/toolkit/query';
import { ProfileCard } from './components/ProfileCard';
import { ActionButton } from './components/ActionButton';
import { CountrySelect } from './components/CountrySelect';
import { ProfileAvatar } from './components/ProfileAvatar';
import { ProfileCardBody } from './components/ProfileCardBody';
import { ProfileCardExtra } from './components/ProfileCardExtra';
import { CategoriesSelect } from './components/CategoriesSelect';
import { MediakitHandle } from '@widgets/mediakit/MediakitHandle';
import {
  useSelector,
  selectAvatar,
  selectProfile,
  selectBuilderPreviewMode,
  selectCreatingQueryStatus,
} from '@shared/redux';
import {
  profileSizes,
  profileAvatarStyles,
} from '@shared/constants/profileSizes';

export const Profile: React.FC<{
  slug: string;
  username: string;
  showSkeleton: boolean;
}> = ({ slug, username, showSkeleton }) => {
  const avatar = useSelector(selectAvatar);
  const profile = useSelector(selectProfile);
  const previewMode = useSelector(selectBuilderPreviewMode);
  const creatingQueryStatus = useSelector(selectCreatingQueryStatus);

  const loading = creatingQueryStatus !== QueryStatus.fulfilled;

  const hasAvatar = !avatar?.includes('ui-avatars');
  const avatarStyles = profileAvatarStyles[profile.avatar_shape!] || {};

  const { width, height } = avatarStyles;
  const activeSize = `${width}${height}` as profileSizes;

  return (
    <ProfileCard className={previewMode}>
      <MediakitHandle slug={slug} username={username} />
      <ProfileCardBody
        activeSize={hasAvatar ? activeSize : profileSizes.s160160}
      >
        <NameInput loading={loading} showSkeleton={showSkeleton} />
        <ProfileCardExtra>
          <Handle showSkeleton={loading} />
          <CountrySelect showSkeleton={loading} />
        </ProfileCardExtra>
        <AddSocials showSkeleton={loading} />
        <CategoriesSelect showSkeleton={loading} />
        <BioInput showSkeleton={loading} />
        <ActionButton showSkeleton={loading} />
        <Box>
          <PoweredBy />
        </Box>
      </ProfileCardBody>
      <ProfileAvatar
        slug={slug}
        username={username}
        styles={avatarStyles}
        showSkeleton={showSkeleton}
      />
      <Backdrop />
    </ProfileCard>
  );
};
