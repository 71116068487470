import React from 'react';
import { CircleAction } from './circle-action';
import { ReplaceAction } from './replace-action';
import { ActionsContainer } from './actions-container';
import { profileAvatarIndex } from '@shared/constants';
import { OvalVerticalAction } from './oval-vertical-action';
import { OvalHorizontalAction } from './oval-horizontal-action';
import {
  useDispatch,
  useSelector,
  selectActiveItem,
  onChangeProfileAvatarShape,
} from '@shared/redux';
import {
  profileSizes,
  profileAvatarShapes,
} from '@shared/constants/profileSizes';
import type { ProfileActionsProps } from '../types';
import { ActionsDivider } from '@widgets/mediakit/MediaKit/Common/Actions/ui/actions-divider';

export const ProfileActions: React.FC<ProfileActionsProps> = ({
  slug,
  username,
  activeSize,
}) => {
  const dispatch = useDispatch();
  const activeItem = useSelector(selectActiveItem);

  const setSizes = (shape: profileAvatarShapes) => () => {
    dispatch(onChangeProfileAvatarShape(shape));
  };

  return (
    <ActionsContainer
      className="actions-container"
      isActive={activeItem === profileAvatarIndex}
    >
      <CircleAction
        isActive={activeSize === profileSizes.s160160}
        setSizes={setSizes(profileAvatarShapes.circle)}
      />
      <OvalHorizontalAction
        isActive={activeSize === profileSizes.s220160}
        setSizes={setSizes(profileAvatarShapes.oval_horizontal)}
      />
      <OvalVerticalAction
        isActive={activeSize === profileSizes.s160220}
        setSizes={setSizes(profileAvatarShapes.oval_vertical)}
      />
      <ActionsDivider type="vertical" />
      <ReplaceAction slug={slug} username={username} widthHelper={true} />
    </ActionsContainer>
  );
};
