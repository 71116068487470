import React from 'react';

export const Branded: React.FC<{
  fill?: string;
  fillOpacity?: number;
}> = ({ fill, fillOpacity }) => {
  return (
    <svg
      width="17"
      height="16"
      fill="none"
      viewBox="0 0 17 16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill={fill}
        fillOpacity={fillOpacity}
        d="M12.0611 8.19572C12.0611 10.104 10.4561 11.6697 8.5 11.6697C6.54389 11.6697 4.93887 10.104 4.93887 8.19572V7.80428C4.93887 5.89602 6.54389 4.33028 8.5 4.33028H12.0611V8.19572ZM12.0611 0H8.5C4.08621 0 0.5 3.49847 0.5 7.80428V8.19572C0.5 12.5015 4.08621 16 8.5 16C12.9138 16 16.5 12.5015 16.5 8.19572V4.33028C16.5 1.95719 14.5188 0 12.0611 0Z"
      />
    </svg>
  );
};
