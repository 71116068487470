import { Avatar } from 'antd';
import styled from '@emotion/styled';
import { Neutral } from '@shared/theme';

export const avatarSizeMD = {
  xs: 40,
  sm: 40,
  md: 40,
  lg: 40,
  xl: 40,
  xxl: 40,
};

export const ProfileMenuAvatar = styled(Avatar)(() => ({
  cursor: 'pointer',
  minWidth: '4rem',
  minHeight: '4rem',
  verticalAlign: 'middle',
  border: '1px solid #FFF',
  backgroundColor: Neutral.gray4,
}));
