import React from 'react';
import { Text } from '@shared/ui/typography';
import styled, { CSSObject } from '@emotion/styled';
import { breakpoints, prefixCls } from '@shared/theme';
import { selectDrawerTheme, useSelector } from '@shared/redux';

export const ButtonIcon = () => {
  const drawerTheme = useSelector(selectDrawerTheme);
  const color = drawerTheme === 'dark' ? '#fff' : void 0;

  return (
    <Text
      strong
      type="secondary"
      style={{ color, display: 'inline-flex', marginBottom: 2 }}
    >
      <svg
        width="24"
        fill="none"
        height="24"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.5069 4.23351C15.3489 4.08343 15.1392 3.99976 14.9213 3.99976C14.7034 3.99976 14.4937 4.08343 14.3357 4.23351L7.48534 10.8447C7.332 10.9904 7.2099 11.1658 7.12646 11.3602C7.04303 11.5546 7 11.7639 7 11.9755C7 12.187 7.04303 12.3963 7.12646 12.5907C7.2099 12.7851 7.332 12.9605 7.48534 13.1063L14.3853 19.7662C14.7053 20.0742 15.2237 20.0782 15.5485 19.7742C15.6271 19.7018 15.69 19.614 15.7333 19.5162C15.7766 19.4185 15.7993 19.3129 15.8001 19.206C15.8008 19.0991 15.7796 18.9932 15.7377 18.8948C15.6958 18.7965 15.6341 18.7078 15.5565 18.6342L9.24213 12.5407C9.1654 12.4678 9.10429 12.3801 9.06254 12.2828C9.02078 12.1856 8.99925 12.0809 8.99925 11.9751C8.99925 11.8693 9.02078 11.7645 9.06254 11.6673C9.10429 11.5701 9.1654 11.4823 9.24213 11.4095L15.5061 5.3639C15.5828 5.2911 15.6439 5.20345 15.6856 5.10628C15.7274 5.00911 15.7489 4.90446 15.7489 4.79871C15.7489 4.69295 15.7274 4.5883 15.6856 4.49113C15.6439 4.39396 15.5836 4.30631 15.5069 4.23351Z"
          fill="white"
        />
      </svg>
    </Text>
  );
};

export const HeadTitle = styled(Text, {
  shouldForwardProp: (propName) =>
    !['drawerTheme', 'cursor'].includes(propName),
})(
  ({
    cursor,
    drawerTheme,
  }: {
    drawerTheme?: 'dark' | 'light';
    cursor?: React.CSSProperties['cursor'];
  }) => ({
    fontSize: '1.6rem',
    display: 'inline-flex',
    cursor: cursor || 'default',
    [`&.${prefixCls}-typography`]: {
      color: drawerTheme === 'dark' ? '#fff' : void 0,
    },
    [`&.${prefixCls}-typography strong`]: {
      alignItems: 'center',
      display: 'inline-flex',
      justifyContent: 'center',
    },
  }),
);

export const SeeAllContainer = styled('div')(() => ({
  gap: '1.6rem',
  width: '100%',
  display: 'grid',
  justifyContent: 'center',
  gridTemplateColumns: 'repeat(4, 65px)',
}));

export const DemoComponentsHead = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

export const DemoComponentsContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'overflow',
})(({ overflow }: { overflow?: CSSObject['overflow'] }) => ({
  gap: '1.6rem',
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  overflow: overflow || 'hidden',
}));

export const FontComponentsContainer = styled('div')(() => ({
  gap: '1.6rem',
  width: '100%',
  height: '100%',
  display: 'grid',
  alignItems: 'center',
  justifyContent: 'center',
  gridTemplateColumns: 'repeat(2, 170px)',
  [`@media (max-width: ${breakpoints.md})`]: {
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
}));

export const BasicComponentsContainer = styled('div')(() => ({
  gap: '1.6rem',
  width: '100%',
  display: 'grid',
  alignItems: 'center',
  justifyContent: 'center',
  gridTemplateColumns: 'repeat(3, 104px)',
  [`@media (max-width: ${breakpoints.lg})`]: {
    gridTemplateColumns: 'repeat(4, 104px)',
  },
  [`@media (max-width: ${breakpoints.sm})`]: {
    gridTemplateColumns: 'repeat(3, 104px)',
  },
}));

export const ThemeComponentsContainer = styled('div')(() => ({
  gap: '1.6rem',
  width: '100%',
  height: '100%',
  display: 'grid',
  alignItems: 'center',
  justifyContent: 'center',
  gridTemplateColumns: 'repeat(2, 170px)',
  '& > div': {
    height: 170,
    width: '100%',
  },
}));

export const SocialComponentsContainer = styled('div')(() => ({
  gap: '1.6rem',
  width: '100%',
  display: 'grid',
  alignItems: 'center',
  justifyContent: 'center',
  gridTemplateColumns: 'repeat(2, 170px)',
  [`@media (max-width: ${breakpoints.lg})`]: {
    // gridTemplateColumns: 'repeat(4, 160px)',
  },
  [`@media (max-width: ${breakpoints.md})`]: {
    // gridTemplateColumns: 'repeat(3, 160px)',
  },
  [`@media (max-width: ${breakpoints.sm})`]: {
    // gridTemplateColumns: 'repeat(2, 160px)',
  },
}));
