import React from 'react';
import { Box } from '@shared/ui/box';
import { CSSObject } from '@emotion/styled';
import {
  useDispatch,
  useSelector,
  selectActiveItem,
  onChangeActiveItem,
} from '@shared/redux';

export const backdropStyles: CSSObject = {
  top: 0,
  left: 0,
  zIndex: 90,
  width: '100%',
  height: '100%',
  position: 'fixed',
  minWidth: '100vw',
  minHeight: '100vh',
};

export function Backdrop() {
  const dispatch = useDispatch();
  const activeItem = useSelector(selectActiveItem);

  const onClick = () => {
    if (activeItem) {
      dispatch(onChangeActiveItem(undefined));
    }
  };

  return activeItem === undefined ? null : (
    <Box onClick={onClick} boxStyles={backdropStyles} />
  );
}
