import React from 'react';
import Image from 'next/image';
import { v4 as uuidV4 } from 'uuid';
import { Box } from '@shared/ui/box';
import { Title } from '@shared/ui/typography';
import ttIcon from '@shared/icons/tiktok.svg';
import { Builder } from '@/lib/types/builder';
import { blurDataURL } from '@shared/constants';
import fallback from '@shared/icons/fallback.png';
import { onAddItem, useDispatch } from '@shared/redux';
import ImageWithFallback from '@shared/ui/image-with-fallback';
import { largeSize } from '@shared/constants/builderCardSizes';
import { abbreviateNumber } from '@/lib/utils/abbreviateNumber';
import { ComponentNames } from '@shared/constants/componentNames';
import { SocialProfileTarget } from '@widgets/mkit-tour/ui/social-profile-target';
import { CardHelper } from '@widgets/mediakit/ComponentsDrawer/components/CardHelper';
import {
  postStyles,
  ProfileCard,
  imageStyles,
  PostsContainer,
  HandleContainer,
  ProfileCardBody,
  ProfileCardHead,
  ProfileCardHeadExtra,
} from './styles';

const keys = [
  uuidV4(),
  uuidV4(),
  uuidV4(),
  uuidV4(),
  uuidV4(),
  uuidV4(),
  uuidV4(),
  uuidV4(),
  uuidV4(),
  uuidV4(),
];

export function TikTokProfileDemo({ data, size }: Builder.DemoComponentProps) {
  const posts = data?.posts;
  const dispatch = useDispatch();
  const hasPosts = !!posts?.length;

  const addSocial = () => {
    dispatch(
      onAddItem({
        w: largeSize.w,
        h: largeSize.h,
        i: ComponentNames.tiktok_profile,
      }),
    );
  };

  return (
    <ProfileCard>
      <SocialProfileTarget />
      <ProfileCardBody
        size={size}
        onClick={addSocial}
        data-w={largeSize.w}
        data-h={largeSize.h}
        data-type={data.name}
      >
        <ProfileCardHead>
          <ProfileCardHeadExtra>
            <Image width={17} height={17} src={ttIcon} alt="TikTok" />
            <HandleContainer>
              <Title ellipsis level={5}>
                @{data.handle}
              </Title>
            </HandleContainer>
          </ProfileCardHeadExtra>
          <Title ellipsis level={5}>
            {abbreviateNumber(data.followers)}
          </Title>
        </ProfileCardHead>
        {hasPosts ? (
          <PostsContainer>
            {posts.map((post, index) => (
              <Box key={keys[index]} boxStyles={postStyles(size)[index]}>
                <ImageWithFallback
                  fill
                  sizes="100% 100%"
                  alt="TikTok post"
                  placeholder="blur"
                  style={imageStyles}
                  fallbackSrc={fallback}
                  blurDataURL={blurDataURL}
                  src={post.image?.trim() || fallback}
                />
              </Box>
            ))}
          </PostsContainer>
        ) : null}
      </ProfileCardBody>
      <CardHelper size={size}>Profile</CardHelper>
    </ProfileCard>
  );
}
