import React from 'react';
import { Modal } from 'antd';
import { ModalProps } from 'antd/lib/modal/interface';
import { useDispatch, onUpdateAuthUser } from '@shared/redux';
import { TemplatesList } from '@widgets/templates-modal/lib/templates-list';
import { Templates } from '@/lib/types/templates';

const modalStyles: ModalProps['styles'] = {
  body: { overflowY: 'auto', maxHeight: 'calc(100vh - 200px)' },
};

const modalCssStyles: React.CSSProperties = {
  top: 60,
  maxWidth: 1024,
};

export const TemplatesModal: React.FC<{
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  onUpdate?: (template?: Templates.Template) => Promise<void>;
}> = ({ visible, onUpdate, setVisible }) => {
  const dispatch = useDispatch();

  const onCancel = () => {
    setVisible(false);
  };

  const afterOpenChange = (open: boolean) => {
    if (!open) {
      dispatch(onUpdateAuthUser({ showTemplates: false }));
    }
  };

  return (
    <Modal
      closable
      footer={null}
      open={visible}
      onCancel={onCancel}
      styles={modalStyles}
      style={modalCssStyles}
      width="calc(100% - 32px)"
      afterOpenChange={afterOpenChange}
      title="Choose Your Media Kit Template"
    >
      <TemplatesList scratchAsButton onUpdate={onUpdate} />
    </Modal>
  );
};
