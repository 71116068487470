import React from 'react';
import NextImage from 'next/image';
import { Box } from '@shared/ui/box';
import bg from '@shared/icons/defaultBackground.png';
import { gradientStyles, imageContainerStyles } from './styles';
import { defaultEndColor, defaultStartColor } from '@shared/constants';
import { previewHeightDiff, previewTop, previewTopDiff } from '@shared/theme';
import {
  useSelector,
  selectPreviewMode,
  selectMediakitTheme,
  selectBuilderPreviewMode,
} from '@shared/redux';

const BgImage = () => {
  const theme = useSelector(selectMediakitTheme);
  const [opacity, setOpacity] = React.useState(0);
  const [quality, setQuality] = React.useState(10);
  const [transition, setTransition] = React.useState<string | undefined>();

  React.useEffect(() => {
    setOpacity(0);
    setQuality(10);
    setTransition('opacity .4s ease-in-out');
  }, [theme?.bg_image]);

  return (
    <NextImage
      fill={true}
      quality={quality}
      alt="Page Background"
      src={theme?.bg_image || bg}
      onLoad={() => {
        setOpacity(1);
        setQuality(100);
        setTransition(undefined);
      }}
      style={{
        opacity,
        transition,
        objectFit: 'cover',
        objectPosition: 'center',
      }}
    />
  );
};

export const Background: React.FC<{ showSkeleton?: boolean }> = ({
  showSkeleton,
}) => {
  const theme = useSelector(selectMediakitTheme);
  const previewMode = useSelector(selectBuilderPreviewMode);

  const paletteColors = theme?.shuffled_palette?.split(',');

  let endColor = defaultEndColor;
  let startColor = defaultStartColor;

  if (Array.isArray(paletteColors)) {
    endColor = paletteColors?.[0];
  }

  if (theme?.primary_color) {
    startColor = theme.primary_color;
  }
  const isMobile = previewMode === 'mobile';
  const previewStyles = isMobile
    ? {
        left: 0,
        right: 0,
        width: 370,
        minWidth: 370,
        top: previewTop,
        borderRadius: 12,
        overflow: 'hidden',
        marginInline: 'auto',
        height: `calc(100% - ${previewHeightDiff}px)`,
        minHeight: `calc(100vh - ${previewHeightDiff}px)`,
        [`@media (max-width: 425px)`]: {
          left: 0,
          width: '100%',
          minWidth: '100%',
        },
      }
    : {};

  return (
    <>
      {!showSkeleton && (
        <Box boxStyles={{ ...imageContainerStyles, ...previewStyles }}>
          <BgImage />
        </Box>
      )}
      <Box
        boxStyles={{
          ...gradientStyles(
            `linear-gradient(113deg, ${endColor} 0%, ${startColor} 50%)`,
          ),
          ...previewStyles,
        }}
      />
    </>
  );
};

export const ServerBackground: React.FC<{
  defaultTheme?: any;
  isPreview?: boolean;
}> = ({ defaultTheme, isPreview }) => {
  const theme = defaultTheme;
  const previewMode = useSelector(selectPreviewMode);
  const paletteColors = theme?.shuffled_palette?.split(',');

  const isMobile = previewMode === 'mobile';

  const [quality, setQuality] = React.useState(10);

  React.useEffect(() => {
    setQuality(10);
  }, [theme?.bg_image]);

  let endColor = defaultEndColor;

  if (Array.isArray(paletteColors)) {
    endColor = paletteColors?.[0];
  }

  let startColor = theme?.primary_color;

  const previewStyles = isPreview
    ? {
        borderRadius: 12,
        overflow: 'hidden',
        top: previewTopDiff,
        height: 'calc(100% - 144px)',
        left: isMobile ? 'unset' : 52,
        minHeight: 'calc(100vh - 144px)',
        marginInline: isMobile ? 'auto' : '0',
        width: isMobile ? 370 : 'calc(100% - 104px)',
        minWidth: isMobile ? 370 : 'calc(100vw - 104px)',
        [`@media (max-width: 425px)`]: {
          left: 0,
          width: '100%',
          minWidth: '100%',
        },
      }
    : {};

  return (
    <>
      <Box
        boxStyles={{
          ...imageContainerStyles,
          ...previewStyles,
        }}
      >
        <NextImage
          fill={true}
          quality={quality}
          alt="Page Background"
          src={theme?.bg_image || bg}
          onLoad={() => setQuality(100)}
          style={{ objectFit: 'cover', objectPosition: 'center' }}
        />
      </Box>
      <Box
        boxStyles={{
          ...gradientStyles(
            `linear-gradient(113deg, ${endColor} 0%, ${startColor} 50%)`,
          ),
          ...previewStyles,
        }}
      />
    </>
  );
};
