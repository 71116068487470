import styled from '@emotion/styled';
import { Text } from '@shared/ui/typography';
import { breakpoints } from '@shared/theme';

export const AuthorName = styled(Text)(() => ({
  gap: '.4rem',
  display: 'flex',
  whiteSpace: 'nowrap',
  alignItems: 'center',
  [`@media (max-width: ${breakpoints.md})`]: {
    fontSize: 10,
  },
}));
