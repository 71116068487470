import React from 'react';
import Image from 'next/image';
import { Typography } from 'antd';
import { Box } from '@shared/ui/box';
import { Builder } from '@/lib/types/builder';
import { DemoCardSize } from '@shared/constants';
import { DemoChartCard } from '@shared/ui/demo-card';
import { getTikTokAccountDetails } from '@widgets/mediakit/helpers';
import {
  builderCardSizes,
  largeSize,
} from '@shared/constants/builderCardSizes';
import { selectIsFreePlan, useSelector } from '@shared/redux';

const dummyData = [
  {
    code: 'US',
    name: 'United States',
    weight: 0.077165,
    followers: 2817,
  },
  {
    code: 'DZ',
    name: 'Algeria',
    weight: 0.054519,
    followers: 1990,
  },
  {
    code: 'DE',
    name: 'Germany',
    weight: 0.046341,
    followers: 1691,
  },
  {
    code: 'FR',
    name: 'France',
    weight: 0.042147,
    followers: 1538,
  },
  {
    code: 'ZA',
    name: 'South Africa',
    weight: 0.034179,
    followers: 1248,
  },
];

export function TikTokTopCountriesDemo({
  data,
  size,
  onAddItem,
}: Builder.DemoComponentProps) {
  const isFree = useSelector(selectIsFreePlan);
  const detailsFunc = getTikTokAccountDetails()[data.name];

  const { title } = detailsFunc({
    size: builderCardSizes.s12,
  });

  let localData = ([...(data?.data || [])] || [])
    .sort((a: any, b: any) => {
      return b.weight - a.weight;
    })
    .slice(0, 5);

  if (isFree) {
    localData = [...dummyData];
  }

  if (size !== DemoCardSize.large) {
    localData = localData.slice(0, 4);
  }

  return (
    <DemoChartCard
      size={size}
      title={title}
      w={largeSize.w}
      h={largeSize.h}
      type={data.name}
      onClick={onAddItem}
    >
      <Box
        boxStyles={{
          gap: '1.6rem',
          padding: '22px 16px',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        {localData.map((item: any, index: number) => {
          const percent = Number((item.weight * 100).toFixed(2));

          return (
            <Box
              key={index}
              boxStyles={{
                width: '100%',
                maxWidth: '100%',
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}
            >
              <Box
                boxStyles={{
                  gap: '0.8rem',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                }}
              >
                <Image
                  alt={''}
                  width={16}
                  height={12}
                  src={`/countries/country-${item.code?.toLowerCase()}.svg`}
                />
                <Typography.Text
                  style={{
                    fontSize: 6,
                    lineHeight: '10px',
                  }}
                >
                  {item.name}
                </Typography.Text>
              </Box>

              <Typography.Text
                style={{
                  fontSize: 6,
                  lineHeight: '8px',
                  color: '#00000073',
                  whiteSpace: 'nowrap',
                }}
              >
                {percent}%
              </Typography.Text>
            </Box>
          );
        })}
      </Box>
    </DemoChartCard>
  );
}
