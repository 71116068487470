import React from 'react';
import debounce from 'lodash.debounce';
import { Builder } from '@/lib/types/builder';
import { dynamicCards } from '@shared/constants';
import { ComponentNames } from '@shared/constants/componentNames';
import { useEditMediakitMutation } from '@/lib/services/mediakit';
import { LayoutBreakpoints } from '@shared/constants/layoutBreakpoints';
import { InternetConnectionStatus } from '@shared/constants/internetConnectionStatus';
import {
  useSelector,
  selectExtraData,
  selectCurrentMediakit,
  selectGridLayoutBreakpoint,
  selectInternetConnectionStatus,
} from '@shared/redux';

const oppositeDevices: Record<string, string> = {
  mobile: 'desktop',
  desktop: 'mobile',
};

const oppositeBreakpoints: Record<LayoutBreakpoints, LayoutBreakpoints> = {
  [LayoutBreakpoints.sm]: LayoutBreakpoints.xxs,
  [LayoutBreakpoints.xxs]: LayoutBreakpoints.sm,
};

const device: Record<LayoutBreakpoints, 'desktop' | 'mobile'> = {
  [LayoutBreakpoints.sm]: 'desktop',
  [LayoutBreakpoints.xxs]: 'mobile',
};

export function MediakitHandle({ slug }: { slug: string; username: string }) {
  const [edit] = useEditMediakitMutation();
  const extraData = useSelector(selectExtraData);
  const currentMediakit = useSelector(selectCurrentMediakit);
  const gridLayoutBreakpoint = useSelector(selectGridLayoutBreakpoint);
  const internetConnectionStatus = useSelector(selectInternetConnectionStatus);

  const ref = React.useRef<boolean>(false);

  const debouncedOnLayoutChange = debounce(
    (currentMediakit: Builder.CurrentMediakit.Root) => {
      const allLayouts = currentMediakit.allLayouts;
      const oppositeBreakpoint = oppositeBreakpoints[gridLayoutBreakpoint];

      const oppositeLayout = allLayouts[oppositeBreakpoint];
      const currentLayout = allLayouts[gridLayoutBreakpoint];

      const { [gridLayoutBreakpoint]: currentDevice } = device;

      const oppositeDevice = oppositeDevices[currentDevice];

      const elem = currentLayout.find(({ y }) => {
        return y === null || y === undefined || y === Infinity;
      });

      if (elem) {
        return;
      }

      let categories = currentMediakit.profile?.categories;
      if (!Array.isArray(categories)) {
        categories = [];
      }

      let lastUpdatedAt: string | number | null =
        sessionStorage.getItem('last_updated_at');

      if (lastUpdatedAt) {
        lastUpdatedAt = parseInt(lastUpdatedAt, 10);
      }

      let extra = {};

      if (typeof lastUpdatedAt === 'number') {
        extra = {
          last_updated_at: lastUpdatedAt,
        };
      }

      console.log('extra', extra);

      edit({
        slug,
        data: {
          profile: {
            cta: currentMediakit.profile.cta,
            name: currentMediakit.profile.name,
            avatar_shape: currentMediakit.profile.avatar_shape,
            social_links: currentMediakit.profile.social_links,
          },
          components: currentLayout.map((item, index) => {
            let extra = {};
            const name = item.i.split('_+_')[0] as ComponentNames;

            if (dynamicCards.includes(name) && extraData?.[item.i]) {
              extra = extraData[item.i];

              if (name === ComponentNames.basics_service_list) {
                extra = {
                  service_list: [...(extra as any)],
                };
              }
            }

            return {
              name,
              uid: item.i,
              [currentDevice]: {
                h: item.h,
                w: item.w,
                x: item.x,
                y: item.y,
              },
              [oppositeDevice]: {
                h: oppositeLayout[index].h,
                w: oppositeLayout[index].w,
                x: oppositeLayout[index].x,
                y: oppositeLayout[index].y,
              },
              ...extra,
            } as Builder.Mediakit.Component;
          }),
          theme: {
            fonts: currentMediakit?.theme?.fonts,
            palette: currentMediakit?.theme?.palette,
            bg_image: currentMediakit?.theme?.bg_image,
            color_id: currentMediakit?.theme?.color_id,
            palette_id: currentMediakit?.theme?.palette_id,
            bg_image_id: currentMediakit?.theme?.bg_image_id,
            bg_image_type: currentMediakit?.theme?.bg_image_type,
            primary_color: currentMediakit?.theme?.primary_color,
            shuffled_palette: currentMediakit?.theme?.shuffled_palette,
          },
        },
        bio: currentMediakit.profile.bio,
        location: currentMediakit.profile.location?.value,
        categories: categories.map(({ value }) => value).join(','),
      });
    },
    400,
  );

  React.useEffect(() => {
    return function () {
      ref.current = false;
    };
  }, []);

  React.useEffect(() => {
    if (!ref.current) {
      ref.current = true;

      return;
    }

    if (internetConnectionStatus === InternetConnectionStatus.online) {
      if (!currentMediakit.id) return;

      const currentMediakitCopy = JSON.stringify(currentMediakit);

      debouncedOnLayoutChange(JSON.parse(currentMediakitCopy));
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [JSON.stringify(currentMediakit), internetConnectionStatus]);

  return null;
}
