'use client';

import React from 'react';
import styled from '@emotion/styled';
import { DraggableCancel } from '@shared/constants';
import useBreakpoint from '@/lib/hooks/useBreakpoint';
import { ResponsiveGridLayout } from '@shared/ui/GridLayout';
import { Layout as ILayout, Layouts } from 'react-grid-layout';
import { GridContainer } from '@widgets/mediakit/GridLayout/styles';
import { LayoutBreakpoints } from '@shared/constants/layoutBreakpoints';
import { EmptyStateProvider } from '@/lib/providers/emptyStateProvider';
import {
  useDispatch,
  useSelector,
  selectAllLayout,
  selectExtraData,
  selectActiveItem,
  selectShouldFetch,
  onChangeActiveItem,
  onChangeAllLayouts,
  onChangeBreakpoint,
  onChangeLayoutProps,
  selectCurrentLayout,
  selectMediakitTheme,
  selectGridLayoutProps,
  selectBuilderPreviewMode,
  selectGridLayoutBreakpoint,
  selectAllElementsAreSelected,
  selectBuilderPreviewIsMobile,
} from '@shared/redux';
import {
  cols,
  generateDOM,
  useOverflow,
  gridBreakpoints,
} from '@widgets/mediakit/GridLayout/helpers';

const ex = {
  ig_profile: {
    link: '',
    active: 1,
    posts: [],
    handle: '',
    platform: 1,
    followers: 0,
    access: 'free',
    name: 'ig_profile',
  },
  tk_profile: {
    link: '',
    posts: [],
    active: 1,
    handle: '',
    platform: 2,
    followers: 0,
    access: 'free',
    name: 'tk_profile',
  },
  yt_profile: {
    link: '',
    posts: [],
    active: 1,
    handle: '',
    platform: 3,
    followers: 0,
    access: 'free',
    name: 'yt_profile',
  },
  ig_followers: {
    value: 0,
    active: 1,
    trends: 0,
    platform: 1,
    access: 'free',
    name: 'ig_followers',
  },
  tk_followers: {
    value: 0,
    active: 1,
    trends: 0,
    platform: 2,
    access: 'free',
    name: 'tk_followers',
  },
  yt_followers: {
    value: 0,
    trends: 0,
    active: 1,
    platform: 3,
    access: 'free',
    name: 'yt_followers',
  },
  ig_followers_growth: {
    data: [],
    active: 1,
    platform: 1,
    access: 'pro',
    name: 'ig_followers_growth',
  },
  tk_followers_growth: {
    data: [],
    active: 1,
    platform: 2,
    access: 'pro',
    name: 'tk_followers_growth',
  },
  yt_followers_growth: {
    data: [],
    active: 1,
    platform: 3,
    access: 'pro',
    name: 'yt_followers_growth',
  },
  ig_avg_views: {
    value: 0,
    trends: 0,
    active: 1,
    platform: 1,
    access: 'pro',
    name: 'ig_avg_views',
  },
  tk_avg_views: {
    value: 0,
    trends: 0,
    active: 1,
    platform: 2,
    access: 'free',
    name: 'tk_avg_views',
  },
  yt_avg_views: {
    value: 0,
    trends: 0,
    active: 1,
    platform: 3,
    access: 'free',
    name: 'yt_avg_views',
  },
  ig_engagement_rate: {
    value: 0,
    active: 1,
    platform: 1,
    access: 'free',
    name: 'ig_engagement_rate',
  },
  tk_engagement_rate: {
    value: 0,
    active: 1,
    platform: 2,
    access: 'free',
    name: 'tk_engagement_rate',
  },
  yt_engagement_rate: {
    value: 0,
    active: 1,
    platform: 3,
    access: 'free',
    name: 'yt_engagement_rate',
  },
  ig_avg_likes: {
    value: 0,
    trends: 0,
    active: 1,
    platform: 1,
    access: 'free',
    name: 'ig_avg_likes',
  },
  tk_avg_likes: {
    value: 0,
    trends: 0,
    active: 1,
    platform: 2,
    access: 'free',
    name: 'tk_avg_likes',
  },
  yt_avg_likes: {
    value: 0,
    active: 1,
    trends: 0,
    platform: 3,
    access: 'free',
    name: 'yt_avg_likes',
  },
  ig_total_likes_growth: {
    data: [],
    active: 1,
    platform: 1,
    access: 'pro',
    name: 'ig_total_likes_growth',
  },
  tk_total_likes_growth: {
    data: [],
    active: 1,
    platform: 2,
    access: 'pro',
    name: 'tk_total_likes_growth',
  },
  yt_views_growth: {
    data: [],
    active: 1,
    platform: 3,
    access: 'pro',
    name: 'yt_views_growth',
  },
  ig_avg_comments: {
    value: 0,
    active: 1,
    platform: 1,
    access: 'free',
    name: 'ig_avg_comments',
  },
  tk_avg_comments: {
    value: 0,
    trends: 0,
    active: 1,
    platform: 2,
    access: 'free',
    name: 'tk_avg_comments',
  },
  yt_avg_comments: {
    value: 0,
    active: 1,
    trends: 0,
    platform: 3,
    access: 'free',
    name: 'yt_avg_comments',
  },
  ig_avg_reels_plays: {
    value: 0,
    active: 1,
    platform: 1,
    access: 'pro',
    name: 'ig_avg_reels_plays',
  },
  tk_avg_saves: {
    active: 0,
    platform: 2,
    access: 'pro',
    name: 'tk_avg_saves',
  },
  tk_avg_shares: {
    active: 0,
    platform: 2,
    access: 'pro',
    name: 'tk_avg_shares',
  },
  ig_audience_genders: {
    data: [],
    active: 1,
    platform: 1,
    access: 'pro',
    name: 'ig_audience_genders',
  },
  tk_audience_genders: {
    data: [],
    active: 1,
    platform: 2,
    access: 'pro',
    name: 'tk_audience_genders',
  },
  yt_audience_genders: {
    data: [],
    active: 1,
    platform: 3,
    access: 'pro',
    name: 'yt_audience_genders',
  },
  ig_audience_genders_per_age: {
    data: [],
    active: 1,
    platform: 1,
    access: 'pro',
    name: 'ig_audience_genders_per_age',
  },
  tk_audience_genders_per_age: {
    data: [],
    active: 1,
    platform: 2,
    access: 'pro',
    name: 'tk_audience_genders_per_age',
  },
  yt_audience_genders_per_age: {
    data: [],
    active: 1,
    platform: 3,
    access: 'pro',
    name: 'yt_audience_genders_per_age',
  },
  ig_top_countries: {
    data: [],
    active: 1,
    platform: 1,
    access: 'pro',
    name: 'ig_top_countries',
  },
  tk_top_countries: {
    data: [],
    active: 1,
    platform: 2,
    access: 'pro',
    name: 'tk_top_countries',
  },
  yt_top_countries: {
    data: [],
    active: 1,
    platform: 3,
    access: 'pro',
    name: 'yt_top_countries',
  },
  ig_top_cities: {
    data: [],
    active: 1,
    platform: 1,
    access: 'pro',
    name: 'ig_top_cities',
  },
  'title_+_0': {
    image_url: null,
    value: 'Instagram',
    bg_color: '#FFFFFF',
  },
  'title_+_5': {
    value: 'TikTok',
    image_url: null,
    bg_color: '#FFFFFF',
  },
  'title_+_11': {
    image_url: null,
    value: 'YouTube',
    bg_color: '#FFFFFF',
  },
};

const tmp = {
  sm: [
    {
      w: 4,
      h: 1,
      x: 0,
      y: 0,
      i: 'title_+_0',
      moved: false,
      static: false,
    },
    {
      w: 2,
      h: 4,
      x: 0,
      y: 1,
      i: 'ig_profile_+_1',
      moved: false,
      static: false,
    },
    {
      w: 2,
      h: 2,
      x: 2,
      y: 1,
      i: 'ig_engagement_rate_+_2',
      moved: false,
      static: false,
    },
    {
      w: 1,
      h: 2,
      x: 2,
      y: 3,
      i: 'ig_avg_likes_+_3',
      moved: false,
      static: false,
    },
    {
      w: 1,
      h: 2,
      x: 3,
      y: 3,
      i: 'ig_avg_comments_+_4',
      moved: false,
      static: false,
    },
    {
      w: 4,
      h: 1,
      x: 0,
      y: 5,
      i: 'title_+_5',
      moved: false,
      static: false,
    },
    {
      w: 2,
      h: 2,
      x: 0,
      y: 6,
      i: 'tk_profile_+_6',
      moved: false,
      static: false,
    },
    {
      w: 1,
      h: 2,
      x: 2,
      y: 8,
      i: 'tk_avg_views_+_7',
      moved: false,
      static: false,
    },
    {
      w: 2,
      h: 2,
      x: 2,
      y: 6,
      i: 'tk_engagement_rate_+_8',
      moved: false,
      static: false,
    },
    {
      w: 2,
      h: 2,
      x: 0,
      y: 8,
      i: 'tk_avg_likes_+_9',
      moved: false,
      static: false,
    },
    {
      w: 1,
      h: 2,
      x: 3,
      y: 8,
      i: 'tk_avg_comments_+_10',
      moved: false,
      static: false,
    },
    {
      w: 4,
      h: 1,
      x: 0,
      y: 10,
      i: 'title_+_11',
      moved: false,
      static: false,
    },
    {
      w: 2,
      h: 4,
      x: 0,
      y: 11,
      i: 'yt_profile_+_12',
      moved: false,
      static: false,
    },
    {
      w: 1,
      h: 2,
      x: 3,
      y: 11,
      i: 'yt_avg_views_+_13',
      moved: false,
      static: false,
    },
    {
      w: 1,
      h: 2,
      x: 2,
      y: 11,
      i: 'yt_engagement_rate_+_14',
      moved: false,
      static: false,
    },
    {
      w: 1,
      h: 2,
      x: 3,
      y: 13,
      i: 'yt_avg_likes_+_15',
      moved: false,
      static: false,
    },
    {
      w: 1,
      h: 2,
      x: 2,
      y: 13,
      i: 'yt_avg_comments_+_16',
      moved: false,
      static: false,
    },
  ],
  xxs: [
    {
      w: 2,
      h: 1,
      x: 0,
      y: 0,
      i: 'title_+_0',
      moved: false,
      static: false,
    },
    {
      w: 2,
      h: 2,
      x: 0,
      y: 1,
      i: 'ig_profile_+_1',
      moved: false,
      static: false,
    },
    {
      w: 2,
      h: 2,
      x: 0,
      y: 3,
      i: 'ig_engagement_rate_+_2',
      moved: false,
      static: false,
    },
    {
      w: 1,
      h: 2,
      x: 0,
      y: 5,
      i: 'ig_avg_likes_+_3',
      moved: false,
      static: false,
    },
    {
      w: 1,
      h: 2,
      x: 1,
      y: 5,
      i: 'ig_avg_comments_+_4',
      moved: false,
      static: false,
    },
    {
      w: 2,
      h: 1,
      x: 0,
      y: 7,
      i: 'title_+_5',
      moved: false,
      static: false,
    },
    {
      w: 2,
      h: 2,
      x: 0,
      y: 8,
      i: 'tk_profile_+_6',
      moved: false,
      static: false,
    },
    {
      w: 1,
      h: 2,
      x: 1,
      y: 12,
      i: 'tk_avg_views_+_7',
      moved: false,
      static: false,
    },
    {
      w: 1,
      h: 2,
      x: 0,
      y: 10,
      i: 'tk_engagement_rate_+_8',
      moved: false,
      static: false,
    },
    {
      w: 1,
      h: 2,
      x: 1,
      y: 10,
      i: 'tk_avg_likes_+_9',
      moved: false,
      static: false,
    },
    {
      w: 1,
      h: 2,
      x: 0,
      y: 12,
      i: 'tk_avg_comments_+_10',
      moved: false,
      static: false,
    },
    {
      w: 2,
      h: 1,
      x: 0,
      y: 14,
      i: 'title_+_11',
      moved: false,
      static: false,
    },
    {
      w: 2,
      h: 2,
      x: 0,
      y: 15,
      i: 'yt_profile_+_12',
      moved: false,
      static: false,
    },
    {
      w: 1,
      h: 2,
      x: 1,
      y: 19,
      i: 'yt_avg_views_+_13',
      moved: false,
      static: false,
    },
    {
      w: 1,
      h: 2,
      x: 0,
      y: 17,
      i: 'yt_engagement_rate_+_14',
      moved: false,
      static: false,
    },
    {
      w: 1,
      h: 2,
      x: 1,
      y: 17,
      i: 'yt_avg_likes_+_15',
      moved: false,
      static: false,
    },
    {
      w: 1,
      h: 2,
      x: 0,
      y: 19,
      i: 'yt_avg_comments_+_16',
      moved: false,
      static: false,
    },
  ],
};

const Mask = styled('div')(() => ({
  top: 0,
  zIndex: 9999,
  width: '100%',
  height: '100%',
  display: 'flex',
  position: 'absolute',
  background: 'transparent',
}));

export const GridLayout: React.FC<{ showSkeleton?: boolean }> = ({
  showSkeleton,
}) => {
  useOverflow();

  const dispatch = useDispatch();
  const matches = useBreakpoint(991);
  const theme = useSelector(selectMediakitTheme);

  let extraData = useSelector(selectExtraData);
  let layout = useSelector(selectCurrentLayout);
  let allLayouts = useSelector(selectAllLayout);

  const activeItem = useSelector(selectActiveItem);
  const shouldFetch = useSelector(selectShouldFetch);
  const previewMode = useSelector(selectBuilderPreviewMode);
  const breakpoint = useSelector(selectGridLayoutBreakpoint);
  const [rowHeight, setRowHeight] = React.useState<number>(73);
  const { margin, draggableHandle } = useSelector(selectGridLayoutProps);
  const builderPreviewIsMobile = useSelector(selectBuilderPreviewIsMobile);
  const allElementsAreSelected = useSelector(selectAllElementsAreSelected);

  React.useEffect(() => {
    dispatch(
      onChangeLayoutProps({
        margin: matches ? [16, 16] : [24, 24],
        draggableHandle: matches ? '.DragHandler' : undefined,
      }),
    );
    setRowHeight(matches ? 80 : 73);
  }, [matches, dispatch]);

  const onSelect = (index: string) => {
    if (shouldFetch) return;

    dispatch(onChangeActiveItem(index));
  };

  const onLayoutChange = (_currentLayout: ILayout[], allLayouts: Layouts) => {
    if (shouldFetch) return;

    dispatch(onChangeAllLayouts(allLayouts));
  };

  const handleOnWidthChange = (
    _containerWidth: number,
    _margin: [number, number],
    cols: number,
    _containerPadding: [number, number],
  ) => {
    let newBreakpoint = 'xxs';

    if (cols === 4) {
      newBreakpoint = 'sm';
    }

    handleOnChangeBreakpoint(newBreakpoint, cols);
  };

  const handleOnChangeBreakpoint = (newBreakpoint: string, newCols: number) => {
    dispatch(
      onChangeBreakpoint({
        newCols,
        newBreakpoint: newBreakpoint as LayoutBreakpoints,
      }),
    );
  };

  if (shouldFetch) {
    allLayouts = tmp;
    extraData = ex as any;
    layout = tmp[breakpoint];
  }

  return (
    <GridContainer
      showSkeleton={shouldFetch}
      className={`grid-container ${previewMode}`}
    >
      <EmptyStateProvider shouldFetch={shouldFetch} previewMode={previewMode}>
        <ResponsiveGridLayout
          cols={cols}
          className="layout"
          isDroppable={true}
          isResizable={false}
          layouts={allLayouts}
          containerPadding={[0, 0]}
          breakpoints={gridBreakpoints}
          onLayoutChange={onLayoutChange}
          draggableHandle={draggableHandle}
          onWidthChange={handleOnWidthChange}
          draggableCancel={`.${DraggableCancel}`}
          onBreakpointChange={handleOnChangeBreakpoint}
          margin={builderPreviewIsMobile ? [16, 16] : margin}
          rowHeight={builderPreviewIsMobile ? 80 : rowHeight}
        >
          {generateDOM({
            theme,
            layout,
            onSelect,
            extraData,
            activeItem,
            showSkeleton,
            allElementsAreSelected,
          } as any)}
        </ResponsiveGridLayout>
      </EmptyStateProvider>
      {showSkeleton && <Mask />}
    </GridContainer>
  );
};
