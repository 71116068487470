import { Drawer } from 'antd';
import styled, { CSSObject } from '@emotion/styled';
import theme, { breakpoints, prefixCls } from '@shared/theme';

const { token } = theme;

export const MkitDrawer = styled(Drawer, {
  shouldForwardProp: (prop) =>
    !['mkitTheme', 'padding', 'paddingXS'].includes(prop),
})(({
  padding,
  mkitTheme,
  paddingXS,
}: {
  mkitTheme?: 'dark' | 'light';
  padding?: CSSObject['padding'];
  paddingXS?: CSSObject['padding'];
}) => {
  let headerStyles = {};
  let contentStyles = {};
  const dark = mkitTheme === 'dark';

  if (dark) {
    headerStyles = {
      color: '#fff',
      backgroundColor: '#001529',
      borderBottom: '1px solid rgba(255, 255, 255, 0.06)',
      [`& .${prefixCls}-drawer-close`]: { color: '#fff' },
    };

    contentStyles = {
      backgroundColor: '#001529',
    };
  }

  return {
    [`&.${prefixCls}-drawer-content`]: { ...contentStyles },
    [`&.${prefixCls}-drawer-content .${prefixCls}-drawer-body`]: {
      paddingRight: 0,
      overflowX: 'hidden',
      padding: padding ?? '2rem 2.4rem',
      backgroundColor: dark ? '#001529' : `${token?.colorBgLayout}`,
    },
    [`&.${prefixCls}-drawer-content .${prefixCls}-drawer-header`]: {
      ...headerStyles,
      [`@media (max-width: ${breakpoints.lg})`]: {
        [`& > .${prefixCls}-drawer-header-title`]: {
          flexDirection: 'row-reverse',
          [`& > .${prefixCls}-drawer-close`]: {
            marginRight: 0,
          },
        },
      },
    },
    [`@media (max-width: ${breakpoints.xs})`]: {
      [`&.${prefixCls}-drawer-content .${prefixCls}-drawer-body`]: {
        padding: paddingXS ?? '2rem 1rem',
      },
    },
  };
});
