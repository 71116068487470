import React from 'react';
import { profileAvatarIndex } from '@shared/constants';
import {
  onCopyItem,
  useDispatch,
  useSelector,
  selectExtraData,
  selectActiveItem,
  onChangeActiveItem,
  selectCurrentLayout,
  onRemoveReactGridItem,
  onEmptyReactGridLayout,
  selectUploadImageStates,
  onRestorePreviousLayout,
  onChangeAllElementsSelection,
} from '@shared/redux';

export const Hotkeys: React.FC = () => {
  const dispatch = useDispatch();

  const extraData = useSelector(selectExtraData);
  const layout = useSelector(selectCurrentLayout);
  const activeItem = useSelector(selectActiveItem);
  const uploadImageStates = useSelector(selectUploadImageStates);

  const index = layout.findIndex((l) => l.i === activeItem);

  React.useEffect(() => {
    const keydown = (e: any) => {
      if (
        e.key === 'Delete' &&
        index !== -1 &&
        e.target.tagName !== 'INPUT' &&
        e.target.tagName !== 'TEXTAREA'
      ) {
        return dispatch(onRemoveReactGridItem(index));
      }

      if (e.key === 'Delete') {
        return dispatch(onEmptyReactGridLayout());
      }

      if (
        e.key === 'Escape' &&
        index !== -1 &&
        e.target.tagName !== 'INPUT' &&
        e.target.tagName !== 'TEXTAREA'
      ) {
        e.preventDefault();

        dispatch(onChangeActiveItem(undefined));
        dispatch(onChangeAllElementsSelection(false));
      }

      if (e.key === 'Escape' && activeItem === profileAvatarIndex) {
        e.preventDefault();

        dispatch(onChangeActiveItem(undefined));
        dispatch(onChangeAllElementsSelection(false));
      }

      if (e.key === 'Escape') {
        e.preventDefault();

        dispatch(onChangeAllElementsSelection(false));
      }

      if (
        e.ctrlKey &&
        (e.key === 'a' || e.key === 'A') &&
        e.target.tagName !== 'INPUT' &&
        e.target.tagName !== 'TEXTAREA'
      ) {
        e.preventDefault();

        dispatch(onChangeAllElementsSelection(true));
      }

      if (
        e.ctrlKey &&
        (e.key === 'c' || e.key === 'C') &&
        e.target.tagName !== 'INPUT' &&
        e.target.tagName !== 'TEXTAREA'
      ) {
        e.preventDefault();

        const active = layout.find((l) => l.i === activeItem);

        if (active) {
          let uid = active.i;
          let info = extraData[uid];
          let name = uid.split('_+_')[0];

          if (!info) {
            info = extraData[name];
          }

          const fullData = {
            info,
            name,
            i: active.i,
            h: active.h,
            w: active.w,
          };

          if (!uploadImageStates[active.i]) {
            sessionStorage.setItem('copiable', JSON.stringify(fullData));
          }
        } else {
          sessionStorage.removeItem('copiable');
        }
      }

      if (
        e.ctrlKey &&
        (e.key === 'v' || e.key === 'V') &&
        e.target.tagName !== 'INPUT' &&
        e.target.tagName !== 'TEXTAREA'
      ) {
        e.preventDefault();
        const copiableItem = sessionStorage.getItem('copiable');

        if (copiableItem !== null) {
          dispatch(onCopyItem(JSON.parse(copiableItem)));
        }
      }
      if (
        e.ctrlKey &&
        (e.key === 'z' || e.key === 'Z') &&
        e.target.tagName !== 'INPUT' &&
        e.target.tagName !== 'TEXTAREA'
      ) {
        e.preventDefault();
        dispatch(onRestorePreviousLayout());
      }
    };

    document.addEventListener('keydown', keydown);

    return () => {
      document.removeEventListener('keydown', keydown);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [index, dispatch, activeItem]);

  return null;
};
