import styled, { CSSObject } from '@emotion/styled';
import { breakpoints } from '@shared/theme';

export const TemplateCard = styled('div', {
  shouldForwardProp: (prop) =>
    !['height', 'backgroundColor', 'boxShadow'].includes(prop),
})(
  ({
    height,
    boxShadow,
    backgroundColor,
  }: {
    height?: CSSObject['height'];
    boxShadow?: CSSObject['boxShadow'];
    backgroundColor?: CSSObject['backgroundColor'];
  }) => ({
    boxShadow,
    display: 'flex',
    borderRadius: 8,
    overflow: 'hidden',
    alignItems: 'center',
    position: 'relative',
    height: height ?? 270,
    flexDirection: 'column',
    justifyContent: 'space-between',
    backgroundColor: backgroundColor || 'transparent',
    [`@media (max-width: ${breakpoints.md})`]: {
      height: height || 315,
    },
  }),
);

export const TemplateCardExtra = styled('div')(() => ({
  height: 193,
  width: '100%',
  display: 'flex',
  borderRadius: 8,
  overflow: 'hidden',
  position: 'relative',
  boxShadow: '0px 6px 14px 0px rgba(0, 0, 0, 0.04)',
  '& img': {
    borderRadius: 8,
    objectFit: 'cover',
    objectPosition: 'top center',
  },
  [`@media (max-width: ${breakpoints.md})`]: {
    height: 250,
  },
}));

export const TemplateCardFooter = styled('div')(() => ({
  gap: 8,
  width: '100%',
  display: 'flex',
  padding: '1.2rem 0',
  flexDirection: 'column',
  alignItems: 'flex-start',
}));
