import React from 'react';
import { Button } from 'antd';
import useBreakpoint from '@/lib/hooks/useBreakpoint';
import { profileAvatarIndex } from '@shared/constants';
import { TourStepProps } from 'antd/es/tour/interface';
import { descriptions } from '../constants/descriptions';
import { useOnboardingMutation } from '@/lib/services/auth';
import { useLocalPostHog } from '@shared/hooks/useLocalPostHog';
import { PosthogEvents } from '@shared/constants/posthog-events';
import { cardShadow } from '@widgets/mkit-tour/constants/card-shadow';
import {
  cover1Mobile,
  cover2Mobile,
  cover1Desktop,
  cover2Desktop,
} from '@widgets/mkit-tour/ui/videos';
import {
  useDispatch,
  setOpenTour,
  useSelector,
  selectTourStep,
  selectTourIsOpen,
  onUpdateTourStep,
  selectActiveItem,
  selectThirdTourCompleted,
  selectFirstTourCompleted,
  selectSecondTourCompleted,
} from '@shared/redux';

const getElements = () => {
  const addSocialsTarget = document.getElementById(
    'addSocialsTarget',
  ) as HTMLElement;

  const bioTextAreaTarget = document.getElementById(
    'bioTextAreaTarget',
  ) as HTMLElement;

  const themeButtonTarget = document.getElementById(
    'themeButtonTarget',
  ) as HTMLElement;

  const categoriesSelectTarget = document.getElementById(
    'categoriesSelectTarget',
  ) as HTMLElement;

  const addComponentsButtonTarget = document.getElementById(
    'addComponentsButtonTarget',
  ) as HTMLElement;

  return {
    addSocialsTarget,
    bioTextAreaTarget,
    themeButtonTarget,
    categoriesSelectTarget,
    addComponentsButtonTarget,
  };
};

const getThirdTourElements = () => {
  const resizeMobileTarget = document.getElementById(
    'resizeMobileTarget',
  ) as HTMLElement;

  const arrangeMobileTarget = document.getElementById(
    'arrangeMobileTarget',
  ) as HTMLElement;

  const resizeOrArrangeTarget = document.getElementById(
    'resizeOrArrangeTarget',
  ) as HTMLElement;

  return {
    resizeMobileTarget,
    arrangeMobileTarget,
    resizeOrArrangeTarget,
  };
};

const RenderPanel: React.FC<{ step: TourStepProps; current: number }> = ({
  step,
  current,
}) => {
  return (
    <div className="mediakit-tour-content">
      <div className="mediakit-tour-inner">
        <MediakitTourClose onClose={step.onClose} />
        {step.cover && <div className="mediakit-tour-cover">{step.cover}</div>}
        <div className="mediakit-tour-header">
          <div className="mediakit-tour-title">{step.title}</div>
        </div>
        <div className="mediakit-tour-description">{step.description}</div>
        <div className="mediakit-tour-footer">
          <div className="mediakit-tour-indicators">
            {new Array(step.total || 0).fill(0).map((_, index) => (
              <span
                key={index}
                className={`mediakit-tour-indicator${
                  index === current ? '-active' : ''
                } mediakit-tour-indicator`}
              />
            ))}
          </div>
          <div className="mediakit-tour-buttons">
            <Button type="text" size="small" onClick={step.onClose}>
              <span style={{ color: '#FFFFFFE0' }}>Skip</span>
            </Button>
            <Button
              size="small"
              type="primary"
              onClick={step.total === current + 1 ? step.onFinish : step.onNext}
            >
              <span>{step.nextButtonProps?.children || 'Next'}</span>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

const MediakitTourClose: React.FC<{ onClose?: () => void }> = ({ onClose }) => {
  return (
    <button type="button" onClick={onClose} className="mediakit-tour-close">
      <span
        role="img"
        aria-label="close"
        className="anticon anticon-close mediakit-tour-close-icon"
      >
        <svg
          width="1em"
          height="1em"
          data-icon="close"
          focusable="false"
          aria-hidden="true"
          fillRule="evenodd"
          fill="currentColor"
          viewBox="64 64 896 896"
        >
          <path d="M799.86 166.31c.02 0 .04.02.08.06l57.69 57.7c.04.03.05.05.06.08a.12.12 0 010 .06c0 .03-.02.05-.06.09L569.93 512l287.7 287.7c.04.04.05.06.06.09a.12.12 0 010 .07c0 .02-.02.04-.06.08l-57.7 57.69c-.03.04-.05.05-.07.06a.12.12 0 01-.07 0c-.03 0-.05-.02-.09-.06L512 569.93l-287.7 287.7c-.04.04-.06.05-.09.06a.12.12 0 01-.07 0c-.02 0-.04-.02-.08-.06l-57.69-57.7c-.04-.03-.05-.05-.06-.07a.12.12 0 010-.07c0-.03.02-.05.06-.09L454.07 512l-287.7-287.7c-.04-.04-.05-.06-.06-.09a.12.12 0 010-.07c0-.02.02-.04.06-.08l57.7-57.69c.03-.04.05-.05.07-.06a.12.12 0 01.07 0c.03 0 .05.02.09.06L512 454.07l287.7-287.7c.04-.04.06-.05.09-.06a.12.12 0 01.07 0z" />
        </svg>
      </span>
    </button>
  );
};

let arrangeTimeout: NodeJS.Timeout;

let startTourTimeout: NodeJS.Timeout;

export const useMkitTour = () => {
  const dispatch = useDispatch();
  const posthog = useLocalPostHog();
  const matches991 = useBreakpoint(991);
  const current = useSelector(selectTourStep);
  const active = useSelector(selectActiveItem);
  const tourIsOpen = useSelector(selectTourIsOpen);
  const firstTourCompleted = useSelector(selectFirstTourCompleted);
  const thirdTourCompleted = useSelector(selectThirdTourCompleted);
  const secondTourCompleted = useSelector(selectSecondTourCompleted);

  const [onboarding] = useOnboardingMutation();
  const [steps, setSteps] = React.useState<TourStepProps[]>([]);

  React.useEffect(() => {
    if (startTourTimeout) {
      clearTimeout(startTourTimeout);
    }

    startTourTimeout = setTimeout(
      (a, b) => {
        if (!a || !b) {
          dispatch(setOpenTour(true));
        }
      },
      2500,
      firstTourCompleted,
      secondTourCompleted,
    );

    return () => {
      if (startTourTimeout) {
        clearTimeout(startTourTimeout);
      }
    };
  }, [dispatch, firstTourCompleted, secondTourCompleted, thirdTourCompleted]);

  React.useEffect(() => {
    const {
      addSocialsTarget,
      bioTextAreaTarget,
      themeButtonTarget,
      categoriesSelectTarget,
      addComponentsButtonTarget,
    } = getElements();

    const onFinishFirstTour = () => {
      onboarding({ step: '1.social_links,1.categories,1.bio' });

      dispatch(setOpenTour(false));
      dispatch(onUpdateTourStep(0));

      try {
        posthog?.capture(PosthogEvents.TourCompleted);
      } catch (e) {
        console.error('Posthog error:', e);
      }
    };

    const onFinishSecondTour = () => {
      onboarding({ step: '2.theme,2.components' });

      dispatch(setOpenTour(false));
      dispatch(onUpdateTourStep(0));

      try {
        posthog?.capture(PosthogEvents.TourCompleted);
      } catch (e) {
        console.error('Posthog error:', e);
      }
    };

    const updateTour = (step: number) => () => {
      dispatch(onUpdateTourStep(step));
    };

    const onCloseFirstTour = (title: string) => {
      onboarding({ step: '1.social_links,1.categories,1.bio' });

      dispatch(setOpenTour(false));
      dispatch(onUpdateTourStep(0));

      try {
        posthog?.capture(PosthogEvents.TourClosed, { closed_step: title });
      } catch (e) {
        console.error('Posthog error:', e);
      }
    };

    const onCloseSecondTour = (title: string) => {
      onboarding({ step: '2.theme,2.components' });

      dispatch(setOpenTour(false));
      dispatch(onUpdateTourStep(0));

      try {
        posthog?.capture(PosthogEvents.TourClosed, { closed_step: title });
      } catch (e) {
        console.error('Posthog error:', e);
      }
    };

    const steps_1: TourStepProps[] = [
      {
        title: 'Add Socials',
        onNext: updateTour(1),
        style: { width: 300 },
        target: addSocialsTarget,
        description: descriptions[0],
        onClose: () => onCloseFirstTour('Add Socials'),
        scrollIntoViewOptions: { block: 'center' },
        renderPanel: (step, current) => (
          <RenderPanel step={step} current={current} />
        ),
      },
      {
        title: 'Add Category',
        style: { width: 300 },
        onNext: updateTour(2),
        placement: 'bottomLeft',
        description: descriptions[1],
        target: categoriesSelectTarget,
        onClose: () => onCloseFirstTour('Add Category'),
        scrollIntoViewOptions: { block: 'center' },
        renderPanel: (step, current) => (
          <RenderPanel step={step} current={current} />
        ),
      },
      {
        title: 'Add Bio',
        style: { width: 300 },
        onNext: updateTour(3),
        placement: 'bottomLeft',
        target: bioTextAreaTarget,
        onFinish: onFinishFirstTour,
        description: descriptions[2],
        onClose: () => onCloseFirstTour('Add Bio'),
        nextButtonProps: { children: 'Got it!' },
        scrollIntoViewOptions: { block: 'center' },
        renderPanel: (step, current) => (
          <RenderPanel step={step} current={current} />
        ),
      },
    ];

    const steps_2: TourStepProps[] = [
      {
        title: 'Theming',
        onNext: updateTour(1),
        style: { width: 300 },
        target: themeButtonTarget,
        description: descriptions[3],
        onClose: () => onCloseSecondTour('Theming'),
        scrollIntoViewOptions: { block: 'center' },
        renderPanel: (step, current) => (
          <RenderPanel step={step} current={current} />
        ),
      },
      {
        style: { width: 300 },
        title: 'Add Components',
        description: descriptions[4],
        onFinish: onFinishSecondTour,
        target: addComponentsButtonTarget,
        onClose: () => onCloseSecondTour('Add Components'),
        nextButtonProps: { children: 'Got it!' },
        scrollIntoViewOptions: { block: 'center' },
        renderPanel: (step, current) => (
          <RenderPanel step={step} current={current} />
        ),
      },
    ];

    if (!firstTourCompleted) {
      setSteps(steps_1);
    } else if (!secondTourCompleted) {
      setSteps(steps_2);
    }
  }, [
    posthog,
    dispatch,
    onboarding,
    firstTourCompleted,
    thirdTourCompleted,
    secondTourCompleted,
  ]);

  React.useEffect(() => {
    if (arrangeTimeout) {
      clearTimeout(arrangeTimeout);
    }

    if (active === profileAvatarIndex || thirdTourCompleted) {
      return;
    }

    const { resizeMobileTarget, arrangeMobileTarget, resizeOrArrangeTarget } =
      getThirdTourElements();

    const onFinishThirdTour = () => {
      onboarding({ step: '3.resize_cards,3.arrange_cards' });

      dispatch(setOpenTour(false));
      dispatch(onUpdateTourStep(0));

      try {
        posthog?.capture(PosthogEvents.TourCompleted);
      } catch (e) {
        console.error('Posthog error:', e);
      }
    };

    const updateTour = (step: number) => () => {
      dispatch(onUpdateTourStep(step));
    };

    const onCloseThirdTour = (title: string) => {
      onboarding({ step: '3.resize_cards,3.arrange_cards' });

      dispatch(setOpenTour(false));
      dispatch(onUpdateTourStep(0));

      try {
        posthog?.capture(PosthogEvents.TourClosed, { closed_step: title });
      } catch (e) {
        console.error('Posthog error:', e);
      }
    };

    const steps_3: TourStepProps[] = [
      {
        onNext: () => {
          dispatch(setOpenTour(false));
          updateTour(1)();
        },
        title: 'Resize the Cards',
        description: descriptions[5],
        nextButtonProps: { children: 'Ok' },
        onClose: () => onCloseThirdTour('Resize the Cards'),
        style: {
          width: '100%',
          borderRadius: 8,
          boxShadow: cardShadow,
          maxWidth: matches991 ? 361 : 438,
        },
        renderPanel: (step, current) => (
          <RenderPanel step={step} current={current} />
        ),
        cover: matches991 ? cover1Mobile : cover1Desktop,
        target: matches991 ? resizeMobileTarget : resizeOrArrangeTarget,
      },
      {
        style: {
          width: '100%',
          borderRadius: 8,
          boxShadow: cardShadow,
          maxWidth: matches991 ? 361 : 438,
        },
        title: 'Arrange the Cards',
        onFinish: onFinishThirdTour,
        description: descriptions[6],
        nextButtonProps: { children: 'Ok' },
        onClose: () => onCloseThirdTour('Arrange the Cards'),
        renderPanel: (step, current) => (
          <RenderPanel step={step} current={current} />
        ),
        cover: matches991 ? cover2Mobile : cover2Desktop,
        target: matches991 ? arrangeMobileTarget : resizeOrArrangeTarget,
      },
    ];

    if (secondTourCompleted && !thirdTourCompleted) {
      setSteps(steps_3);

      arrangeTimeout = setTimeout(
        (a) => {
          if (a) {
            dispatch(setOpenTour(true));
          }
        },
        2500,
        active,
      );
    }

    return () => {
      if (arrangeTimeout) {
        clearTimeout(arrangeTimeout);
      }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    active,
    posthog,
    dispatch,
    onboarding,
    thirdTourCompleted,
    secondTourCompleted,
  ]);

  React.useEffect(() => {
    if (
      !active &&
      tourIsOpen &&
      firstTourCompleted &&
      secondTourCompleted &&
      !thirdTourCompleted
    ) {
      dispatch(setOpenTour(false));
    }
  }, [
    active,
    dispatch,
    tourIsOpen,
    firstTourCompleted,
    secondTourCompleted,
    thirdTourCompleted,
  ]);

  return {
    steps,
    current,
    open: tourIsOpen,
  };
};
