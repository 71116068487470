import React from 'react';

export const Unsplash: React.FC<{
  fill?: string;
  fillOpacity?: number;
}> = ({ fill, fillOpacity }) => {
  return (
    <svg
      width="19"
      height="18"
      fill="none"
      viewBox="0 0 19 18"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_7264_45242)">
        <path
          fill={fill}
          fillOpacity={fillOpacity}
          d="M6.125 5.0625V0H12.875V5.0625H6.125ZM12.875 7.875H18.5V18H0.5V7.875H6.125V12.9375H12.875V7.875Z"
        />
      </g>
      <defs>
        <clipPath id="clip0_7264_45242">
          <rect
            width="18"
            height="18"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
