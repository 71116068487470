import React from 'react';
import { Typography } from 'antd';
import dynamic from 'next/dynamic';
import { Box } from '@shared/ui/box';
import { prefixCls } from '@shared/theme';
import { Builder } from '@/lib/types/builder';
import { DemoCardSize } from '@shared/constants';
import { DemoChartCard } from '@shared/ui/demo-card';
import { selectIsFreePlan, useSelector } from '@shared/redux';
import { getYouTubeAccountDetails } from '@widgets/mediakit/helpers';
import {
  builderCardSizes,
  largeSize,
} from '@shared/constants/builderCardSizes';

// @ts-ignore
const Pie = dynamic(() => import('@ant-design/plots').then((mod) => mod.Pie), {
  ssr: false,
});

const dummyData = [
  {
    type: 'MALE',
    value: 71.4,
  },
  {
    type: 'FEMALE',
    value: 28.6,
  },
];

export function YouTubeSexRatioDemo({
  data,
  size,
  onAddItem,
}: Builder.DemoComponentProps) {
  const isFree = useSelector(selectIsFreePlan);
  const detailsFunc = getYouTubeAccountDetails()[data.name];

  const { title } = detailsFunc({
    size: builderCardSizes.s12,
  });

  let tmp: any = {};
  let localData = (data?.data || []).map((item: any) => {
    tmp[item.code] = Number((item.weight * 100).toFixed(2));
    return {
      type: item.code,
      value: Number((item.weight * 100).toFixed(1)),
    };
  });
  const isLarge = size === DemoCardSize.large;

  if (isFree) {
    localData = [...dummyData];
  }

  return (
    <DemoChartCard
      size={size}
      title={title}
      w={largeSize.w}
      h={largeSize.h}
      type={data.name}
      onClick={onAddItem}
    >
      <Box
        boxStyles={{
          alignItems: 'center',
          gap: isLarge ? 16 : 5,
          flexDirection: 'column',
          justifyContent: 'center',
          paddingTop: isLarge ? '1.6rem' : '1.2rem',
        }}
      >
        <React.Suspense>
          <Pie
            margin={0}
            label={false}
            legend={false}
            tooltip={false}
            colorField="type"
            paddingBottom={0}
            innerRadius={0.6}
            angleField="value"
            width={isLarge ? 90 : 70}
            height={isLarge ? 90 : 70}
            data={{ type: 'inline', value: localData || [] }}
            scale={{ color: { palette: ['#5B8FF9', '#FF85C0'] } }}
          />
        </React.Suspense>

        <Box
          boxStyles={{
            gap: 5,
            width: isLarge ? 120 : 100,
            alignItems: 'center',
            flexDirection: 'column',
            [`& .${prefixCls}-typography`]: {
              fontWeight: 400,
              color: '#00000073',
              fontSize: isLarge ? '1rem' : '.7rem',
              lineHeight: isLarge ? '1.2rem' : '.8rem',
            },
          }}
        >
          <Box
            boxStyles={{
              gap: 16,
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Box boxStyles={{ alignItems: 'center', gap: 8 }}>
              <Box
                boxStyles={{
                  width: 8,
                  height: 8,
                  borderRadius: '50%',
                  backgroundColor: '#5B8FF9',
                }}
              />
              <Typography.Text>Male</Typography.Text>
            </Box>

            <Typography.Text style={{ whiteSpace: 'nowrap' }} strong>
              {tmp.MALE}%
            </Typography.Text>
          </Box>

          <Box
            boxStyles={{
              gap: 16,
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Box boxStyles={{ alignItems: 'center', gap: 8 }}>
              <Box
                boxStyles={{
                  width: 8,
                  height: 8,
                  borderRadius: '50%',
                  backgroundColor: '#FF85C0',
                }}
              />
              <Typography.Text>Female</Typography.Text>
            </Box>
            <Typography.Text style={{ whiteSpace: 'nowrap' }} strong>
              {tmp.FEMALE}%
            </Typography.Text>
          </Box>
        </Box>
      </Box>
    </DemoChartCard>
  );
}
