import { Skeleton } from 'antd';
import styled from '@emotion/styled';
import { Neutral, prefixCls } from '@shared/theme';

export const SkeletonAvatar = styled(Skeleton.Avatar)(() => ({
  [`&>.${prefixCls}-skeleton-avatar.${prefixCls}-skeleton-avatar-circle`]: {
    minWidth: '4rem',
    cursor: 'pointer',
    minHeight: '4rem',
    verticalAlign: 'middle',
    border: '1px solid transparent',
  },
}));
