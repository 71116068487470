import { Input } from 'antd';
import styled from '@emotion/styled';
import { boxShadowTertiary, prefixCls } from '@shared/theme';

export const BorderedInput = styled(Input)(() => ({
  lineHeight: 1,
  outline: 'none',
  fontSize: '2.4rem',
  textAlign: 'center',
  border: '2px solid transparent',
  [`&.${prefixCls}-input &.${prefixCls}-input-borderless`]: {
    border: '2px solid transparent !important',
  },
  [`&.${prefixCls}-input:focus, &.${prefixCls}-input-focused`]: {
    border: '2px solid #001529',
    boxShadow: boxShadowTertiary,
  },
  [`&.mediakit-input-borderless, &.mediakit-input-borderless:hover, &.mediakit-input-borderless:focus, &.mediakit-input-borderless-focused, &.mediakit-input-borderless-disabled, &.mediakit-input-borderless[disabled]`]:
    {
      border: '2px solid transparent',
    },
}));
