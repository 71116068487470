import React from 'react';
import Image from 'next/image';
import image from './image.svg';
import { Typography } from 'antd';
import styled from '@emotion/styled';
import { Box } from '@shared/ui/box';
import { breakpoints } from '@shared/theme';

const Card = styled('div')(() => ({
  height: 192,
  display: 'flex',
  padding: '0.8rem',
  background: '#fff',
  alignItems: 'center',
  borderRadius: '.8rem',
  justifyContent: 'center',
  boxShadow: '0px 6px 14px 0px rgba(0, 0, 0, 0.04)',
  [`@media (max-width: ${breakpoints.md})`]: {
    height: 250,
  },
}));

export const CreateFormScratch: React.FC<{ onClick: () => void }> = ({
  onClick,
}) => {
  return (
    <Card onClick={onClick}>
      <Box
        boxStyles={{
          gap: '0.8rem',
          width: '100%',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          borderRadius: '.4rem',
          justifyContent: 'center',
          backgroundColor: 'rgba(0, 0, 0, 0.04)',
          border: `1px dashed rgba(0, 0, 0, 0.15)`,
          [`@media (max-width: ${breakpoints.md})`]: {
            flexDirection: 'column',
          },
        }}
      >
        <Image src={image} alt={''} />
        <Typography.Text
          style={{
            marginTop: 1,
            fontSize: 16,
            fontWeight: 600,
          }}
        >
          New Media Kit
        </Typography.Text>
      </Box>
    </Card>
  );
};
