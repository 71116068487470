import React from 'react';
import { createPortal } from 'react-dom';
import { Mask } from './get-started-mask';
import { Content } from './get-started-content';
import useBreakpoint from '@/lib/hooks/useBreakpoint';
import { getStartedStepsCount } from '@shared/constants';
import { useEnableMutation } from '@/lib/services/mediakit';
import { GetStartedContainer } from './get-started-container';
import { useLocalPostHog } from '@shared/hooks/useLocalPostHog';
import { PosthogEvents } from '@shared/constants/posthog-events';
import { CloseOutlined, ShareAltOutlined } from '@ant-design/icons';
import { Controls, Player } from '@lottiefiles/react-lottie-player';
import {
  Flex,
  Modal,
  Badge,
  Button,
  Popover,
  Progress,
  Typography,
} from 'antd';
import {
  useSelector,
  useDispatch,
  onOpenShareModal,
  selectAuthUserHandle,
  selectOnboardingModal,
  onToggleOnboardingModal,
  selectGetStartedCompletedCount,
  selectActiveItem,
} from '@shared/redux';

const overlayInnerStyle = { padding: 0 };

const overlayStyle = { width: 'calc(100% - 32px)', maxWidth: 320 };

export const GetStarted: React.FC<{ slug?: string }> = ({ slug }) => {
  const dispatch = useDispatch();
  const posthog = useLocalPostHog();
  const matches768 = useBreakpoint(768);
  const [enable, res] = useEnableMutation();
  const [open, setOpen] = React.useState(false);
  const handle = useSelector(selectAuthUserHandle);
  const activeItem = useSelector(selectActiveItem);
  const count = useSelector(selectGetStartedCompletedCount);
  const onboardingModalIsOpen = useSelector(selectOnboardingModal);

  const isFinished = getStartedStepsCount - count === 0;

  const onClick = () => {
    dispatch(onToggleOnboardingModal());

    try {
      posthog?.capture(PosthogEvents.GetStartedClick);
    } catch (e) {
      console.error('Posthog error:', e);
    }
  };

  const onOpenChange = (open: boolean) => {
    dispatch(onToggleOnboardingModal(open));
  };

  React.useEffect(() => {
    if (isFinished && !localStorage.getItem(`getStartedFinished_${handle}`)) {
      setOpen(true);

      localStorage.setItem(
        `getStartedFinished_${handle}`,
        `${new Date().getTime()}`,
      );
    }
  }, [isFinished, handle]);

  const onClose = () => {
    setOpen(false);

    localStorage.setItem(
      `getStartedFinished_${handle}`,
      `${new Date().getTime()}`,
    );
  };

  React.useEffect(() => {
    if (res.isSuccess) {
      setOpen(false);
      dispatch(onOpenShareModal());
    }
  }, [res, dispatch]);

  return (
    <>
      {isFinished || (activeItem && matches768) ? (
        <div style={{ width: 62 }} />
      ) : (
        <GetStartedContainer>
          {onboardingModalIsOpen &&
            createPortal((<Mask />) as any, document.body)}
          <Popover
            title={null}
            arrow={false}
            trigger="click"
            showArrow={false}
            placement="topRight"
            content={<Content />}
            onOpenChange={onOpenChange}
            overlayStyle={overlayStyle}
            align={{ offset: [0, -12] }}
            open={onboardingModalIsOpen}
            overlayInnerStyle={overlayInnerStyle}
          >
            <Badge
              status="error"
              offset={[-10, 5]}
              dot={!onboardingModalIsOpen}
            >
              <div
                onClick={onClick}
                style={{
                  display: 'flex',
                  cursor: 'pointer',
                  borderRadius: '50%',
                  alignItems: 'center',
                  backgroundColor: 'white',
                  justifyContent: 'center',
                  boxShadow: '0px 2px 12px 0px rgba(0, 0, 0, 0.32)',
                }}
              >
                {onboardingModalIsOpen ? (
                  <div
                    style={{
                      height: 62,
                      width: 62,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <CloseOutlined style={{ fontSize: 24 }} />
                  </div>
                ) : (
                  <Progress
                    size={62}
                    type="circle"
                    strokeWidth={6}
                    strokeColor="#52C41A"
                    style={{ fontWeight: 700 }}
                    percent={Math.ceil((count * 100) / getStartedStepsCount)}
                  />
                )}
              </div>
            </Badge>
          </Popover>
        </GetStartedContainer>
      )}
      <Modal width={360} open={open} onCancel={onClose} footer={null}>
        <Flex vertical gap={16}>
          <Player
            autoplay
            loop={false}
            src="/1714989893013.json"
            style={{
              top: 0,
              left: 0,
              zIndex: 10,
              width: '100vw',
              height: '100vh',
              position: 'fixed',
            }}
          >
            <Controls visible={false} />
          </Player>
          <span
            style={{
              fontSize: 48,
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            🎉
          </span>
          <Flex vertical align="center">
            <Typography.Title level={5}>Congratulations</Typography.Title>
            <Typography.Text style={{ fontSize: 12 }}>
              Your media kit is ready to be shared with brands.
            </Typography.Text>
          </Flex>
          <Flex justify="center">
            <Button
              type="primary"
              style={{ width: 160, position: 'relative', zIndex: 11 }}
              loading={res.isLoading}
              icon={<ShareAltOutlined />}
              onClick={() => enable(slug!)}
            >
              Share
            </Button>
          </Flex>
        </Flex>
      </Modal>
    </>
  );
};
