import React from 'react';
import styled, { CSSObject } from '@emotion/styled';
import { DemoCardSize } from '@shared/constants/demo-card-size';
import theme, { lightBoxShadow, prefixCls } from '@shared/theme';

const { token } = theme;

export const ProfileCard = styled('div')(() => ({
  gap: '.8rem',
  display: 'flex',
  position: 'relative',
  alignItems: 'center',
  flexDirection: 'column',
}));

export const PostsContainer = styled('div')(() => ({
  flexGrow: 1,
  display: 'flex',
  alignItems: 'center',
  position: 'relative',
  justifyContent: 'space-between',
}));

export const ProfileCardHead = styled('div')(() => ({
  gap: '1rem',
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
  [`& > h5.${prefixCls}-typography`]: {
    fontSize: '1rem',
  },
}));

export const HandleContainer = styled('div')(() => ({
  gap: '.6rem',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  [`& > h5.${prefixCls}-typography`]: {
    fontSize: '.6rem',
  },
}));

export const ProfileCardHeadExtra = styled('div')(() => ({
  gap: '.5rem',
  display: 'flex',
  flexDirection: 'column',
}));

export const ProfileCardBody = styled('div', {
  shouldForwardProp: (prop) => prop !== 'size',
})(({ size }: { size?: 'large' | 'default' }) => ({
  gap: '.8rem',
  display: 'flex',
  cursor: 'pointer',
  padding: '1.2rem',
  borderRadius: '.8rem',
  flexDirection: 'column',
  backgroundColor: '#fff',
  boxShadow: lightBoxShadow,
  justifyContent: 'space-between',
  width: size === DemoCardSize.large ? 170 : 132,
  height: size === DemoCardSize.large ? 170 : 132,
  border: `1px solid ${token!.colorBorderSecondary}`,
}));

export const postStyles = (size?: DemoCardSize): CSSObject[] => [
  {
    zIndex: 20,
    position: 'relative',
    width: size === DemoCardSize.large ? 50 : 38,
    height: size === DemoCardSize.large ? 75 : 57,
    transform: 'translate(-7%,0) scaleX(.85) scaleY(.85)',
  },
  {
    zIndex: 30,
    left: '50%',
    borderRadius: '8px',
    position: 'absolute',
    transform: 'translate(-50%,0)',
    width: size === DemoCardSize.large ? 59 : 45,
    height: size === DemoCardSize.large ? 95 : 65,
  },
  {
    zIndex: 20,
    position: 'relative',
    width: size === DemoCardSize.large ? 50 : 38,
    height: size === DemoCardSize.large ? 75 : 57,
    transform: 'translate(7%,0) scaleX(.85) scaleY(.85)',
  },
];

export const imageStyles: React.CSSProperties = {
  objectFit: 'cover',
  borderRadius: '8px',
  objectPosition: 'center',
  boxShadow: '0 0 0 4px #fff',
};
