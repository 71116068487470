import React from 'react';
import styled from '@emotion/styled';
import theme, { lightBoxShadow, prefixCls } from '@shared/theme';

const { token } = theme;

export const ProfileCard = styled('div')(() => ({
  gap: '.8rem',
  display: 'flex',
  position: 'relative',
  alignItems: 'center',
  flexDirection: 'column',
}));

export const PostsContainer = styled('div')(() => ({
  gap: '.4rem',
  height: '100%',
  display: 'grid',
  gridTemplateColumns: `1fr 1fr 1fr`,
}));

export const ProfileCardHead = styled('div')(() => ({
  gap: '1rem',
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
  [`& > h5.${prefixCls}-typography`]: {
    fontSize: '1rem',
  },
}));

export const HandleContainer = styled('div')(() => ({
  gap: '.6rem',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  [`& > h5.${prefixCls}-typography`]: {
    fontSize: '.6rem',
  },
}));

export const ImageContainer = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  position: 'relative',
}));

export const ProfileCardHeadExtra = styled('div')(() => ({
  gap: '.5rem',
  display: 'flex',
  flexDirection: 'column',
}));

export const ProfileCardBody = styled('div', {
  shouldForwardProp: (prop) => prop !== 'size',
})(({ size }: { size?: 'large' | 'default' }) => ({
  gap: '.8rem',
  display: 'flex',
  cursor: 'pointer',
  padding: '1.2rem',
  borderRadius: '.8rem',
  flexDirection: 'column',
  backgroundColor: '#fff',
  boxShadow: lightBoxShadow,
  justifyContent: 'space-between',
  width: size === 'large' ? 170 : 132,
  height: size === 'large' ? 170 : 132,
  border: `1px solid ${token!.colorBorderSecondary}`,
}));

export const imageStyles: React.CSSProperties = {
  objectFit: 'cover',
  borderRadius: '8px',
  objectPosition: 'center',
  boxShadow: '0 0 0 4px #fff',
};
