import React from 'react';
import styled from '@emotion/styled';
import { BorderedInput } from './styled';
import { prefixCls } from '@shared/theme';
import { Title } from '@shared/ui/typography';
import { Flex, InputRef, Skeleton } from 'antd';
import { fontVariants } from '@shared/constants/fontVariants';
import { useLocalPostHog } from '@shared/hooks/useLocalPostHog';
import { PosthogEvents } from '@shared/constants/posthog-events';
import { Unverified } from '@/widgets/verification/ui/unverified';
import {
  selectName,
  useDispatch,
  useSelector,
  onChangeName,
} from '@shared/redux';

const NameSkeleton = styled(Skeleton)({
  [`&.${prefixCls}-skeleton`]: {
    width: 160,
    [`& > .${prefixCls}-skeleton-content`]: {
      height: 32,
      [`& > .${prefixCls}-skeleton-title`]: {
        height: 32,
        marginBottom: 0,
      },
    },
  },
});

export const NameInput: React.FC<{
  loading?: boolean;
  showSkeleton?: boolean;
}> = ({ loading, showSkeleton }) => {
  const dispatch = useDispatch();
  const posthog = useLocalPostHog();
  const name = useSelector(selectName);

  const inputRef = React.useRef<InputRef>(null);

  const [inputVisible, setInputVisible] = React.useState(false);
  const [bordered, setBordered] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (inputVisible) {
      inputRef.current?.focus();
    }
  }, [inputVisible]);

  const showInput = () => {
    setInputVisible(true);
  };

  const onFocus = () => setBordered(true);

  const onBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value?.trim() || '';
    setBordered(false);

    setInputVisible(false);

    dispatch(onChangeName(value?.slice(0, 70)));

    if (value) {
      try {
        posthog?.capture(PosthogEvents.AddedName, {
          name: value,
        });
      } catch (e) {
        console.error('Posthog error:', e);
      }
    }
  };

  let inputStyle = {
    display: inputVisible ? 'block' : 'none',
  };

  let titleStyle = {
    padding: '8px 4px',
    alignItems: 'center',
    display: !inputVisible ? 'block' : 'none',
  };

  const flexStyle = {
    width: '100%',
    marginBottom: '-1.6rem',
  };

  if (!name?.value) {
    inputStyle = {
      display: 'flex',
    };
    titleStyle = {
      ...titleStyle,
      display: 'none',
    };
  }

  return (
    <Flex
      gap={8}
      align="center"
      justify="center"
      style={flexStyle}
      id="nameInputContainer"
    >
      <NameSkeleton paragraph={false} active={loading} loading={loading}>
        <>
          <BorderedInput
            size="large"
            id="nameInput"
            ref={inputRef}
            onBlur={onBlur}
            onFocus={onFocus}
            style={inputStyle}
            placeholder="Your Name"
            className={fontVariants.opt1}
            defaultValue={name?.value || ''}
            variant={!bordered ? 'borderless' : undefined}
          />
          <Title
            ellipsis
            level={3}
            onClick={showInput}
            titleProps={titleStyle}
            className={fontVariants.opt1}
          >
            {name?.value}
          </Title>
        </>
      </NameSkeleton>
      <Unverified showSkeleton={showSkeleton} />
    </Flex>
  );
};
