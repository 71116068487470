import React from 'react';
import styled from '@emotion/styled';
import {
  useDispatch,
  useSelector,
  selectActiveItem,
  onChangeActiveItem,
} from '@shared/redux';

const BackdropBox = styled('div')(() => ({
  top: 0,
  left: 0,
  zIndex: 91,
  width: '100%',
  display: 'flex',
  position: 'absolute',
  height: 'calc(100% + 80px)',
  transform: 'translate(0, -80px)',
}));

export const Backdrop: React.FC = () => {
  const dispatch = useDispatch();
  const activeItem = useSelector(selectActiveItem);

  const onClick = () => {
    dispatch(onChangeActiveItem(undefined));
  };

  return !!activeItem ? <BackdropBox onClick={onClick} /> : null;
};
