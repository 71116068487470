import { Typography } from 'antd';
import styled from '@emotion/styled';
import { breakpoints, prefixCls } from '@shared/theme';

const selector = `h1&.${prefixCls}-typography, h2&.${prefixCls}-typography, h3&.${prefixCls}-typography, h4&.${prefixCls}-typography, h5&.${prefixCls}-typography, h6&.${prefixCls}-typography`;

export const TemplateName = styled(Typography.Title)(() => ({
  [selector]: {
    width: '100%',
    marginBottom: 0,
    textAlign: 'left',
    [`@media (max-width: ${breakpoints.md})`]: {
      fontSize: 12,
    },
  },
}));
