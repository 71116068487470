import React from 'react';
import styled from '@emotion/styled';
import { Tags } from './components/Tags';
import { AddNewTag } from './components/AddNewTag';
import { Categories } from '@/lib/types/categories';
import { useLocalPostHog } from '@shared/hooks/useLocalPostHog';
import { PosthogEvents } from '@shared/constants/posthog-events';
import {
  useDispatch,
  useSelector,
  onSelectCategory,
  selectSelectedCategories,
  selectCategoriesHasSelected,
} from '@shared/redux';
import { useOnboardingMutation } from '@/lib/services/auth';

const AddNewTagContainer = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  marginTop: '1rem',
  justifyContent: 'flex-start',
}));

const TagsContainer = styled('div')(() => ({
  width: '100%',
  gap: '.8rem',
  display: 'flex',
  flexWrap: 'wrap',
  marginTop: '.8rem',
  alignItems: 'center',
  justifyContent: 'center',
}));

export const CategoriesSelect: React.FC<{ showSkeleton?: boolean }> = ({
  showSkeleton,
}) => {
  const dispatch = useDispatch();
  const posthog = useLocalPostHog();
  const inputRef = React.useRef<any>(null);
  const [onboarding] = useOnboardingMutation();
  const [inputVisible, setInputVisible] = React.useState(false);
  const selectedCategories = useSelector(selectSelectedCategories);
  const categoriesHasSelected = useSelector(selectCategoriesHasSelected);

  React.useEffect(() => {
    if (inputVisible) {
      inputRef.current?.focus();
    }
  }, [inputVisible]);

  const onBlur = () => {
    setInputVisible(false);
  };

  const showInput = () => {
    setInputVisible(true);
  };

  const onSelect = (
    _value: Categories.Category['value'],
    option?: Categories.Category,
  ) => {
    if (option) {
      dispatch(onSelectCategory(option));

      if (!categoriesHasSelected) {
        onboarding({ step: 'general_info.categories' });
      }

      try {
        const labels = selectedCategories.map((category) => category.label);

        labels.push(option.label);

        posthog?.capture(PosthogEvents.AddedCategory, {
          categories: labels,
        });
      } catch (e) {
        console.error('Posthog error:', e);
      }
    }

    setInputVisible(false);
  };

  return (
    <div>
      <TagsContainer>
        <Tags showSkeleton={showSkeleton} />
      </TagsContainer>
      <AddNewTagContainer>
        <AddNewTag
          ref={inputRef}
          onBlur={onBlur}
          onSelect={onSelect}
          showInput={showInput}
          disabled={showSkeleton}
          showSkeleton={showSkeleton}
          inputVisible={inputVisible}
        />
      </AddNewTagContainer>
    </div>
  );
};
