import React from 'react';
import { App } from 'antd';
import Cookies from 'universal-cookie';
import { IsNew } from '@shared/ui/is-new';
import { Builder } from '@/lib/types/builder';
import { allowedVideos } from '@shared/constants';
import Dropzone, { DropzoneFile } from 'dropzone';
import { BasicDemoCard } from '@shared/ui/basic-demo-card';
import { largeSize } from '@shared/constants/builderCardSizes';
import { useLocalPostHog } from '@shared/hooks/useLocalPostHog';
import { PosthogEvents } from '@shared/constants/posthog-events';
import { getBasicComponentsDetails } from '@widgets/mediakit/helpers';
import {
  useDispatch,
  useSelector,
  selectCounter,
  videoUploadMatchPending,
  videoUploadMatchFulfilled,
} from '@shared/redux';

interface ReactDropzoneProps {
  id: string;
  onAddedfile: (file: DropzoneFile) => void;
  onSuccess: (file: DropzoneFile, response: Object | string) => void;
  onSending: (
    file: DropzoneFile,
    xhr: XMLHttpRequest,
    formData: FormData,
  ) => void;
}

export const ReactDropzone: React.FC<
  React.PropsWithChildren<ReactDropzoneProps>
> = ({ id, children, onSuccess, onSending, onAddedfile }) => {
  const { message } = App.useApp();
  const posthog = useLocalPostHog();
  const dropzoneRef = React.useRef<Dropzone | null>(null);

  const addedfile = React.useCallback(
    (file: Dropzone.DropzoneFile) => {
      const isAllowedFormat = allowedVideos.includes(file.type);

      if (!isAllowedFormat) {
        message.error('You can only upload mp4 file!').then(console.log);

        return false;
      }

      const isAllowedSize = file.size / 1024 / 1024 < 1024;

      if (!isAllowedSize) {
        message.error('Video must smaller than 1GB!').then();

        try {
          posthog?.capture(PosthogEvents.MoreThanAllowedSize, {
            type: 'video',
            size: file.size,
          });
        } catch (e) {
          console.error('Posthog error:', e);
        }

        return false;
      }

      onAddedfile(file);

      dropzoneRef.current?.uploadFile(file);
    },
    [message, onAddedfile, posthog],
  );

  React.useEffect(() => {
    if (dropzoneRef.current || !id) {
      return;
    }

    const cookies = new Cookies();
    const access_token = cookies.get('access_token') ?? null;

    dropzoneRef.current = new Dropzone(`#${id}`, {
      maxFiles: 1,
      chunking: true,
      autoQueue: false,
      paramName: 'file',
      maxFilesize: 1024,
      uploadMultiple: false,
      autoProcessQueue: false,
      previewsContainer: false,
      chunkSize: 1024 * 1024 * 2,
      acceptedFiles: allowedVideos.join(','),
      url: '/api/mediakit-builder/upload-video',
      headers: { Authorization: `Bearer ${access_token}` },
    });

    dropzoneRef.current
      .on('sending', onSending)
      .on('success', onSuccess)
      .on('addedfile', addedfile);
  }, [id, message, addedfile, onSuccess, onSending, onAddedfile]);

  return (
    <form className="dropzone" id={id}>
      <input
        type="file"
        name="file"
        maxLength={1}
        style={{ display: 'none' }}
        accept={allowedVideos.join(',')}
      />
      <div className="dz-default dz-message">
        <div className="dz-button">{children}</div>
      </div>
    </form>
  );
};

export function VideoDemo({
  data,
  size,
  onAddItem,
}: Builder.DemoComponentProps) {
  const dispatch = useDispatch();
  const posthog = useLocalPostHog();
  const counter = useSelector(selectCounter);
  const detailsFunc = getBasicComponentsDetails()[data.name];

  const { title, icon } = detailsFunc();

  const onClick = () => {
    try {
      posthog?.capture(PosthogEvents.VideoComponent);
    } catch (e) {
      console.error('Posthog error:', e);
    }
  };

  const onSuccess = React.useCallback(
    (file: Dropzone.DropzoneFile, response: Object | string) => {
      if (typeof response !== 'string') {
        dispatch(
          videoUploadMatchFulfilled({
            uid: (response as any).uid,
            url: (response as any).url,
            thumb_url: (response as any).thumb_url,
          }),
        );
      }
    },
    [dispatch],
  );

  const uid = React.useMemo(() => {
    return `${data.name}_+_${counter}`;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onAddedfile = React.useCallback((_file: Dropzone.DropzoneFile) => {
    if (typeof onAddItem === 'function') {
      onAddItem({
        currentTarget: {
          dataset: {
            w: largeSize.w,
            h: largeSize.h,
            type: data.name,
            platform: `${data.platform}`,
          },
        },
      } as any);
    }

    dispatch(videoUploadMatchPending({ uid }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSending = React.useCallback(
    (file: Dropzone.DropzoneFile, _xhr: XMLHttpRequest, formData: FormData) => {
      formData.append('uid', uid);

      dispatch(
        videoUploadMatchPending({
          uid,
          data: {
            totalSize: file.size,
            bytesSent: file.upload?.bytesSent,
          },
        }),
      );
    },

    [uid, dispatch],
  );

  return (
    <ReactDropzone
      id="demo-dropzone"
      onSuccess={onSuccess}
      onSending={onSending}
      onAddedfile={onAddedfile}
    >
      <IsNew />
      <BasicDemoCard
        h={2}
        w={2}
        icon={icon}
        size={size}
        title={title}
        type={data.name}
        onClick={onClick}
        platform={data.platform}
      />
    </ReactDropzone>
  );
}
