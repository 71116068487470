import React from 'react';
import { Builder } from '@/lib/types/builder';
import { ComponentNames } from '@shared/constants/componentNames';
import * as Demo from '@widgets/mediakit/ComponentsDrawer/components/DemoComponents/TikTok';

export const componentsMap: Record<
  string,
  (props: Builder.DemoComponentProps) => React.JSX.Element
> = {
  [ComponentNames.tiktok_profile]: Demo.TikTokProfileDemo,
  [ComponentNames.tiktok_avg_views]: Demo.TikTokAccountDemo,
  // [ComponentNames.tiktok_avg_saves]: Demo.TikTokAccountDemo,
  [ComponentNames.tiktok_avg_likes]: Demo.TikTokAccountDemo,
  [ComponentNames.tiktok_followers]: Demo.TikTokAccountDemo,
  // [ComponentNames.tiktok_avg_shares]: Demo.TikTokAccountDemo,
  [ComponentNames.tiktok_avg_comments]: Demo.TikTokAccountDemo,
  [ComponentNames.tiktok_engagement_rate]: Demo.TikTokAccountDemo,
  [ComponentNames.tiktok_audience_genders]: Demo.TikTokSexRatioDemo,
  [ComponentNames.tiktok_top_countries]: Demo.TikTokTopCountriesDemo,
  [ComponentNames.tiktok_likes_growth]: Demo.TikTokLikesFollowersGrowthDemo,
  [ComponentNames.tiktok_audience_genders_per_age]: Demo.TikTokSexAgeRatioDemo,
  [ComponentNames.tiktok_followers_growth]: Demo.TikTokLikesFollowersGrowthDemo,
};
