import styled from '@emotion/styled';
import { breakpoints, builderBreakpoint } from '@shared/theme';

export const ProfileCard = styled('div')(() => ({
  top: 140,
  width: 364,
  zIndex: 92,
  marginTop: 80,
  display: 'flex',
  marginRight: 24,
  position: 'sticky',
  textAlign: 'center',
  height: 'fit-content',
  flexDirection: 'column',
  '&.mobile': {
    top: 'unset',
    width: '100%',
    marginInline: 'auto',
  },
  [`@media (max-width: ${builderBreakpoint})`]: {
    top: 'unset',
    marginInline: 'auto',
  },
  [`@media (max-width: ${breakpoints.xs})`]: {
    width: '100%',
  },
}));
