import styled from '@emotion/styled';

export const ProfileCardExtra = styled('div')(() => ({
  gap: '1.3rem',
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  '& > div': {
    maxWidth: '50%',
  },
}));
