import React from 'react';
import { ProBadge } from '@shared/ui/pro-badge';
import { componentsMap } from './componentsMap';
import { DemoCardSize } from '@shared/constants';
import { useUpgradeModal } from '@shared/hooks/useUpgradeModal';
import { ComponentNames } from '@shared/constants/componentNames';
import { useSelector, selectBasicComponents } from '@shared/redux';
import { BasicComponentsContainer } from '@shared/ui/demo-components-container';

const proComponents: Record<string, boolean> = {
  [ComponentNames.basics_text]: true,
  [ComponentNames.basics_link]: true,
  [ComponentNames.basics_image]: true,
  [ComponentNames.basics_video]: true,
  [ComponentNames.basics_title]: true,
  [ComponentNames.basics_info_card]: true,
  [ComponentNames.basics_service_list]: true,
};

const wrapperStyle: React.CSSProperties = {
  position: 'relative',
  width: 'fit-content',
  display: 'inline-flex',
};

export const BasicsGroup: React.FC<{
  slug: string;
  username: string;
  onAddItem: (e: React.MouseEvent<HTMLDivElement>) => void;
}> = ({ slug, username, onAddItem }) => {
  const { handleOpen } = useUpgradeModal();
  const components = useSelector(selectBasicComponents);

  const onAddProItem = (name: ComponentNames) => () => {
    handleOpen('component', { seeAll: true, from: name });
  };

  return (
    <BasicComponentsContainer>
      {components.map((componentData, index) => {
        const Card = componentsMap[componentData.name];

        if (proComponents[componentData.name]) {
          return !!Card ? (
            <div key={index} style={wrapperStyle}>
              <Card
                key={index}
                slug={slug}
                seeAll={true}
                username={username}
                data={componentData}
                onAddItem={onAddItem}
                size={DemoCardSize.large}
              />
            </div>
          ) : null;
        } else {
          return !!Card ? (
            <div key={index} style={wrapperStyle}>
              <ProBadge isPro={true} />
              <Card
                slug={slug}
                seeAll={true}
                username={username}
                data={componentData}
                size={DemoCardSize.large}
                onAddItem={onAddProItem(componentData.name)}
              />
            </div>
          ) : null;
        }
      })}
    </BasicComponentsContainer>
  );
};
