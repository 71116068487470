import React from 'react';
import Image from 'next/image';
import proBadge from '@shared/icons/pro-badge.svg';

export const ProBadge = ({ isPro }: { isPro: boolean }) => {
  return isPro ? (
    <div
      style={{
        right: 6,
        zIndex: 99,
        bottom: 30,
        position: 'absolute',
      }}
    >
      <Image src={proBadge} alt={''} />
    </div>
  ) : null;
};
