import React from 'react';
import { App, Button, Skeleton } from 'antd';
import { defaultSlug } from '@shared/constants';
import { Templates } from '@/lib/types/templates';
import useBreakpoint from '@/lib/hooks/useBreakpoint';
import { selectProfile, useSelector } from '@shared/redux';
import { mkitDefaults } from '@shared/constants/mkit-defaults';
import { TemplatesModal } from '@widgets/templates-modal/ui/templates-modal';
import {
  useEditMediakitMutation,
  useEditMediakitFromTemplateMutation,
} from '@/lib/services/mediakit';

const content =
  'By changing the template, you will lose all your current edits. Are you sure you want to proceed?';

const ButtonSkeleton = () => {
  const is768 = useBreakpoint(768);
  const style = {
    padding: 0,
    display: 'flex',
    alignItems: 'center',
    width: is768 ? 40 : 130,
    minWidth: is768 ? 'unset' : void 0,
  };

  return <Skeleton.Button active size="large" style={style} />;
};

export const TemplatesButton: React.FC<{
  scrolled?: boolean;
  showSkeleton?: boolean;
}> = ({ scrolled, showSkeleton }) => {
  const { modal } = App.useApp();
  const matches = useBreakpoint(768);
  const profile = useSelector(selectProfile);
  const [edit] = useEditMediakitMutation();
  const [update] = useEditMediakitFromTemplateMutation();
  const [modalVisible, setModalVisible] = React.useState(false);

  const onClick = () => {
    setModalVisible(true);
  };

  const onUpdate = async (template?: Templates.Template) => {
    const categories = profile?.categories
      ?.map((category) => category.value)
      .join(',');

    modal.confirm({
      content,
      okText: 'Change',
      title: 'Confirm Template Change',
      onOk: async () => {
        if (template) {
          await update({
            slug: defaultSlug,
            templateId: template.template_id,
          });
        } else {
          await edit({
            categories,
            bio: profile.bio,
            slug: defaultSlug,
            location: profile.location?.value,
            data: {
              components: [],
              profile: profile,
              theme: mkitDefaults.content.theme,
            },
          } as any);
        }

        return window.location.reload();
      },
    });
  };

  return (
    <>
      {showSkeleton ? (
        <ButtonSkeleton />
      ) : (
        <Button
          size="large"
          onClick={onClick}
          type={scrolled ? 'text' : void 0}
          icon={
            <svg
              width="18"
              height="18"
              fill="none"
              viewBox="0 0 18 18"
              style={{ display: 'flex' }}
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill="currentColor"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3.5625 3.375H9.1875C9.33668 3.375 9.47976 3.43426 9.58525 3.53975C9.69074 3.64524 9.75 3.78832 9.75 3.9375V14.0625C9.75 14.2117 9.69074 14.3548 9.58525 14.4602C9.47976 14.5657 9.33668 14.625 9.1875 14.625H3.5625C3.41332 14.625 3.27024 14.5657 3.16475 14.4602C3.05926 14.3548 3 14.2117 3 14.0625V3.9375C3 3.78832 3.05926 3.64524 3.16475 3.53975C3.27024 3.43426 3.41332 3.375 3.5625 3.375ZM1.5 3.9375C1.5 3.39049 1.7173 2.86589 2.10409 2.47909C2.49089 2.0923 3.01549 1.875 3.5625 1.875H9.1875C9.73451 1.875 10.2591 2.0923 10.6459 2.47909C11.0327 2.86589 11.25 3.39049 11.25 3.9375V14.0625C11.25 14.6095 11.0327 15.1341 10.6459 15.5209C10.2591 15.9077 9.73451 16.125 9.1875 16.125H3.5625C3.01549 16.125 2.49089 15.9077 2.10409 15.5209C1.7173 15.1341 1.5 14.6095 1.5 14.0625V3.9375ZM17.1 6.07725L15.75 5.298V12.702L17.1 11.922V6.078V6.07725ZM14.25 4.4325L12.75 3.567V14.4345L14.25 13.569V4.4325Z"
              />
            </svg>
          }
        >
          {!matches && 'Templates'}
        </Button>
      )}
      <TemplatesModal
        onUpdate={onUpdate}
        visible={modalVisible}
        setVisible={setModalVisible}
      />
    </>
  );
};
