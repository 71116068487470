import styled from '@emotion/styled';
import { prefixCls, shadow } from '@shared/theme';
import { FloatButton as AntdFloatButton } from 'antd';

export const StyledFloatButton = styled(AntdFloatButton, {
  shouldForwardProp: (prop) => prop !== 'dark',
})(({ dark }: { dark?: boolean }) => {
  let styles = {};
  let hoverStyles = {};

  if (dark) {
    hoverStyles = {
      backgroundColor: 'rgba(0, 0, 0, 0.06)',
    };
    styles = {
      boxShadow: shadow,
      backgroundColor: 'rgba(255, 255, 255, 0.10)',
    };
  }

  return {
    [`&.${prefixCls}-float-btn`]: {
      width: 50,
      height: 50,
      insetBlockEnd: 0,
      insetInlineEnd: 0,
      position: 'relative',
      ...styles,
    },
    [`&.${prefixCls}-float-btn .${prefixCls}-float-btn-body`]: {
      ...styles,
    },
    [`&.${prefixCls}-float-btn .${prefixCls}-float-btn-body:hover`]: {
      ...hoverStyles,
    },
    [`&.${prefixCls}-float-btn .${prefixCls}-float-btn-body .${prefixCls}-float-btn-content .${prefixCls}-float-btn-icon`]:
      {
        width: 'auto',
        display: 'inline-flex',
      },
  };
});
