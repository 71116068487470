import React from 'react';
import { Typography } from 'antd';
import styled from '@emotion/styled';
import { DemoCardSize } from '@shared/constants';
import { selectDrawerTheme, useSelector } from '@shared/redux';

const Helper = styled(Typography.Text, {
  shouldForwardProp: (propName) => !['size', 'drawerTheme'].includes(propName),
})(({
  size,
  drawerTheme,
}: {
  size?: DemoCardSize;
  drawerTheme?: 'dark' | 'light';
}) => {
  const color = drawerTheme === 'dark' ? 'rgba(255, 255, 255, 0.88)' : void 0;

  return {
    color,
    fontSize: size === DemoCardSize.large ? '1.4rem' : '1.2rem',
    lineHeight: size === DemoCardSize.large ? '2.2rem' : '2rem',
  };
});

export const CardHelper: React.FC<
  React.PropsWithChildren<{ size?: DemoCardSize }>
> = ({ size, children }) => {
  const drawerTheme = useSelector(selectDrawerTheme);

  return (
    <Helper size={size} drawerTheme={drawerTheme}>
      {children}
    </Helper>
  );
};
