export const mockData = new Array(8)
  .fill(0)
  .map((_, i) => i)
  .reduce(
    (acc, curr, index) => {
      if (index % 2 === 0 && index !== 0) {
        acc.nestedIndex++;
      }

      if (!acc.arr[acc.nestedIndex]) {
        acc.arr[acc.nestedIndex] = [curr];
      } else {
        acc.arr[acc.nestedIndex].push(curr);
      }

      return acc;
    },
    {
      arr: [],
      nestedIndex: 0,
    } as any,
  );
